import constants from '@/utils/constants'


export function removePrefix(strA, strB) {
  if (strA.startsWith(strB)) {
      return strA.slice(strB.length);
  }
  return strA;
}

export function removeUpToFirstInstance(strA, strB) {
  let index = strA.indexOf(strB);
  if (index !== -1) {
      return strA.slice(index+1);
  }
  return strA;
}


export function replaceSubstringWithUnderscores(str, target) {
  // Create a case-insensitive regular expression with 'g' flag for global replacement
  let regex = new RegExp(target, 'gi');
  return str.replace(regex, match => '_'.repeat(match.length));
}

export function splitIntoSyllables(word) {
  // Basic rule-based approach for splitting English words into syllables
  if (!word || typeof word !== 'string') return [];

  // Lowercase the word for consistent processing
  word = word.toLowerCase();

  // Vowel groups to identify syllables
  const vowels = 'aeiouy';
  const regex = new RegExp(`[${vowels}]+[^${vowels}]*`, 'g');

  // Find syllable-like chunks using regex
  const chunks = word.match(regex);

  if (!chunks) return [word]; // Return the word itself if no syllables are found

  // Refine syllable splits
  return refineSyllables(chunks);
}

function refineSyllables(chunks) {
  // Example refinement rules, can be improved with more phonetic rules
  const refined = [];
  for (let i = 0; i < chunks.length; i++) {
      let chunk = chunks[i];

      // Handle silent "e" at the end of words
      if (i === chunks.length - 1 && chunk.endsWith('e')) {
          chunk = chunk.slice(0, -1);
      }

      // Combine small chunks
      if (i > 0 && chunk.length === 1 && /^[aeiouy]$/.test(chunk)) {
          refined[refined.length - 1] += chunk; // Merge with previous chunk
      } else {
          refined.push(chunk);
      }
  }
  return refined.filter(Boolean); // Remove empty parts
}


export function removePlural(word)
{
  let out_word = word

  if(word.length>2)
  {
    if(word.slice(-1)=='s')
    {
      out_word = word.substring(0, word.length - 1);

    }
  }

  return out_word

}

export function averageHighestPercentile(arr, percentile = 20) {
  if (!Array.isArray(arr) || arr.length === 0) {
      throw new Error("Input must be a non-empty array.");
  }
  
  // Sort the array in ascending order
  const sortedArr = arr.slice().sort((a, b) => a - b);
  
  // Calculate the cutoff index for the given percentile
  const count = Math.ceil((percentile / 100) * sortedArr.length);

  console.debug(sortedArr)
  console.debug(count)
  // Get the highest percentile elements
  const highestPercentileValues = sortedArr.slice(Math.max(0,sortedArr.length-1-count), sortedArr.length-1);
  
  console.debug(highestPercentileValues)
  // Calculate the average of these values
  // const sum = highestPercentileValues.reduce((acc, val) => acc + val, 0);

  let sum = 0

  for(let k in highestPercentileValues)
  {
    sum = sum + Number(highestPercentileValues[k])
  }
  
  console.debug(sum)

  return sum / (Math.max(highestPercentileValues.length,1));
}

export function roundNumber(number, number_of_decimals) { 
    
  let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)

  if(isNaN(rounded_number))
  {
    return number
  }

  return rounded_number
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function findIDRange(pos,target)
{
  let min_target = 0;
  let max_target = pos[pos.length-1];
  if(target<pos[0])
  {
    min_target = '';
    max_target = pos[0];
  }
  else if(target>pos[pos.length-1])
  {
    min_target = pos[pos.length-1];
    max_target = ''
  }
  else
  {
    for(let i=0;i<pos.length;i++)
    {
      if((pos[i]<target)&&(pos[i]>min_target))
      {
        min_target = pos[i];
      }

      if((pos[i]>target)&&(pos[i]<max_target))
      {
        max_target = pos[i]
      }
    }
  }

  return[min_target,max_target]
}

export function phonemeToWord(word, phonemes)
{
  // console.debug('phoenme0= '+phonemes[1])
  let pho_pos = [];
  let word_pos = [];
  let pho_letter = [];
  let word_pos_in_pho = [];
  let phoneme_word = []


  if(word==undefined)
  {
    return [phoneme_word,word_pos_in_pho]

  }


  phoneme_word = word.split('');
  for(let i = 0;i<phonemes.length;i++)
  {

    if(constants.consonants.includes(phonemes[i]))
    {
      let pho = phonemes[i];
      let conso_arr = constants.conso_letters[pho];
      let temp_cons_pos = word.length+1;
      let temp_letter = ''
      for(let j=0;j<conso_arr.length;j++)
      {
        let temp_pos = ''
        if(word_pos.length>0)
        {
          temp_pos = word.indexOf(conso_arr[j],word_pos[word_pos.length-1]+1);
        }
        else
        {
          temp_pos = word.indexOf(conso_arr[j]);
        }
        if((temp_pos!=-1)&&(temp_pos<temp_cons_pos))
        {
          temp_cons_pos = temp_pos;
          temp_letter = conso_arr[j];
        }
      }
      if((temp_letter == 'cc')&&(i<phonemes.length-1)) //for cases to distinguish 'success' from 'succulent'
      {
        if(phonemes[i+1]=='s')
        {
          temp_letter = 'c'
        }
      }
      
      for(let n=0;n<temp_letter.length;n++)
      {
        pho_pos.push(i);
      }
      if(temp_cons_pos!=word.length+1)
      {
        for(let m=0;m<temp_letter.length;m++)
        {
          word_pos.push(temp_cons_pos+m)
          phoneme_word[temp_cons_pos+m] = pho;  
        }
        
        pho_letter.push(temp_letter)
      }
      else
      {
        console.debug('phoneme '+ pho+' doesnt have corresponding letters')
      }
      // if((pho == 'k')&&(phonemes[i+1]=='s'))
      // {
      //   i=i+1;
      // }

    }
  }
  for(let k=0;k<phoneme_word.length;k++)
  {
    if(word_pos.includes(k))
    {
      let id = pho_pos[word_pos.indexOf(k)];
      phoneme_word[k] = phonemes[id];
      word_pos_in_pho.push(id);
      // if(word == test)
      // {
      //   console.debug('k= '+k+' pho_id= '+id)
      // }
    }
    else
    {
      let [min_word_pos,max_word_pos] = findIDRange(word_pos,k);
      if((min_word_pos == ''))
      {
        min_word_pos = word_pos[0];
      }
      if(max_word_pos == '')
      {
        max_word_pos = word_pos[word_pos.length-1];
      }
      let min_pho_pos = pho_pos[word_pos.indexOf(min_word_pos)];
      let max_pho_pos = pho_pos[word_pos.indexOf(max_word_pos)];
      
      let pho_id = min_pho_pos+k-min_word_pos;
      // if(word == test)
      // {
      //   console.debug('word pos min='+min_word_pos+' max= '+max_word_pos);
      //   console.debug('pho pos min='+min_pho_pos+' max= '+max_pho_pos);
      //   console.debug('k= '+k+' pho_id= '+pho_id)
      // }
      while(pho_id>=max_pho_pos)
      {
        pho_id = pho_id - 1
      }
      if(k>max_word_pos)
      {
        pho_id = max_pho_pos+k-max_word_pos;
        if(pho_id>phonemes.length-1)
        {
          pho_id = phonemes.length-1
        }
      }
      if(k<min_word_pos)
      {
        pho_id = min_pho_pos - (min_word_pos - k)
        if(pho_id<0)
        {
          pho_id = 0;
        }
      }
      // if(word == test)
      // {
      //   console.debug('new pho_id= '+pho_id)
      // }
      phoneme_word[k] = phonemes[pho_id];
      word_pos_in_pho.push(pho_id)
    }
  }
  return [phoneme_word,word_pos_in_pho]
}

export function getIssueFlag()
{
    
    let temp_iss_flag = localStorage.getItem('issue_flag');

    if(temp_iss_flag!=undefined && temp_iss_flag!=null)
    {
        
        let issue_flag_arr = temp_iss_flag.split(',')
        let issue_flag = [];
        for(let i=0;i<issue_flag_arr.length;i++)
        {
            let flag = (issue_flag_arr[i] == 'true')
            issue_flag.push(flag);
        }
        return issue_flag

    }
    else
    {
        let issue_flag = [true,true,true,true]

        return issue_flag
    }

}

export function toWords(num){
  var ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
              'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
              'seventeen', 'eighteen', 'nineteen'];
  var tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty',
              'ninety'];

  var numString = num.toString();

  if (num < 0) throw new Error('Negative numbers are not supported.');

  if (num === 0) return 'zero';

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + ' ' + ones[numString[1]];
  }

  //100 and more
  if (numString.length == 3) {
    if (numString[1] === '0' && numString[2] === '0')
      return ones[numString[0]] + ' hundred';
    else
      return ones[numString[0]] + ' hundred and ' + toWords(+(numString[1] + numString[2]));
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + ' thousand';
    if (end < 100) return ones[numString[0]] + ' thousand and ' + toWords(end);
    return ones[numString[0]] + ' thousand ' + toWords(end);
  }
}



import {updateDataDirectory} from '@/firebase/index'

const list_of_symbols_2_replace = {
  '&laquo;': '«',
  '&raquo;': '»',
  '&reg;':'®',
  '&deg;':'°',
  '&plusmn;':'±',
  '&middot;':'·',
  '&frac12;':'½',
  '&ndash;':'–',
  '&mdash;':'—',
  '&lsquo;':'‘',
  '&rsquo;':'’',
  '&sbquo;':'‚',
  '&ldquo;':'“',
  '&rdquo;':'”',
  '&bdquo;':'„',
  '&bull;':'•',
  '&prime;':'′',
  '&Prime;':'″',
  '&euro;':'€',
  '&trade;':'™',
  '&le;':'≤',
  '&ge;':'≥',
  '&lt;':'<',
  '&gt;':'>',
  '&rsquo;': '\'',
  '&quot;':'\"',
  '&pound;': '\£',
  '&dollar;':'\$'

}

const list_of_punctuation_2_remove= {
  '\\.': '',
  '\!': 'a',
  '\\?': '',
  '\,': 'a',
  '\;': 'a',
  '\\(': 'a',
  '\\)': 'a',
  '\\"': 'a'
}



export function checkUndefinedNull(in_data)
{

  return (typeof in_data===undefined || in_data===null)


}


export function getSeconds()
{

  var seconds = new Date().getTime() / 1000;

  return seconds
}

export function getTodayString()
{

  let today = new Date();
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0! so we add 1
  var yyyy = today.getFullYear()
  var hour = String(today.getHours()).padStart(2, '0')
  var minute = String(today.getMinutes()).padStart(2, '0')

  function zeroPad(nr,base){
    var  len = (String(base).length - String(nr).length)+1;
    return len > 0? new Array(len).join('0')+nr : nr;
  }

  mm = zeroPad(mm,2)
  dd = zeroPad(dd,2)
  hour = zeroPad(hour,2)
  minute = zeroPad(minute,2)

  today = yyyy + '-' + mm + '-' + dd + ' ' + hour + ':' + minute;


  return today;


}

export function removePunctuationFromString(text)
{
  if(text == undefined || text == null || text === "")
  {
    console.debug('Empty or undefined string')

  }
  else
  { 


    for(let k in list_of_punctuation_2_remove)
    {

      text = text.toString()

      let temp = {}
      temp[k] = '1'

  
  
      let re = new RegExp([k].join("|"),"gi"); 
  
  
      
      text = text.replace(re, '')
  
  

    }

  }

  return text
}

export function removeQuotesAndSimilarForAnalysis(text)
{

  text = text.replaceAll('\n','')

  let regex = new RegExp('[^a-zA-Z0-9][^a-zA-Z0-9]','g')

  text = text.replace(regex,' ') 

  let first_characters_replace = ["'","`",'"', "‘","’"]

  for(let k in first_characters_replace)
  {
    if(text.charAt(0)===first_characters_replace[k])
    {
      text = text.substring(1)
    }
  }

  let last_characters_replace = ["'","`",'"', "‘","’"]


  for(let k in last_characters_replace)
  {
    if(text.charAt(text.length-1)===last_characters_replace[k])
    {
      text = text.substring(0, text.length - 1);
    }
  }

  return text


}


export function removeWeirdCharactersFromString(text)
{
  if(text == undefined || text == null || text === "")
  {
    console.debug('Empty or undefined string')

  }
  else
  {
    text = text.toString()

    for(let i in list_of_symbols_2_replace)
    {
      let regex  = new RegExp(i,'g') //g is global, so it applies to all instances of this regex
      text = text.replace(regex,list_of_symbols_2_replace[i]) 
    }

  }

  return text
}


export function logActivity( type, activityData, isAssignment=false)
{

  let uid = localStorage.getItem('uid')

  const d = new Date();
  let month = d.getUTCMonth() + 1
  let datef = d.getUTCFullYear() + "-" + month + "-" + d.getUTCDate()

  let ms = d.valueOf()
  let log_data = {}
  log_data[ms] = activityData

  let user_page_red ='Users/' + uid + '/Activity/' + datef + '/' + type + '/other'
  
  if (isAssignment) {
    user_page_red ='Users/' + uid + '/Activity/' + datef + '/' + type + '/assignment'
  }

  updateDataDirectory(user_page_red, log_data);
}

export function getWeekNumber() {
  let d = new Date()
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  d = new Date()
  let day = d.getDay();

  console.debug('getday = ' + day)

  // Return array of year and week number and week day
  return [String(d.getUTCFullYear()), String(weekNo), String(day)];
}

export function substractDayFromWeekInfo(week_info)
{

  let day = week_info[2]
  let week = week_info[1]
  let year = week_info[0]

  if(day>1)
  {
    day = day-1
  }
  else
  {
    day = 7

    if(week>1)
    {
      week = week-1
    }
    else
    {
      week = 52
      year = year - 1
    }

    
  }


  return [year,week,day]



}