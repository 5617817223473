import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'
import "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//##########CONFIG############
const firebaseConfig = { 
    apiKey: import.meta.env.VITE_FIREBASE_APIKEY, 
    authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN, 
    databaseURL: import.meta.env.VITE_FIREBASE_DATABASEURL, 
    projectId: import.meta.env.VITE_FIREBASE_PROJECTID, 
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGEBUCKET, 
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGINGSENDERID, 
    appId: import.meta.env.VITE_FIREBASE_APPID, 
}
//##################################

//Initialize Firebase
export const firebaseInstance = firebase.initializeApp(firebaseConfig);


firebaseInstance.auth().onAuthStateChanged(async (user) => {

  //Function runs if there is any authentication status change
  if (user) {
    console.debug('status change logged');
    localStorage.setItem('uid', user.uid);

    getUserDataDir('Settings',function (err, result) {

      let region = result.child('region').val();

      if (region==undefined || region==null) {
        region = 'UK' 
      }
      
      localStorage.setItem('region', region); 

      let language = result.child('language').val();

      if(language==undefined || language==null) {
        language = 'UK' 
      }

      localStorage.setItem('language', language); 
    });

    getUserDataDir('Data', function (err, result) {
      localStorage.setItem('userCategory', result.child('category').val());
 
      let user_mic_calibration = result.child('user_mic_calibration').val()

      if (user_mic_calibration) {
        localStorage.setItem('user_mic_calibration', Number(user_mic_calibration));
      } else {
        localStorage.setItem('user_mic_calibration', Number(0));
      }
     
    });

    getUserDataDir('Subscription/', function (err, result) {
      let sub = result.val()

      sessionStorage.setItem("expiry", sub['expiry'])
      sessionStorage.setItem("tier", sub['id'])

      if (sub['id'] && sub['id'].includes('Free')) {
        if (sub['trial']) {

          const jsonString = JSON.stringify(sub['trial']);

          sessionStorage.setItem('trial', jsonString);
        }
      }

      if (sub.hasOwnProperty('license_status')) {
        for (const [key, value] of Object.entries(sub['license_status'])) {
          if (value === 'assigned') {
            sessionStorage.setItem("license_status", 'assigned')
          } else if (value === 'assigned_premium') {
            sessionStorage.setItem("license_status", 'assigned_premium')
          } else {

          }
        }
      } else {
        sessionStorage.setItem("license_status", null)
      }
    });


    let ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
    if(/trident/i.test(M[1])){
      tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
      return {name:'IE',version:(tem[1]||'')};
    }   

    if(M[1]==='Chrome'){
      tem=ua.match(/\bOPR|Edge\/(\d+)/)
      if(tem!=null)   {return {name:'Opera', version:tem[1]};}
    }

    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if ((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
      
    let browser_version = {
      name: M[0],
      version: M[1]
    }

    if(browser_version != undefined && browser_version != null) {
      let user_data_address = 'Users/' + user.uid + '/Settings/'
      updateDataDirectory(user_data_address,{browser_version:browser_version})
    }

    user.getIdToken().then((idToken) => {
      // Now you have the ID token (which is a JWT)
      console.log("ID token:", idToken);
      sessionStorage.setItem('idToken', idToken)
    }).catch((error) => {
      console.error("Error getting ID token:", error);
      sessionStorage.setItem('idToken', "")
    });

  } else {
    console.debug('status change not logged');
    sessionStorage.setItem('idToken', "")
    localStorage.setItem('uid', "");
    localStorage.setItem('userCategory', "");
  }
});

firebaseInstance.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebaseInstance.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  }
)};

export function getAuthToken() {

  return new Promise((resolve, reject) => {
    firebase.auth().currentUser.getIdToken(/* forceRefresh */ false).then(function(idToken) {

      resolve(idToken)
    }).catch(function(error) {
      console.error(error);
      reject()
    });
  })

}



export function logOut()
{
  firebaseInstance.auth().signOut().then(() => {
    //window.location.assign("/");


    // Sign-out successful.
    //localStorage.clear(); //mic permission is required in unsupported browsers
    sessionStorage.clear();
    window.location.assign("/signIn");
  }).catch((error) => {
    //window.location.assign("/");
    // An error happened.
  });
}


export function resetPassword(email)
{

  return firebaseInstance.auth().sendPasswordResetEmail(email)

}



export function createEmailUser(email, password, callback) {

  //Create a new user with email and password

  firebaseInstance.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
        // Signed in 
        var user = userCredential.user;
        callback(null, user);
        // ...
    })
    .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.debug('create user error = ' + errorMessage)
        callback(error);
        // ..
    });
}

export function signInWithCustomToken(customToken, callback) {
  console.log(customToken)
  firebaseInstance.auth().signInWithCustomToken(customToken).then((userCredential) => {

    const user = userCredential.user;
    firebaseInstance.database().ref('Users').child(user.uid).child('Data').update({"last_login": Math.floor(Date.now() / 1000)})
    callback(null);
  })
  .catch((error) => {
    console.debug(error.message);
    callback(error);
  });
}

export function emailLogin(email, password, callback) {
  //Login using an email and password

  firebaseInstance.auth().signInWithEmailAndPassword(email, password)
    .then((userCredential) => {

      const user = userCredential.user;

      //log login
      firebaseInstance.database().ref('Users').child(user.uid).child('Data').update({"last_login": Math.floor(Date.now() / 1000)})
      callback(null);

    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.debug(error.message);

      callback(error);


      // ..
    });
}


export function getData(label, callback) {

  firebaseInstance.database().ref(label).once("value", function (snapshot) {
    var peep = snapshot;

    // error will be null, and peep will contain the snapshot
    callback(null, peep);
  }, function (error) {

    // error wil be an Object
    callback(error);
  });
}

export function getDataDirectory(target_dir) {
  return firebaseInstance.database().ref(target_dir).get()
}

export function getDataDirectoryCallback(target_dir, callback) {

  firebaseInstance.database().ref(target_dir).once("value", (snapshot) => {

    if(snapshot.exists()) {
      let peep = snapshot;

      // error will be null, and peep will contain the snapshot
      callback(null, peep);
    } else {
      let peep = snapshot;
      callback(null, peep);
    }
  
  }).catch((error) => {
    console.error(error);
    callback(error);
  });
}

export function getUserDataDir(target_dir,callback) {

  var curr_user = firebaseInstance.auth().currentUser;
      
  //Get data from the currently logged in user
  if (curr_user) {
    let uid = curr_user.uid;

    firebaseInstance.database().ref('Users').child(uid).child(target_dir).once("value", function (snapshot) {
      var peep = snapshot;
      // error will be null, and peep will contain the snapshot
      callback(null, peep);
    }, function (error) {
      // error wil be an Object
      callback(error)
    });
  }

}


export function getUserData(callback) {

  //Get data from the currently logged in user

  let curr_user = firebaseInstance.auth().currentUser;

  let uid = curr_user.uid;

  firebaseInstance.database().ref('Users').child(uid).once("value", function (snapshot) {
    var peep = snapshot;
    // error will be null, and peep will contain the snapshot
    callback(null, peep);
  }, function (error) {
    // error wil be an Object
    callback(error)
  });

}

export function getDatabase() {
  return firebaseInstance.database();
}

export function setData(database_ref, input) {
  //Send data to a specific database
  database_ref.set(input);
}

export function setDataDirectory(path, input) {
  let database_ref = firebaseInstance.database().ref(path) 
  database_ref.set(input);
}


export function setUserData(target_dir, input) {
  var curr_user = firebaseInstance.auth().currentUser;

  let uid = curr_user.uid;

  let dir =  firebaseInstance.database().ref('Users').child(uid).child(target_dir) 
  dir.set(input)
}


export function updateUserData(target_dir, input) {

  // firebaseInstance.auth().onAuthStateChanged(user => {

  //Get data from the currently logged in user
  var curr_user = firebaseInstance.auth().currentUser;

  let uid = curr_user.uid;

  let dir =  firebaseInstance.database().ref('Users').child(uid).child(target_dir) 
  dir.update(input)

}

export async function updateDataDirectory(path, input) {
  let database_ref = firebaseInstance.database().ref(path) 
  database_ref.update(input);
}
    
export function updateData(database_ref, input) {
  database_ref.update(input)
}


export async function getFirestoreDoc(collection, docId) {

  const db = firebase.firestore();

  try {
    const docRef = db.collection(collection).doc(docId);

    const docSnap = await docRef.get()
    console.log(docSnap)
    if (docSnap.exists) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();

    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error accessing Firestore:", error);
  }

  return null
}

export function removeDirectory(path, input) {
  var curr_user = firebaseInstance.auth().currentUser;
  
  if (curr_user) {
    let database_ref = firebaseInstance.database().ref(path) 
    database_ref.remove(input);
  }
}





export function removeDirectoryCallback(target_dir, callback) {

  var curr_user = firebaseInstance.auth().currentUser;
  
  if (curr_user) {
    firebaseInstance.database().ref(target_dir).remove.then(() => {
      callback();
    });
  }

}