<template>
  <div class="d-flex flex-column min-vh-100">
  <Navbar :enableBack="true" title="Pub quiz practice"></Navbar>
  <div class="container-fluid">
    <div class="text-center">
      <div class="text-center card card-body">
        <div class="row mt-4 justify-content-center">
          <h2> What topic do you want to test your knowledge on?</h2>
        </div>
        <div class="row mt-4 justify-content-center">
          <input v-model="pub_quiz_form.topic" :placeholder="placeholder_text" class="form-control" style="fontSize:20px;width:40%" />
        </div>
        <div class="row mt-2 justify-content-center">
          <button v-if="!generating" class="btn btn-primary btn-lg mt-2 w-25" @click="generate_pub_quiz(pub_quiz_form.topic)">
            Start
          </button>
          <button v-else class="btn btn-primary btn-lg mt-2 w-25" disabled>
            <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
          </button>
        </div>
        <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
        <h4> Out of ideas? Try one of these by clicking here:</h4>
        <div class="mt-2">
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_pub_quiz('The Beatles')" style="margin-left:5mm">
            The Beatles
          </button>
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_pub_quiz('Ancient Greece')" style="margin-left:5mm">
            Ancient Greece
          </button>
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_pub_quiz('Liverpool FC in the 80s')" style="margin-left:5mm">
            Liverpool FC in the 80s
          </button>
        </div>
        <div class="mt-2">
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_pub_quiz('Leonardo Da Vinci')" style="margin-left:5mm">
            Leonardo Da Vinci
          </button>
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_pub_quiz('The Olympic Games')" style="margin-left:5mm">
            The Olympic Games
          </button>
          <button class="btn btn-secondary btn-lg mt-2" @click="generate_pub_quiz('Thailand')" style="margin-left:5mm">
            Thailand
          </button>
        </div>
      </div>
    </div>
  </div>
  <Footerbar class="mt-auto"></Footerbar>
</div>
</template>
<script>
  
  
  
  import {pub_quiz} from '@/utils/llm/methods'
  import Footerbar from '@/views/Footerbar.vue'
  import Navbar from '@/views/Navbar.vue'

  export default {
    name: 'pub_quiz_setup',
    components: {Navbar, Footerbar},
    data() {
      return {
        userCategory: '',
        results_data: '',
        generating: false,
        placeholder_text: 'British TV programmes from the 80s',
        pub_quiz_form:
        {
          topic:'',
          instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',
          n_item:3,
          text: [],
        },
      }
    },
    created() 
    {
      const _this = this
  
      _this.userCategory = localStorage.getItem("userCategory")

    },
  
    methods: 
    {
      generate_pub_quiz(topic)
      {
          const _this = this

          let n_item = _this.pub_quiz_form.n_item

          _this.generating = true


          if(topic==='')
          {
              topic = _this.placeholder_text
          }


          let list_quiz = pub_quiz(topic,n_item)

          list_quiz.then(function(result) {

                console.debug('list_quiz = ' + result)

                for(let i in result)
                {
                  for(let k in result[i])
                  {
                    console.debug('result['+i+']['+k+'] = ' + result[i][k])

                  }
                }

                let string_result = JSON.stringify(result)

                localStorage.setItem('list_quiz',string_result)

                _this.$router.push({ name: 'quiz_interest' } );

            })
          

      },
    }
  }
  </script>
  <style scoped>
  
  </style>