import {Module} from '@/utils/session/module.js'
import {Package} from '@/utils/session/package.js'
import {getUserDataDir, setUserData} from '@/firebase/index'
import { getDataDirectory} from '@/firebase/index'
import axios from 'axios';
import {getAuthToken} from '@/firebase/index'
import { useToast } from "vue-toastification";

export class Session {
  constructor(requestType, blueprint_id, blueprint_path, context) {

    this.context = context
    
    this.requestType = requestType
    
    this.blueprint_id = blueprint_id

    this.blueprint_path = blueprint_path

    this.module = null
    
    this.package = null

    this.isPackage = false

    this.userCategory = localStorage.getItem("userCategory")

    this.title = ''
  }

  async initialise() {
    let _this = this
    
    await this.getSubscription()

    await this.checkActivity()

    let meta = {}

    let start_object = {
      module_path:'', 
      assessment_type: "USER_TRAINING",
      module_name: '', 
      current_exercise_index: 1
    }

    if (_this.context.$route.query.start_object) {
      start_object = JSON.parse(decodeURIComponent(_this.context.$route.query.start_object))
    }
    
    meta = {  //don't move this
      start_object: start_object
    }

    if (_this.requestType === "assignment") {
      
      _this.isPackage = _this.context.$route.query.isPackage === "true"? true:false

      let pmeta = {
        index: Number(_this.context.$route.query.module_index),
        isPackage: _this.context.$route.query.isPackage === "true"? true:false,
        isAssessment: _this.context.$route.query.isAssessment === "true"? true:false,
        package_uid: _this.context.$route.query.package_uid,
        current_exercise_index: Number(_this.context.$route.query.current_exercise_index),
        start_object: meta.start_object
      }

      if (_this.isPackage) {
        this.package = new Package(pmeta)
        await this.package.initialise(_this.requestType, pmeta)
        _this.title = _this.package.title
      } else {
        _this.module = new Module(pmeta)
        await _this.module.initialise(_this.requestType)
        _this.title = _this.module.module_name
      }
      
    } else if (_this.requestType === "clinic_assessment_package") {
      //assessment package in clinic
      _this.isPackage = true


      let package_name = _this.context.$route.query.package_name
      let package_dir = _this.context.$route.query.package_dir

      meta = {
        package_name: package_name,
        package_dir: package_dir,
        start_object: start_object
      }
      
      let pmeta = {

      }
      
      this.package = new Package(meta)
      await this.package.initialise(_this.requestType, pmeta)
      _this.title = _this.package.title
    } else if (_this.requestType === "resume_clinic_assessment_package") {
      //assessment package in clinic

      _this.isPackage = true
      let packages_dir = 'Users/' + localStorage.getItem('client_uid') + '/Active_Assessment/packages/'
      let package_data = (await getDataDirectory(packages_dir)).val()

      if (Object.keys(package_data).length > 0) {
        let package_name = Object.keys(package_data)[0]

        meta = {
          package_name: package_name,
          package_dir: packages_dir + '/' + package_name,
          start_object: start_object
        }
          
        let pmeta = {
  
        }
        
        this.package = new Package(meta)
        await this.package.initialise(_this.requestType, pmeta)
        _this.title = _this.package.title
      }
      

    } else if (_this.requestType === "user_training") {
      _this.isPackage = false
      
      _this.module = new Module(meta)
      await _this.module.initialise(_this.requestType, _this.blueprint_id)
      _this.title = _this.module.module_name
    } else if (_this.requestType === "clinic_training" || _this.requestType === 'clinic_assessment') {
      _this.isPackage = false
      _this.module = new Module(meta)
      await _this.module.initialise(_this.requestType, null, _this.blueprint_path)
      _this.title = _this.module.module_name
    } else {
      _this.isPackage = false
      _this.module = new Module(meta)
      await _this.module.initialise(_this.requestType)
      _this.title = _this.module.module_name
    }

    return true
  }

  async getSubscription() {

    return new Promise(function(resolve)
    {

      getUserDataDir('Subscription/', function (err, result) {
        let sub = result.val()
      
        sessionStorage.setItem("expiry", sub['expiry'])
        sessionStorage.setItem("tier", sub['id'])

        //check if trial sub_trials exists
        if (sub['id'] && sub['id'].includes('Free')) {
          if (sub['trial']) {

            const jsonString = JSON.stringify(sub['trial']);

            sessionStorage.setItem('trial', jsonString);
            
          }
        }

        if (sub.hasOwnProperty('license_status')) {
          for (const [key, value] of Object.entries(sub['license_status'])) {
            
            if (value === 'assigned') {
              sessionStorage.setItem("license_status", 'assigned')
            } else if (value === 'assigned_premium') {
              sessionStorage.setItem("license_status", 'assigned_premium')
            } else {

            }
          }
        } else {
          sessionStorage.setItem("license_status", null)
        }
        resolve()
      });
    })

  }

  async next() {
    if (this.isPackage) {
      await this.package.next()
      this.title = this.package.title
    } else {
      await this.module.next()
      this.title = this.module.module_name
    }
    await this.checkActivity()
  }

  checkHasTrial(component_type) {
    let trial = JSON.parse(sessionStorage.getItem('trial'));

    if (trial) {
      //check if there is trial 
      const trial_components = trial['components']

      if (component_type in trial_components && 'start_timestamp' in trial_components[component_type]) {
        const start_timestamp = trial_components[component_type]['start_timestamp']

        if (start_timestamp) {
          //Calculate days in milliseconds
          const days = trial_components[component_type]['days']

          const daysInMs = days * 24 * 60 * 60 * 1000;

          const roundedDate = new Date(start_timestamp + daysInMs);
          // Move to the next day at 00:00:00
          const expiry = roundedDate.setHours(24, 0, 0, 0);
          //check expiry
          if (expiry > Date.now()) {
            //days from start_timestamp has not yet passed.
            //DO NOTHING
            return true
          } else {
            //days from start_timestamp has passed.
            return false
          }

        } else {
          //this should not happen as start_timestamp should exist
          return false
        }
      } else {
        return false
      }
    }
    //if no subscribe
    return false
  }

  async checkActivity() {
    let _this = this

    const d = new Date();
    let month = d.getUTCMonth() + 1
    let datef = d.getUTCFullYear() + "-" + month + "-" + d.getUTCDate()
    let total = 0
    let assignment = 0
    let other = 0

    await new Promise(function(resolve)
    {
      getUserDataDir("Activity/" + datef, function (err, handle) {

        if(handle.hasChildren())
        {
          handle.forEach(function (childSnapshot) {
            if (childSnapshot.key == "completed") {
              if (childSnapshot.val()['other']) {
                other = Object.keys(childSnapshot.val()['other']).length
              }
              if (childSnapshot.val()['assignment']) {
                assignment = Object.keys(childSnapshot.val()['assignment']).length
              }
              total = other + assignment

            }
          })
        }
        
        let subscription_tier = sessionStorage.getItem("tier")

        //initialise expiry date if first time
        if (subscription_tier.includes("F")) {
          
          let trial = JSON.parse(sessionStorage.getItem('trial'));
              
          if (trial) {
            let trial_components = trial['components']
            
            if (_this.requestType === "assignment") {
              if ('assignment' in trial_components) {
                if (sessionStorage.getItem('license_status') !== "assigned" && 
                    sessionStorage.getItem('license_status') !== "assigned_premium") {
                
                  if (!('start_timestamp' in trial_components['assignment'])) {
                    //check if assignment trial start date DOESN'T exists in the database

                    const post_data = {
                      uid: localStorage.getItem('uid'),
                      component: 'assignment',
                    };
                    
                    const api = import.meta.env.VITE_FAST_API

                    getAuthToken().then((idToken) => {
                      return axios.post(`${api}/set_timestamp`, post_data, {
                        headers: {
                          Authorization: `Bearer ${idToken}`,
                          'Content-Type': 'application/json',
                        },
                      });
                    })
                    .then((response) => {
                      _this.getSubscription().then(()=>{

                      })
                    })
                    .catch((error) => {
                      console.error("Error applying timestamp:", error);
                    });
                  

                  } else { /* empty */ }
                }
              }
            } else {
              if ('other' in trial_components) {
                if (sessionStorage.getItem('license_status') !== "assigned_premium") {
                  //check if other trial start date DOESN'T exists in the database
                  if (!('start_timestamp' in trial_components['other'])) {
                    //set timestamp to start trial

                    const post_data = {
                      uid: localStorage.getItem('uid'),
                      component: 'other',
                    };
                    
                    const api = import.meta.env.VITE_FAST_API

                    getAuthToken().then((idToken) => {
                      return axios.post(`${api}/set_timestamp`, post_data, {
                        headers: {
                          Authorization: `Bearer ${idToken}`,
                          'Content-Type': 'application/json',
                        },
                      });
                    })
                    .then((response) => {
                      _this.getSubscription().then(()=>{

                      })
                    })
                    .catch((error) => {
                      console.error("Error applying timestamp:", error);
                    });


                  } else { /* empty */ }
                }
              } else { /* empty */ }
            }
          }

        }

        if (total >= 5) {
          
          if (subscription_tier.includes("F")) {
            if (_this.userCategory === 'patient') {

              if (_this.requestType === "assignment") {
                if (sessionStorage.getItem('license_status') === "assigned" || 
                    sessionStorage.getItem('license_status') === "assigned_premium") {
                  //do nothing as patient has license by their SLT
                } else {
                  if (assignment >= 5) {
                    if (_this.checkHasTrial('assignment')) {

                    } else {
                      _this.context.$router.push({ name: 'subscribe'})    
                    }
                  }
                }

              } else {
                if (other >= 5) {
                  if (sessionStorage.getItem('license_status') !== "assigned_premium") {
                    //check if there is trial
                    if (_this.checkHasTrial('other')) {

                    } else {
                      _this.context.$router.push({ name: 'subscribe'})    
                    }
                  }
                }
              }
            } else {
              //SLT F tier go here

              //check if there is trial
              if (_this.checkHasTrial('other')) {

              } else {
                _this.context.$router.push({ name: 'subscribe'})    
              }
            }

          } else if (subscription_tier === "Trial1") { //this tier will be removed - only use for back compatibility
            //has super access for limited period so bypass restrictions

          } else if (subscription_tier === "T1") {
            if (_this.userCategory === "slp") {
              //do nothing there are no restrictions
            } else {
              //this tier is no assigned anymore
              if (_this.requestType === "assignment") {
                //do nothing as homework is unlimited in T1
              } else {
                //either take to subscription page to upgrade or notify that reached daily training limit
                _this.context.$router.push({ name: 'subscribe'})
              }
            }
          } else if (subscription_tier === "T2") {
            //do nothing as there is no restriction

          } else if (subscription_tier === "T3") {
            //do nothing as there is no restriction

          } else {
            //invalid tier -> take to subscription page
            _this.context.$router.push({ name: 'subscribe'})
          }
        }
        resolve()
      });
    })
  }

  getExForm() {
    let exForm
    
    if (this.isPackage) {
      exForm = this.package.getExForm()
    } else {
      exForm = this.module.exForm
    }

    return exForm
  }

  getShowResults() {
    let showResults

    if (this.isPackage) {
      showResults = this.package.getShowResults()
    } else {
      showResults = this.module.showResults
    }

    return showResults
  }

  getCurrentExerciseType() {
    let current_exercise_type

    if (this.isPackage) {
      current_exercise_type = this.package.getCurrentExerciseType()
    } else {
      current_exercise_type = this.module.current_exercise_type
    }

    return current_exercise_type
  }

  getResults() {
    let more_exercises_available
    let results_table
    if (this.isPackage) {
      more_exercises_available = this.package.more_exercises_available
      results_table = this.package.results_table
    } else {
      more_exercises_available = this.module.more_exercises_available
      results_table = this.module.results_table
    }

    return {"more_exercises_available": more_exercises_available, "results_table": results_table}
  }
  
  goToResults(goToResultsData)
  {
    let more_exercises_available
    let results_table
    if (this.isPackage) {
      this.package.showResultPage()
      more_exercises_available = this.package.more_exercises_available
      results_table = this.package.results_table
    } else {
      this.module.goToResults(goToResultsData)
      more_exercises_available = this.module.more_exercises_available
      results_table = this.module.results_table
    }

    return {"more_exercises_available": more_exercises_available, "results_table": results_table}
  }

  logExerciseResults(data)
  {
    this.checkActivity()
    if (this.isPackage) {
      this.package.logExerciseResults(data)
    } else {
      this.module.logExerciseResults(data)
    }
  }

}