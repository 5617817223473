<template>
<div>
  <Layout>
    <template v-slot:content>
      <!-- <div class="row">
        <span class="title_txt" style="fontSize:24px">Click on the correct answer</span>
      </div> -->
      <div class="row">

      <div class="card card-body">
        <span class="title_txt" style="margin-top:20px;fontSize:30px">{{question_text}}</span>
        <img :src="audioImg" class="mx-auto d-block" style="width:30px;cursor: pointer;" @click="listenHowToSayIt(question_text)">
      </div>
      </div>

      <div v-show="!digital_clock" class="row mt-4">
      <div class="col-4">
        <div class="card card-body" v-bind:style="{'width':'240px','margin':'auto','background':frameColour[0]}" :body-style="{ padding: '5px' }"  >
          <div @click="selectClock(0)">
            <div class="card card-body" v-bind:style="{'background':'FFFFFFF'}">
              <div class="clock" @click="selectClock(0)" >
                <div class="outer-clock-face">
                  <div class="marking marking-one"></div>
                  <div class="marking marking-two"></div>
                  <div class="marking marking-three"></div>
                  <div class="marking marking-four"></div>
                  <div class="inner-clock-face">
                      <div id="minute_0" class="hand hour-hand"></div>
                      <div id="hour_0" class="hand min-hand"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card card-body" v-bind:style="{'width':'260px','margin':'auto','background':frameColour[1]}" :body-style="{ padding: '5px' }">
          <div @click="selectClock(1)">
            <div class="card card-body" v-bind:style="{'background':'FFFFFFF'}">
              <div class="clock">
                <div class="outer-clock-face">
                  <div class="marking marking-one"></div>
                  <div class="marking marking-two"></div>
                  <div class="marking marking-three"></div>
                  <div class="marking marking-four"></div>
                  <div class="inner-clock-face">
                      <div id="minute_1" class="hand hour-hand"></div>
                      <div id="hour_1" class="hand min-hand"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card card-body" v-bind:style="{'width':'260px','margin':'auto','background':frameColour[2]}" :body-style="{ padding: '5px' }">
          <div @click="selectClock(2)">
            <div class="card card-body" v-bind:style="{'background':'FFFFFFF'}">
              <div class="clock" >
                <div class="outer-clock-face">
                    <div class="marking marking-one"></div>
                    <div class="marking marking-two"></div>
                    <div class="marking marking-three"></div>
                    <div class="marking marking-four"></div>
                    <div class="inner-clock-face">
                        <div id="minute_2" class="hand hour-hand"></div>
                        <div id="hour_2" class="hand min-hand"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
          <!--  -->

      <div v-show="digital_clock" class="row mt-4">
      <div class="col-4">
        <div class="card card-body" v-bind:style="{'width':'240px','margin':'auto','background':frameColour[0]}" :body-style="{ padding: '5px' }"  >
          <div @click="selectClock(0)">
            <div class="back_digital_clock">
              <div id="digital_clock_1" class="digital_clock" >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card card-body" v-bind:style="{'width':'260px','margin':'auto','background':frameColour[1]}" :body-style="{ padding: '5px' }">
          <div @click="selectClock(1)">
            <div class="back_digital_clock">
              <div id="digital_clock_2" class="digital_clock" >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card card-body" v-bind:style="{'width':'260px','margin':'auto','background':frameColour[2]}" :body-style="{ padding: '5px' }">
          <div @click="selectClock(2)">
            <div class="back_digital_clock">
              <div id="digital_clock_3" class="digital_clock" >
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </template>

    <template v-slot:sidebar>
      <Sidebar class="h-100"
        :current_exercise_index="current_exercise_index"
        :total_exercises="total_exercises"
        :has_next="true"
        @next="nextExercise()"

        :has_show_results="has_results"
        @show_results="goToResults()"   
        >
      </Sidebar>
    </template>
  </Layout>

  <div class="modal fade" id="level_up_pop" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Level up</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="m-3">
            <div class="row">
              <h4 >{{ level_up_message }}</h4>
            </div>
            <div class="row mt-2">
              <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import { getData, updateDataDirectory } from '@/firebase/index'
import doneIcon from '@/assets/images/icon_green_check.png'
import { save_pre_computed_results } from '@/utils/results/data_save_utils'
import audioImg from '@/assets/images/audio_logo.png'
import { logActivity } from '@/utils/utils'
import { startConfetti } from '@/common_js/confetti'
import { useTTS } from '@/stores/tts'
import { Modal } from 'bootstrap'

export default {
    name: 'clock_game',
    /*

    {
   "exercise_type":"clock_game",
   "formula":[
      1,
      1
   ],
   "instruct":"Click on the correct answer",
   "name":"footbal",
   "number_variations":[
      {
         "hour":2,
         "min":0
      },
      {
         "hour":0,
         "min":0
      }
   ],
   "numbers":[
      {
         "hour":16,
         "min":0
      },
      {
         "hour":1,
         "min":30
      }
   ],
   "question_text":[
      "The /variable_1/ starts at /number_1/ and it usually takes about /number_2/. When will the final whistle happen?"
   ],
   "variables":[
      [
         "footbal match",
         "rugby game"
      ]
   ],
   "plan_name":"Clock game",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Clock game/6",
   "ex_path":"Clock game/6",
   "today":"2023-07-11 17:26",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"clock_game",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"Click on the correct answer",
   "module_name":"Clock game",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
  }

  */
    props: {
        exForm: {
          type: Object,
        }
    },
    components: {Layout, Sidebar},
    data() {
      return {
        audioImg:audioImg,
        img_array: [],
        word_array: [],
        firebase_exercise_folder: '',
        firebase_exercise_folder_handle: '',
        question_text: '',
        target_time:null,
        all_times:null,
        results:{correct:0,incorrect:0,total:0},
        current_exercise_index:0,
        total_exercises:1,
        level_up_message: 'You have been doing really well! Let’s try something harder.',
        number_exercises:0,
        maths_level:'no_maths',
        doneIcon: doneIcon,
        curr_level_properties:{},
        level_properties:{
        1:{'level':1,'maths_level':'no_maths','total_hours':'12','digital_clock':true},
        2:{'level':2,'maths_level':'no_maths','total_hours':'24','digital_clock':true},  
        3:{'level':3,'maths_level':'no_maths','total_hours':'12','digital_clock':false},
        4:{'level':4,'maths_level':'no_maths','total_hours':'24','digital_clock':false},
        5:{'level':5,'maths_level':'simple_maths','simple_maths_hours_minutes':'hours','total_hours':'12','digital_clock':true},
        6:{'level':6,'maths_level':'simple_maths','simple_maths_hours_minutes':'minutes+','total_hours':'12','digital_clock':true},
        7:{'level':7,'maths_level':'simple_maths','simple_maths_hours_minutes':'minutes-','total_hours':'12','digital_clock':true},
        8:{'level':8,'maths_level':'simple_maths','simple_maths_hours_minutes':'hours','total_hours':'12','digital_clock':false},
        9:{'level':9,'maths_level':'simple_maths','simple_maths_hours_minutes':'minutes+','total_hours':'12','digital_clock':false},
        10:{'level':10,'maths_level':'simple_maths','simple_maths_hours_minutes':'minutes-','total_hours':'12','digital_clock':false},
        11:{'level':11,'maths_level':'puzzle','total_hours':'24','digital_clock':true},
        12:{'level':12,'maths_level':'puzzle','total_hours':'24','digital_clock':false},
        },
        level_up_counter:0,
        level_up_target:3,
        base_level_address:'/Levels/Clock_game',
        level_address:'',
        frameColour: ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
        minsHand:null,
        hourHand:null,
        digital_clock:true,
        has_results: false,
        bv_tts: useTTS().tts_engine,
      }
    },
    created() {
      const _this = this
      
      logActivity( 'accessed', 'clock_game')
    },
    beforeUnmount()
    {
      this.bv_tts.cancel()

    },
    mounted: function()
    {
      this.loadDifficulty()


      setTimeout(()=>{
          this.listenHowToSayIt(this.question_text)
      },500)
    },
    methods: 
    {
      nextExercise()
      {
        const _this = this;
      
        _this.saveResults()
        
        _this.$emit('nextExercise');
      },
      goToResults()
      {
        const _this = this
        _this.saveResults()
        _this.$emit('goToResults', false);
          
      },    
      listenHowToSayIt(text)
      {
        const _this = this
        
        // let string_2_substitute = ':'
        // let replacement_string = ' '
        
        // let regex  = new RegExp(string_2_substitute,'g') //g is global, so it applies to all instances of this regex
        // text = text.replace(regex,String(replacement_string)) 
        
        
        var mapObj = {
            "00:" : ":",
            "01:00" : "1 am",
            "02:00" : "2 am",
            "03:00" : "3 am",
            "04:00" : "4 am",
            "05:00" : "5 am",
            "06:00" : "6 am",
            "07:00" : "7 am",
            "08:00" : "8 am",
            "09:00" : "9 am",
            "10:00" : "10 am",
            "11:00" : "11 am",
            "12:00" : "noon",
            "13:00" : "1 pm",
            "14:00" : "2 pm",
            "15:00" : "3 pm",
            "16:00" : "4 pm",
            "17:00" : "5 pm",
            "18:00" : "6 pm",
            "19:00" : "7 pm",
            "20:00" : "8 pm",
            "21:00" : "9 pm",
            "22:00" : "10 pm",
            "23:00" : "11 pm",
            "24:00" : "midnight",
            "01:" : "1:",
            "02:" : "2:",
            "03:" : "3:",
            "04:" : "4:",
            "05:" : "5:",
            "06:" : "6:",
            "07:" : "7:",
            "08:" : "8:",
            "09:" : "9:",
            ":00": " ",
            ":01":" o 1",
            ":02":" o 2",
            ":03":" o 3",
            ":04":" o 4",
            ":05":" o 5",
            ":06":" o 6",
            ":07":" o 7",
            ":08":" o 8",
            ":09":" o 9"
        };

     
        let re = new RegExp(Object.keys(mapObj).join("|"),"gi"); 

        let mapped_text = text



        mapped_text = mapped_text.replace(re, function(matched){
            return mapObj[matched];
        });


        if(mapped_text.length>200)
        {
          let array_text = mapped_text.split('.')

          this.bv_tts.say_sentences(array_text, 75)


        }
        else
        {
          this.bv_tts.say(mapped_text)

        }



        
        // this.bv_tts.say(mapped_text)
      },
      saveResults()
      {
        const _this = this


        if(_this.results['total']>0)
        {
          let all_results = {
            Correct: _this.results['correct'],
            Incorrect: _this.results['incorrect'],
            Total: _this.results['total']
          }
          
          let plot_results = {
            Correct: _this.results['correct'],
            Incorrect: _this.results['incorrect']}
          
            let results = {
            all_results: all_results, 
            plot_results: plot_results
          }

          let exercise_name = 'Clock Game'

          
          let clockData = {
            resultsTablePre: {
              exercise_type: 'Life Skills', 
              exercise: exercise_name, 
              level: this.current_level,
              results: results, 
              audio_address:''
            }
          }
          
          save_pre_computed_results(_this.exForm, clockData)

          _this.$emit('logExerciseResults', clockData);

        }
        
          


      },
      selectClock(index)
      {
        const _this = this
        _this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];
        
        // console.debug('this.selectClock')
        
        if(index==_this.target_clock)
        {
            
            if(_this.results['incorrect']==0)
            {
              if(_this.results['correct']==0)
              {
                this.updateLevel(true)

              }
              _this.results['correct'] = 1
              _this.results['incorrect'] = 0

            }

            let minutes = this.all_times[this.target_clock]['min']

                
            if(minutes==='0' || minutes===0)
            {
                minutes='00'
            }

            let time_message = this.all_times[this.target_clock]['hour'] + ':' + minutes

            this.listenHowToSayIt(time_message + ' is correct! Press Next to go to the next exercise.')
            startConfetti(this)


            
            _this.frameColour[index] = '#00FF00';
            
        }
        else
        {

          if(_this.results['correct']==0 && _this.results['incorrect']==0)
          {
            this.updateLevel(false)
            _this.results['correct'] = 0
          
          _this.results['incorrect'] = 1

          }
          _this.frameColour[index] = '#FF0000';

          this.listenHowToSayIt('Not quite right. Try again or press Next to go to the next exercise.')
        }
        _this.results['total'] = 1
      },
      updateLevel(correct)
      {

        if(correct)
        {
          this.level_up_counter++
          if(this.level_up_counter == this.level_up_target)
          {
              this.level_up_counter = 0
              this.current_level++

              if(this.current_level>Object.keys(this.level_properties).length)
              {
                this.current_level = Object.keys(this.level_properties).length
              }
              else
              {
                this.level_up_message = "You have been doing really well! Let’s try something harder."
                let level_up_pop = new Modal(document.getElementById('level_up_pop'));
                level_up_pop.show()

              }

          }
        }
        else
        {
          this.level_up_counter--

          if(this.level_up_counter<0)
          {
            this.level_up_counter = 0
          }
        }

        updateDataDirectory(this.level_address,{current_level:this.current_level,level_up_counter:this.level_up_counter})

      },
      updateDigitalClock()
      {
        const _this = this

        let clock_names = ['digital_clock_1','digital_clock_2','digital_clock_3']


        for(let i in clock_names)
        {

          let m = _this.all_times[i]['min']
          let h = _this.all_times[i]['hour']

          // if(h == 0){
          //   h = 12;
          // }
          
          // if(h > 12){
          //     h = h - 12;
          // }
          
          h = (h < 10) ? "0" + h : h;
          m = (m < 10) ? "0" + m : m;
          
          var time = h + ":" + m;
          document.getElementById(clock_names[i]).innerText = time;
          document.getElementById(clock_names[i]).textContent = time;

        }


    
        


        
      },
      updateClock()
      {

        const _this = this

        console.debug('updateClock')
        
        _this.minsHand = [document.getElementById('minute_0'),document.getElementById('minute_1'),document.getElementById('minute_2')];
        _this.hourHand = [document.getElementById('hour_0'),document.getElementById('hour_1'),document.getElementById('hour_2')];
        


        for(let n=0;n<3;++n)
        {
            _this.setClock(n)
        }
      },
      setClock(index)
      {
        const _this = this
        
        let mins = _this.all_times[index]['min']
        let hour = _this.all_times[index]['hour']
        
        const minsDegrees = ((mins / 60) * 360)  + 90;
        const hourDegrees = ((hour / 12) * 360) + ((mins/60)*30) + 90;
        
        _this.minsHand[index].style.transform = `rotate(${minsDegrees}deg)`;
        _this.hourHand[index].style.transform = `rotate(${hourDegrees}deg)`;
      },
      loadExercises()
      {
        let _this = this;
        
        let do_once = true;
        
        getData(_this.firebase_exercise_folder , function (err, result) {
            
            //Inputs new exercise on the current user's database
            
            if (do_once) {
                
                do_once = false;
                _this.firebase_exercise_folder_handle = result;
                
                
                _this.setup();
                
            }
            
        });
      },
      loadDifficulty()
      {
        let _this = this

        let userType = localStorage.getItem('userCategory');

        let uid = localStorage.getItem('uid');

        this.level_address = '/Users/' + uid + this.base_level_address

        if(userType!=undefined || userType==='slp') {
          let client_number = localStorage.getItem('client_uid');

          if(client_number!='undefined' && client_number!=null && client_number!='') {
            this.level_address = '/Users/'+ client_number + this.base_level_address
          }
        }

        let level_address = this.level_address

        getData(level_address, function (err, result) {
          let current_level = result.child('current_level').val()
          let lvl_up_counter = result.child('level_up_counter').val()

          console.debug('current_level = ' + current_level)
          console.debug('lvl_up_counter = ' + current_level)

          if(current_level==undefined || lvl_up_counter==undefined) {
            current_level = 1
            lvl_up_counter = 0
            
            updateDataDirectory(level_address, 
              {
                current_level:current_level,  
                level_up_counter:lvl_up_counter
              }
            )
          }

          _this.current_level = current_level
          _this.level_up_counter = lvl_up_counter

          _this.setup()
        })

      },
      setup()
      {
        const _this = this;
        
        let exercise_data = _this.exForm
        _this.has_results = _this.exForm.has_results
        
        let formula = exercise_data['formula']
        let question_text = exercise_data['question_text']
        let number_variations = exercise_data['number_variations']
        let numbers = exercise_data['numbers']
        let variables = exercise_data['variables']


        if(this.current_level>Object.keys(this.level_properties).length)
        {
          this.current_level = Object.keys(this.level_properties).length
        }
        else if(this.current_level<1)
        {
          this.current_level = 1
        }


     
        let curr_level_properties = this.level_properties[this.current_level] 

        console.debug('this.current_level  = ' + this.current_level)

        this.curr_level_properties = curr_level_properties;


        this.digital_clock = curr_level_properties.digital_clock



        this.maths_level = curr_level_properties.maths_level



        
        _this.current_exercise_index = _this.exForm.current_exercise_index + 1
        
        _this.total_exercises = _this.exForm.total_all_type_exercises
        
        
        numbers = _this.computeVariations(numbers,number_variations, 100)


        
        _this.target_time = _this.computeResult(formula,numbers)

        if(this.maths_level==='no_maths')
        {

          if(curr_level_properties.total_hours=='12')
          {
            if(this.target_time['hour']>12)
            {
              this.target_time['hour'] = this.target_time['hour']-12
            }
          }
          let question_text = 'Pick the clock showing /number_1/'
          numbers = []
          numbers.push(_this.target_time)
          variables = []
          _this.question_text = _this.fixQuestionText(question_text,numbers,variables)

        }
        else if(this.maths_level==='simple_maths')
        {

          this.create_simple_math_options(curr_level_properties.simple_maths_hours_minutes)

        }
        else
        {
          _this.question_text = _this.fixQuestionText(question_text,numbers,variables)
        }
        
        
        
        _this.all_times = []
        _this.all_mods = []
        
        _this.n_clocks = 3
        
        
        _this.target_clock = Math.floor(Math.random()*_this.n_clocks)
        
        for(let n=0;n<_this.n_clocks;++n)
        {
            _this.all_times.push(_this.target_time)
            
            if(_this.target_clock==n)
            {
                _this.all_mods.push({hour:0,min:0})
                
            }
            else
            {
                _this.all_mods.push({hour:2,min:30})
            }
        }
        
      
        _this.all_times = _this.computeVariations(_this.all_times,_this.all_mods,_this.target_clock)

        if(this.digital_clock)
        {
          _this.updateDigitalClock()

        }
        else
        {
          _this.updateClock()

        }
      },
      create_simple_math_options(hours_or_minutes)
      {
        const _this = this

        if(_this.target_time['hour']>9)
        {
          _this.target_time['hour'] = 9
        }
        else if(_this.target_time['hour']<3)
        {
          _this.target_time['hour'] = 3

        }


        let operator = 'plus'

        let random_plus_minus_time  = Math.floor(Math.random() * Math.floor(2))+1;

        let random_binary  = Math.floor(Math.random() * Math.floor(2));

        if(random_binary==1)
        {
          operator = 'minus'
        }


        if(hours_or_minutes==='minutes+')
        {
          let minutes_lookup = [10,30]

          random_plus_minus_time = minutes_lookup[random_plus_minus_time-1]

          operator = 'plus'

        }
        else if(hours_or_minutes==='minutes-')
        {
          let minutes_lookup = [10,30]

          random_plus_minus_time = minutes_lookup[random_plus_minus_time-1]

          operator = 'minus'

        }


        if(operator==='minus')
        {

          random_plus_minus_time = -random_plus_minus_time
          
        }

        if(hours_or_minutes.includes('minutes'))
        {
          hours_or_minutes = 'minutes'
        }


        if(Math.abs(random_plus_minus_time)==1)
        {
          hours_or_minutes = hours_or_minutes.substring(0,hours_or_minutes.length-1)
        }

        let question_text = 'Pick the clock showing /number_1/ ' + operator + ' ' + Math.abs(random_plus_minus_time) + ' ' + hours_or_minutes 



        let numbers = []
        numbers.push(_this.target_time)
        let variables = []
        _this.question_text = _this.fixQuestionText(question_text,numbers,variables)



        if(hours_or_minutes.includes('minutes'))
        {


          _this.target_time['min'] = _this.target_time['min'] + random_plus_minus_time

        
        }
        else
        {
          _this.target_time['hour'] = _this.target_time['hour'] + random_plus_minus_time

        }

        _this.target_time = this.fixSummedClockTime(_this.target_time)


        




      },
      fixQuestionText(question_text,numbers,variables)
      { 
        let n_numbers = numbers.length
        
        question_text = question_text.toString()
        
        for(let n=0;n<n_numbers;++n)
        {
            let string_2_substitute = '/number_' + String(n+1)+'/'
            
            let hour = numbers[n]['hour']
            let min = numbers[n]['min']
            
            let string_hour = String(hour)
            let string_min = String(min)
            
            if(string_min.length<2)
            {
                string_min = '0' + string_min
            }
            
            let replacement_string = string_hour + ':' + string_min
            
            if(n>0)
            {
                
                replacement_string = ''
                
                if(hour>0)
                {
                    if(hour==1)
                    {
                        replacement_string += string_hour + ' hour'
                        
                        
                    }
                    else
                    {
                        replacement_string += string_hour + ' hours'
                    }
                    
                    if(min>0)
                    {
                        replacement_string += ' and ' + string_min + ' minutes'
                        
                    }
                    
                }
                else
                {
                    replacement_string += string_min + ' minutes'
                }
                
            }
            
            let regex  = new RegExp(string_2_substitute,'g') //g is global, so it applies to all instances of this regex
            question_text = question_text.replace(regex,String(replacement_string)) 
        }
        
        
        if(variables!=undefined && variables!=null)
        {
            let n_variables = variables.length
            for(let n=0;n<n_variables;++n)
            {
                let string_2_substitute = '/variable_' + String(n+1)+'/'
                
                let curr_variable = variables[n][Math.floor(Math.random()*variables[n].length)];
                
                let regex  = new RegExp(string_2_substitute,'g') //g is global, so it applies to all instances of this regex
                question_text = question_text.replace(regex,String(curr_variable)) 
            }
        }
        
        return question_text
      },
      computeVariations(numbers,number_variations, target_clock)
      {
        const _this = this
        let n_numbers = numbers.length
        let n_variations = number_variations.length
        
        let N = Math.min(n_numbers,n_variations)
        
        let output = []



        let prev_variations = []


        
        
        for(let n=0;n<N;++n)
        {
            let curr_hour = numbers[n]['hour']
            let curr_min = numbers[n]['min']
            
            let curr_variation = number_variations[n]
            let curr_variation_limit_hour = curr_variation['hour']
            let curr_variation_limit_min = curr_variation['min']
            
            
            let curr_variation_hour = Math.round(Math.random()*curr_variation_limit_hour)
            
            
            let curr_variation_min = Math.round(Math.random()*curr_variation_limit_min)

            
                curr_hour = curr_hour+curr_variation_hour
                curr_min = curr_min+curr_variation_min 
                
                
                let curr_time = {hour:curr_hour,min:curr_min}
                
                curr_time = _this.fixSummedClockTime(curr_time)
                
                
                if(target_clock<N && target_clock!=n)
                {
                    let target_hour = numbers[target_clock]['hour']
                    let target_min = numbers[target_clock]['min']
                    
                    if(target_hour==curr_time['hour'] && target_min==curr_time['min'])
                    {
                        if(curr_time['min']<50)
                        {
                            curr_time['min'] = curr_time['min'] + 10
                        }
                        else
                        {
                            curr_time['min'] = curr_time['min'] - 10
                        }
                    }
                }
                
                
                output.push(curr_time)
                
            }


            //very shit way to make sure the clocks don't repeat
            for(let i in output)
            {
              for(let k in output)
              {
                if(i!=k && Math.abs(output[i].min-output[k].min)<10 && Math.abs(output[i].hour-output[k].hour)<1)
                {
                  output[i].hour = output[i].hour+1
                  if(output[i].hour==output[target_clock].hour && output[i].min==output[target_clock].min)   
                  {
                    output[i].hour = output[i].hour[i]-2

                  }             
                }
              }
            }


            
            return output
            
        },
        fixSummedClockTime(curr_time)
        {
            let hour = curr_time['hour']
            let min = curr_time['min']
            
            
            
            if(min<0)
            {
                hour = hour+Math.floor(min/60)
                
                min = 60+(min%60)
                
            }
            else
            {
                hour = hour+Math.floor(min/60)
                
                min = min%60
            }
            min = 10*Math.floor(min/10)
            return {hour:hour,min:min}
        },
        computeResult(formula,numbers)
        {
            let target_time = {hour:0,min:0}
            const _this = this
            
            let n_numbers = numbers.length
            for(let n=0;n<n_numbers;++n)
            {
                target_time['hour'] = target_time['hour'] + formula[n]*numbers[n]['hour']
                target_time['min'] = target_time['min'] + formula[n]*numbers[n]['min']
            }
            target_time = _this.fixSummedClockTime(target_time)
            
            return target_time 
        },
      }
    }
</script>

<style scoped>

@font-face{
 font-family:'digital-clock-font';
 src: url('@/assets/fonts/digital-7.ttf');
 }

.digital_clock
{
  position: relative;
    /* top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); */
    color: cyan;
    font-size: 80px;
    font-family:  'digital-clock-font';
    letter-spacing: 7px;
   
}

.back_digital_clock {
    	background-color: #3d3d3f;
    }

.clock {
    width: 10rem;
    height: 10rem;
    border: 3px solid #282828;
    border-radius: 50%;
    margin: 5px auto;
    position: relative;
    padding: 1rem;
    
}

.outer-clock-face {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #282828;
    
    
    overflow: hidden;
}

.outer-clock-face::after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg)
}

.outer-clock-face::before,
.outer-clock-face::after,
.outer-clock-face .marking{
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    background: #1df52f;
    z-index: 0;
    left: 49%;
}

.outer-clock-face .marking {
    background: #bdbdcb;
    width: 2px;
}

.outer-clock-face .marking.marking-one {
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    transform: rotate(30deg)
}

.outer-clock-face .marking.marking-two {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    transform: rotate(60deg)
}

.outer-clock-face .marking.marking-three {
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    transform: rotate(120deg)
}

.outer-clock-face .marking.marking-four {
    -webkit-transform: rotate(150deg);
    -moz-transform: rotate(150deg);
    transform: rotate(150deg)
}

.inner-clock-face {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    background: #282828;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    z-index: 1;
}

.inner-clock-face::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 18px;
    margin-left: -9px;
    margin-top: -6px;
    background: #4d4b63;
    z-index: 11;
}

.hand {
    width: 50%;
    right: 50%;
    height: 6px;
    background: #61afff;
    position: absolute;
    top: 50%;
    border-radius: 6px;
    transform-origin: 100%;
    transform: rotate(90deg);
    transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
}

.hand.hour-hand {
    background: #ee791a;
    
    height: 3px;
    z-index: 10;
    width: 45%;
}

.hand.min-hand {
    width: 30%;
    z-index: 3;
    
}


</style>

<!-- Result Description
{
  "Clock Game": {
    "<key>": {
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>", //audio address is here because that's how I used to find where the results are
        "exercise": "Clock Game",
        "exercise_type": "Life Skills",
        "results": {
          "all_results": {
            "Correct": "<int>",
            "Incorrect": "<int>",
            "Total": "<int>"
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    }
  }
}
    -->