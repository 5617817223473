<template>
  <div>
    <div class="container">
      <h1 class="display-5">{{ title }}</h1>
      <div class="card card-body">
        <div class="m-4">
          <p class="lead">{{ description }}.</p>
          <div class="card card-body">
            <div class="row" v-for="row in qn_size" :key="row">
              <span class="custom-text">
                {{ row }}. {{ qs[row - 1] }}
              </span>

              <FormKit v-model="form.options[row - 1]" :options="radio_options" type="radio" label="" />
            </div>
            <button class="btn btn-primary btn-lg" @click="submit()"> Submit results</button>
          </div>

          <div class="modal fade" id="results_modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Results
                  </h5>
                </div>
                <div class="modal-body">
                  <div class="card card-body">
                    <span class="custom-text" style="color:blue">The Z-Score is {{ z_score.toFixed(1) }} <br><br> </span>
                    <p class="lead" v-if="z_score.toFixed(1) <= 1">Very good. Your voice does <b :style="msg_color"> not
                        have a significant impact </b> on your daily life.</p>
                    <p class="lead" v-else> It seems that your voice condition has <b
                        :style="msg_color">{{ z_score_meaning }}</b> on your daily life.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<script>

import { save_pre_computed_results } from '@/utils/results/data_save_utils'
import { BV_Points } from '@/utils/bv_points'

export default {
  name: 'ui_patient',
  props:
  {
    exForm: {
      type: Array,
    },
  },
  data() {
    return {
      title: '',
      description: '',
      userCategory: localStorage.getItem('userCategory'),
      qn_size: 0,
      loaded_data: null,
      questionnaire_type: null,
      qs: [],
      rating: [],
      radio_options: {},
      rate_scores: [0, 1, 2, 3, 4],
      population_mean: 0,
      population_std: 1,
      z_score: 0,
      z_score_meaning: '',
      showResults: false,
      form: {
        options: []
      },
      msg_color: '',
      results_modal: '',
      bv_points: new BV_Points()
    }
  },
  watch: {
    showResults(new_results, old_results) {
      if (new_results) {
        this.results_modal = new Modal(document.getElementById('results_modal'));
        this.results_modal.show();
      } else {
        if (this.results_modal) {
          this.results_modal.hide();
        }
      }
    }
  },
  created() {
    this.process_loaded_data();
  },
  methods: {
    process_loaded_data() {
      const _this = this

      _this.loaded_data = _this.exForm

      let questions = _this.loaded_data['Ex']

      for (let k in questions) {
        _this.qs.push(questions[k])
      }

      _this.qn_size = _this.qs.length

      let options = _this.loaded_data['options']

      for (let i = 0; i < _this.rate_scores.length; i++) {
        let input = { judge: '', score: i }
        _this.rating.push(input);
      }

      for (let k in options) {
        console.debug('k = ' + k)
        _this.rating[options[k]].judge = k
      }

      _this.radio_options = Object.assign({}, ..._this.rating.map((i) => ({ [i.judge]: i.score })))
      _this.questionnaire_type = _this.loaded_data['type']

      _this.description = _this.loaded_data['description']
      _this.title = _this.loaded_data['title']
    },
    saveResults() {
      const _this = this

      let results = _this.form.options

      let assessment_questionnaire_data = { 
        resultsTablePre: { 
          exercise_type: _this.questionnaire_type, 
          exercise: _this.questionnaire_type, 
          results: results, 
          audio_address: ''
        } 
      }

      save_pre_computed_results(_this.exForm,  assessment_questionnaire_data)

      _this.bv_points.add(10)

      _this.$emit('logExerciseResults', assessment_questionnaire_data);
    },
  },
}
</script>
<style scoped>
.page_title {
  width: 100%;
  height: 40px;
  background-color: #7cc543;
}

.page_main {
  width: 100%;
  height: calc(100vh - 40px);
  background-color: #195e52;
  overflow: auto;
}

.title_txt {
  float: left;
  line-height: 40px;
  margin-left: 2%;
  font-size: 30px;
}

.el-menu-item {
  font-size: 20px;
}
</style>