<template>
  <div class="gauge-container">

    <svg viewBox="0 0 200 220" class="gauge">
      <!-- Draw each compartment as a donut segment -->
      <g v-for="(comp, index) in compartmentPaths" :key="index">
        <path :d="comp.d" :fill="comp.fill" stroke="#fff" stroke-width="1" />
      </g>

      <!-- Draw the needle -->
      <line x1="100" y1="100" :x2="needle.x" :y2="needle.y" stroke="black" stroke-width="2" />
      <circle cx="100" cy="100" r="4" fill="black" />
      <!-- Labels -->
      <text x="20" y="115" text-anchor="middle" font-size="12">Whisper</text>
      <text x="150" y="115" text-anchor="middle" font-size="12">Loud Conversation</text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Speedometer',
  props: {
    loudness_data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // Value from 0 (left) to 100 (right) that controls the needle
      value_percent: 0,
      // Gap between compartments in degrees
      gapAngle: 2,
      // Radii for the donut segments (tweak as needed)
      outerRadius: 80,
      innerRadius: 40,
      // Define the five compartments. Each has an active flag and two colors.
      compartments: [
        { active: true,  activeColor: '#ff0000', inactiveColor: '#ddd', l_limit:0},
        { active: false, activeColor: '#ff7f00', inactiveColor: '#ddd',l_limit:57 },
        { active: false, activeColor: '#ffff00', inactiveColor: '#ddd',l_limit:62 },
        { active: false, activeColor: '#7fff00', inactiveColor: '#ddd',l_limit:67 },
        { active: false, activeColor: '#00ff00', inactiveColor: '#ddd',l_limit:72 },
        
      ]
    };
  },
  watch: {
    loudness_data: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.db !== undefined) {
          let db_value = newValue.db;
          console.debug("value_percent = " + db_value);

          for (let n in this.compartments) {
            this.compartments[n].active = this.compartments[n].l_limit <= db_value;
          }

          let max_sound = 77
          let min_sound = 52

          let output = (db_value - min_sound) / (max_sound-min_sound);

          this.value_percent = Math.max(0, Math.min(100, output * 100));
          console.debug("Updated value_percent:", this.value_percent);
        }
      },
    },
  },
  computed: {
    // Create the SVG path data for each donut segment
   
    compartmentPaths() {
      const totalArc = 180; // semicircular arc
      const count = this.compartments.length;
      // Total gap spread across compartments (only between them)
      const totalGap = (count - 1) * this.gapAngle;
      // Remaining arc divided evenly among compartments
      const effectiveArc = totalArc - totalGap;
      const sliceAngle = effectiveArc / count;
      const paths = [];
      for (let i = 0; i < count; i++) {
        // Starting from the left (180°) going to the right (0°)
        const startAngle = 215 + i * (sliceAngle + this.gapAngle);
        const endAngle = startAngle - sliceAngle;


        const d = this.describeDonutSegment(100, 100, this.innerRadius, this.outerRadius, startAngle, endAngle);
        const fill = this.compartments[i].active
          ? this.compartments[i].activeColor
          : this.compartments[i].inactiveColor;
        paths.push({ d, fill });
      }
      return paths;
    },
    // Compute the needle endpoint
    // Maps value=0 to angle=180° (left) and value=100 to angle=0° (right)
    needle() {
      const angle = 180 + (this.value_percent * 180 / 100);
      // Choose a needle length between inner and outer radii
      const length = (this.innerRadius + this.outerRadius) / 2;
      return this.polarToCartesian(100, 100, length, angle);
    }
  },
  methods: {
    // Convert polar coordinates (angle in degrees) to Cartesian (x, y)
    polarToCartesian(cx, cy, radius, angleInDegrees) {
      const angleInRadians = angleInDegrees * Math.PI / 180;



      return {
        x: cx + radius * Math.cos(angleInRadians),
        y: cy + radius * Math.sin(angleInRadians)
      };
    },
    // Generate an SVG path string for a donut segment defined by:
    // center (cx, cy), inner & outer radii, and start & end angles.
    describeDonutSegment(cx, cy, innerRadius, outerRadius, startAngle, endAngle) {
      // Outer arc: from startAngle to endAngle
      const outerStart = this.polarToCartesian(cx, cy, outerRadius, startAngle);
      const outerEnd = this.polarToCartesian(cx, cy, outerRadius, endAngle);
      // Inner arc: from endAngle back to startAngle
      const innerEnd = this.polarToCartesian(cx, cy, innerRadius, endAngle);
      const innerStart = this.polarToCartesian(cx, cy, innerRadius, startAngle);
      
      // Calculate if the arc spans more than 180°
      const outerDelta = startAngle - endAngle;
      const outerLargeArcFlag = outerDelta <= 180 ? "0" : "1";
      const innerLargeArcFlag = outerLargeArcFlag; // same angle span
      
      // Build the path string:
      const d = [
        "M", outerStart.x, outerStart.y,
        "A", outerRadius, outerRadius, 0, outerLargeArcFlag, 0, outerEnd.x, outerEnd.y,
        "L", innerEnd.x, innerEnd.y,
        "A", innerRadius, innerRadius, 0, innerLargeArcFlag, 1, innerStart.x, innerStart.y,
        "Z"
      ].join(" ");
      
      return d;
    }
  }
};
</script>

<style scoped>
.gauge-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gauge {
  width: 800px;
  height: 440px;
}
</style>
