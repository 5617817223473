import {contact_ai} from './interface'

import { removeUpToFirstInstance } from '../utils';

export async function sendMessage(conversationHistory, role) {

  const botReply = await interactWithChatbot(role, conversationHistory);
  
  return botReply
}

export async function interactWithChatbot(role, conversationHistory) {

  const messages = conversationHistory.map(msg => ({ role: msg.role, content: msg.content }));
  const prompt = `You are a ${role}.` + (messages.length > 0 ? "\nUser says: " + messages.map(msg => `${msg.role}: ${msg.content}`).join("\n") : "");

  let raw_answer = await contact_ai(prompt)


  raw_answer = removeUpToFirstInstance(raw_answer,':')

  
  return raw_answer
}