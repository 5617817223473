import axios from 'axios'
import { getAuthToken } from '@/firebase/index'

export async function contact_ai(request, version='3.5') {

  let rt = null
  
  try {
    const url = import.meta.env.VITE_FAST_API + '/llm_request'

    const request_data = {
      request: request,
      version: version,
    };
    
    let idToken = await getAuthToken()
    const response = await axios.post(url, request_data, {
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      }
    });

    console.log('Response from FastAPI:', response.data.response);
    rt = response.data.response;
  
  } catch (error) {
    console.error('Error calling the /llm_request API:', error.response ? error.response.data : error.message);
  }

  return rt
}


