<template>
<div>
  <Layout>
    <template v-slot:content>
      <div class="card card-body">
        <span class="read-text"> {{instruction}} <img :src="audioImg" style="width:35px;margin-left:1%;cursor: pointer;" @click="bv_tts.say(instruction)"></span>
        <p class="h5" :style="interaction_color">{{interaction_msg}}</p>
      </div>  

      <div class="parent">
        <span hidden>{{dummy}}</span>
        <span hidden>{{currentSentence_found}}</span>

        <img class="backimg" :src="backImg" :style="backImg_info.size" @click="interaction('back',0)">
          <!-- <img class="frontimg" :src="vaseImg"
          style="width:120px;top:50px;left:500px;cursor: pointer;" @click="testFunc()"> -->
          <img v-for="num in frontImg.length" :key="num" class="frontimg" :src="frontImg[num-1]"
          :style="frontImg_info[num-1].size" style="cursor: pointer;margin-left: 50%;" @click="interaction('front',num-1)">
      </div>
    </template>

    <template v-slot:sidebar>

      <Sidebar class="h-100"
        :current_exercise_index="current_exercise_index"
        :total_exercises="total_exercises"
        :audioCaptureOn = "show_btn"
        :mic_on = "is_recording"

        @started = "playPause()"
        @stopped = "playPause()"

        :has_next="started"
				@next="nextExercise()"



        >






        <template v-slot:middle>

          <div style="margin-top: 15%;">


          <button v-if="has_results" class="btn btn-outline-warning btn-lg w-100 mt-3" @click="goToResults()">See your results</button>
          </div>
          <div style="margin-top: 15%;">


          <p class="mb-0">Need help?</p>


          <button v-if="half_word_url!=null" class="btn btn-secondary btn-lg mt-4 w-100" @click="listenHint()">Sound hint <i class="bi bi-ear"></i></button>
            <button
              class="btn btn-outline-primary btn-lg w-100 mt-4 rounded" 
              @click="showAnswer()">
              <p class="mb-0" style="font-size: 16px;"> Show answer <i class="bi bi-file-earmark-check"></i> </p>
            </button>
          </div>
        </template>
      </Sidebar>
      
    </template>
  </Layout>
</div>
</template>


<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'
import {checkPermissionsMediaBasic} from '@/media_input/microphone_recording'
import {LevenshteinDistance} from '@/utils/text_distance_metrics'

import {getUserDataDir,getData,updateDataDirectory} from '@/firebase/index'
import {logActivity} from '@/utils/utils'
import SpeechRec from '@/media_input/SpeechRecognition'

// import tableImg from '@/assets/images/image_click/table_alpha.png'
// import vaseImg from '@/assets/images/image_click/vase_white_alpha.png'
// import flowerImg from '@/assets/images/image_click/vase_alpha.png'
// import testImg from '@/assets/images/image_click/burger_white_alpha.png'
import audioImg from '@/assets/images/audio_logo.png'

import { useTTS } from '@/stores/tts'
import {startConfetti} from '@/common_js/confetti'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {BV_Points} from '@/utils/bv_points'

export default {
  name: 'Scene_description',
  /*

  {
   "Name":"Scene description random",
   "exercise_type":"scene_description",
   "setting":"random",
   "plan_name":"Real life items",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Real life items//Exercises/General/Aphasia/Scene Description/exercises/random",
   "ex_path":"Real life items//Exercises/General/Aphasia/Scene Description/exercises/random",
   "today":"2023-07-11 17:22",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"scene_description",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "module_name":"Real life items",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":1
  }

*/
  props: 
  {
    exForm: {
      type: Object,
    },
  },
  components: {Layout, Sidebar},
  data(){
    return {
      base_level_address:'/Levels/Scenario Description',
      level_address:'',
      userCategory:'',
      region:'',
      skip_button: "Skip the whole exercise",
      audioImg:audioImg,
      backImg:null,
      backImg_info:{},
      frontImg:[],
      frontImg_info:[],
      dummy:'',
      instruction:'Click on an item in the image below to start',
      operation_instruction:'',
      record_instruction:"Press the 'Record' button below to give your answer.",
      check_instruction:"Press the 'Check answer' button to check your answer.",
      next_instruction: "Press Next to see more questions.",
      new_item_instruction:'Click on an item in the image below to see more questions',
      interaction_msg:'',
      interaction_color:'',
      show_btn:false,
      total_exercises:1,
      play_pause_text:'Record answer',
      has_results:false,
      listener_ref:null,
      answer_text: '',
      resultsTable: [],
      results:{'correct':0,'correct_hint':0,'incorrect':0,'total':0},
      show_answer_counter:0,
      show_hint_counter:0,
      first_answer:'',
      uid:'',
      play_status: false,
      audio_player: null,
      half_word_url: null,
      current_qs:[],
      qs_count:0,
      max_qs:3,
      check_count:0,
      right_answer:'',
      previous_img_name: null,
      previous_img_id: 0,
      target_scenario:null,
      finishScene:false,
      history_scene_id:[],
      total_scene_num:0,
      speech_rec: null,
      currentSentence: '',
      target_words:[],
      started: false,
      answer_text_color: '',
      found_target_word:false,
      is_recording:false,
      scenario_id:0,
      bv_tts: useTTS().tts_engine,
      bv_points: new BV_Points()
    }
  },
  created() {

    const _this = this

    checkPermissionsMediaBasic()

    _this.current_exercise_index = _this.exForm.current_exercise_index+1
    _this.total_exercises = _this.exForm.total_all_type_exercises

    this.userCategory = localStorage.getItem('userCategory');
    this.region = localStorage.getItem('region');
    
    logActivity( 'accessed', 'scene_description')
    
    let uid = localStorage.getItem('uid');
    _this.uid = uid;

    this.chooseScenario();

    this.speech_rec = new SpeechRec(function(captions) {
      this.currentSentence = captions.original
    }.bind(this))
  },
  mounted()
  {
    const _this = this
    _this.bv_tts.say(_this.instruction)
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()
  },
  computed: {

    currentSentence_found: function () {

      console.debug('currentSentence_found')
      
      if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
      {
        let curr_sentence = this.currentSentence.toString().toLowerCase()

        console.debug('curr_sentence = ' + curr_sentence)

        console.debug('this.target_words = ' + this.target_words)

        let found_something = false

        let minimum_distance = 100
        let best_answer = null

        for(let i in this.target_words)
        {
          let distance = LevenshteinDistance(this.target_words[i],curr_sentence)

          distance = distance/Math.max(this.target_words[i].length, 1)

          if(distance<minimum_distance && distance<0.35)
          {
            best_answer = this.target_words[i]
            this.stop_recording()
            found_something = true
          }
        }

        if(!found_something)
        {
          let split_sentence = curr_sentence.split(' ')

          for(let i in this.target_words)
          {

            for(let k in split_sentence)
            {
              let word = split_sentence[k]

              console.debug()

              
              let distance = LevenshteinDistance(this.target_words[i],word)

              distance = distance/Math.max(this.target_words[i].length, 1)

              if(distance<minimum_distance && distance<0.35)
              {
                best_answer = this.target_words[i]
                this.stop_recording()
                found_something = true
              }

              
            }
            
          }

          

        }

        if(best_answer!=null)
        {
          this.found_correct_word(best_answer) 
        }

        // for(let i in this.target_words)
        // {
        //     if(this.target_words[i]!='')
        //     {
        //       if(this.target_words[i].includes(curr_sentence) || curr_sentence.includes(this.target_words[i]))
        //       {
        //         console.debug('found = ' + curr_sentence)
        //           this.found_target_word = 'true'
        //           this.stop_recording()
        //           this.found_correct_word(this.target_words[i])
        //           found_something = true
        //           break
        //       }
        //     }
        // }

        if(!found_something)
        {
            this.found_target_word = 'false'
        }
        else
        {
          return curr_sentence

        }

      }

      return ''

    },
  },
  methods: {
    goToResults()
    {
      const _this = this

      this.saveResults()

      let goToResultsData = {curr_ex_done:true}
      this.$emit('goToResults', goToResultsData);
    },
    chooseScenario()
    {
      const _this = this

      let userType = localStorage.getItem('userCategory');


      let uid = localStorage.getItem('uid');

      this.level_address = '/Users/' + uid + this.base_level_address


      if(userType!=undefined || userType==='slp')
      {
          let client_number = localStorage.getItem('client_uid');

          if(client_number!='undefined' && client_number!=null && client_number!='')
          {

              this.level_address = '/Users/'+ client_number + this.base_level_address

          }
      }



      let address = this.level_address

      let do_once = true

      console.debug('chooseScenario')

      getData(address, function (err, result) {
        console.debug('here')

        if(do_once)
        {
          do_once = false
          console.debug('chooseScenario2')

          let curr_scenario = result.child('Scenario ID').val()

          console.debug('curr_scenario = ' + curr_scenario)

          if(curr_scenario==undefined || curr_scenario==null)
          {
            curr_scenario = 0
          }
          else
          {
            curr_scenario = parseInt(curr_scenario)
          }
          console.debug('curr_scenario = ' + curr_scenario)
          _this.scenario_id = curr_scenario
          // curr_scenario = curr_scenario+1
          // if(curr_scenario>(_this.total_scene_num-1))
          // {
          //   curr_scenario = 0
          // }

          // curr_scenario = curr_scenario.toString()

          // updateDataDirectory(address,{'Scenario ID':curr_scenario})
          

          _this.loadImage()
        }
      })
    },
    closeDialog()
    {
      this.finishScene = false;
    },
    nextExercise()
    {
      const _this = this;
      this.bv_tts.cancel()

      _this.$emit('nextExercise');
    },
    loadImage()
    {
      let _this = this;
      _this.backImg = null;
      _this.backImg_info = {};
      _this.frontImg = [];
      _this.frontImg_info = [];
      let image_folder_ref = _this.$firebase.database().ref("Exercises").child('General').child('Aphasia').child('Image_click');
      // let image_folder_ref = _this.$firebase.database().ref("Exercises").child('General').child('Aphasia').child('Image_click_newImg_stage'); //for debugging new images in the scene description
      var do_once = true;
      getData(image_folder_ref , function (err, result) {

        if(do_once)
        {
          do_once = false;
          let full_scenario = []
          result.forEach(function (childSnapshot) {
              let scenario = childSnapshot.key;
              full_scenario.push(scenario);
          }); 
          _this.total_scene_num = full_scenario.length;

          let address = _this.level_address

          let curr_scenario = _this.scenario_id
          curr_scenario = curr_scenario+1
          if(curr_scenario>(_this.total_scene_num-1))
          {
            curr_scenario = 0
          }

          curr_scenario = curr_scenario.toString()

          updateDataDirectory(address,{'Scenario ID':curr_scenario})

          if(_this.history_scene_id.length!=0)
          {
            for(let i=0;i<_this.history_scene_id.length;i++)
            {
              full_scenario.splice(_this.history_scene_id[i],1);
            }
          }
          // let scenario_id = Math.floor(Math.random() * full_scenario.length);
          let scenario_id = _this.scenario_id
          // scenario_id = 3; //force scene for debugging
          _this.history_scene_id.push(scenario_id);
          _this.target_scenario = full_scenario[scenario_id];
          // console.debug('scenario= '+_this.target_scenario);
          _this.backImg_info = result.child(_this.target_scenario).child('backImg').child('0').val();
          // console.debug('image name = '+_this.backImg_info.name);
          _this.backImg = new URL(`../../../../../assets/images/image_click/${_this.target_scenario}/${_this.backImg_info.name}.png`, import.meta.url)
          _this.backImg_info.size = "width:"+_this.backImg_info.size;
          _this.backImg_info.checked = true;
          console.debug('imag size= '+_this.backImg_info.size);

          result.child(_this.target_scenario).child('frontImg').forEach(function (childSnapshot) {
              let frontInfo_temp = childSnapshot.val();
              console.debug('front image name = '+frontInfo_temp.name)
              frontInfo_temp.size = "width:"+frontInfo_temp.size+";"+frontInfo_temp.loc;
              frontInfo_temp.checked = false;
              _this.frontImg_info.push(frontInfo_temp);
              let frontImg_temp = new URL(`../../../../../assets/images/image_click/${_this.target_scenario}/${frontInfo_temp.name}_white_alpha.png`, import.meta.url)
              _this.frontImg.push(frontImg_temp)
          });
          console.debug('front info size= '+_this.frontImg_info.length);
          // _this.loadLocation();
        }
      });
        
    },
    interaction(name,id)
    {
      this.dummy = this.dummy + '1'
      this.interaction_msg = '';
      this.qs_count = 0;
    
      if(name == 'front')
      {
        if(!this.frontImg_info[id].checked)
        {
          if(this.previous_img_name != null)
          {
            if(this.previous_img_name == 'back')
            {
              this.backImg = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${this.backImg_info.name}.png`, import.meta.url)
            }
            else if(this.previous_img_name == 'front')
            {
              this.frontImg[this.previous_img_id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${this.frontImg_info[this.previous_img_id].name}_alpha.png`, import.meta.url)
            }
          }
          this.check_count = this.check_count+1;
          this.frontImg_info[id].checked = true;
          this.frontImg[id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${ this.frontImg_info[id].name}_halo_alpha.png`, import.meta.url) 
          console.debug('front clicked= '+this.frontImg_info[id].name);
          this.instruction = this.frontImg_info[id].q_a[0].question;
          console.debug('saying ' + this.instruction)
          this.bv_tts.say(this.instruction)
          this.right_answer = this.frontImg_info[id].q_a[0].answer;
          this.target_words = this.right_answer.split(';')

          this.operation_instruction = this.record_instruction;
          this.show_btn = true;
          this.half_word_url = null

          this.first_answer = this.right_answer.split(';')[0]
          this.get_half_word_url(this.first_answer)

          this.current_qs = [];
          this.current_qs = this.frontImg_info[id].q_a;
          this.current_qs.splice(0,1);
        }
      }
      this.previous_img_name = name;
      this.previous_img_id = id;
    },
    get_half_word_url(word)
    {
      let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'
      const _this = this;
      const storage = _this.$firebase.storage();

      storage.ref(audio_file_location).getDownloadURL()
      .then((url) => {
        _this.half_word_url = url
        console.debug('Found half word url!!')
      })
      .catch((error) => {
        console.debug('error ' + error )
      });
    },
    listenHint() 
    {

      const _this = this
      this.show_hint_counter = this.show_hint_counter+1

      let url = _this.half_word_url

      if(_this.audio_player==null)
      {
        _this.audio_player = new Audio(url);
        _this.audio_player.play();
      }
      else
      {
        if(!_this.audio_player.paused)
        {
        _this.audio_player.pause();

        
        }
        // else
    
        {
        _this.audio_player = new Audio(url);

        _this.audio_player.play();

        }
      }
    },
    nextQuestion()
    {
      const _this = this

      this.saveResults()
      this.interaction_msg = '';
      console.debug('qs_count= '+this.qs_count)
      if(_this.play_status)
      {
        // _this.pause();
        // _this.endRecordingAudio(false)
        _this.play_pause_text = 'Record answer'
        _this.play_status = false
        _this.started = false
        clearInterval(window.refreshIntervalId)
      }
      
      if(this.qs_count<this.max_qs -1)//take out the question[0] which is the first question asked when clicked
      {
        this.qs_count = this.qs_count+1;
        let rand_question_id = Math.floor(Math.random() * this.current_qs.length);
        this.instruction = this.current_qs[rand_question_id].question;
        this.bv_tts.say(this.instruction)

        this.right_answer = this.current_qs[rand_question_id].answer;
        console.debug('this.right_answer = ' + this.right_answer)
        this.target_words = this.right_answer.split(';')
        this.operation_instruction = this.record_instruction;
        this.show_btn = true;
        this.half_word_url = null

        let first_answer = this.right_answer.split(';')[0]

        this.get_half_word_url(first_answer)

        this.current_qs.splice(rand_question_id,1)
      }
      else {
          
        console.debug('check count = '+this.check_count)
        console.debug('this.frontImg.length = '+this.frontImg.length)
        console.debug('this.history_scene_id.length = '+this.history_scene_id.length)
        console.debug('this.total_scene_num = '+this.total_scene_num)

        console.debug('here 0')

        console.debug('this.history_scene_id.length = ' + this.history_scene_id.length)
        console.debug('this.total_scene_num = ' + this.total_scene_num)


        if(this.check_count ==  this.frontImg.length)
        {
          console.debug('in here')

          _this.$emit('nextExercise');
        }
        else
        {
          this.instruction = this.new_item_instruction;
          this.bv_tts.say(this.instruction)
          this.operation_instruction = '';
          this.show_btn = false;
          this.half_word_url = null
          this.qs_count = 0;
        }
      }
    },
    playPause()
    {
      let _this = this;


      if(!this.play_status)
      {
        _this.results['total'] = 1

        _this.play_status = true;
        _this.resumeRecording()      
      }
      else
      {

        _this.interaction_msg = ''


        _this.is_recording = false
        _this.play_status = false
        _this.stop_recording()


        _this.nextQuestion()
        // _this.nextExercise()
      }

    },
    resumeRecording()
    {
      let _this = this;
  
      // recordAudioMedia()
      _this.is_recording = true;
      // _this.runTimer()

      _this.start_recording()
    },
    start_recording() {
      const _this = this

      console.debug('this.target_words  = ' + this.target_words)
      this.speech_rec.start(this.target_words,false,this.region)
      this.started = true
      this.play_pause_text = 'Recording'
      console.debug('started')
      clearInterval(window.countDownTimer)
      // clearInterval(window.countDownTimer2)
      clearInterval(window.countDownTimer3)

      window.countDownTimer = setTimeout(() => {
        _this.interaction_msg = 'It is recording now, answer the question now'
        _this.interaction_color = 'fontSize:24px;color:blue;white-space: pre-wrap;'
      }, 300)

      // window.countDownTimer2 = setTimeout(() => {

      //   if(_this.play_status)
      //   {
      //     _this.started = false
      //     _this.play_pause_text = 'Record answer'

      //     // _this.answer_text = "Sorry, we didn\'t hear it clearly, could you repeat please?"
      //     // _this.answer_text_color = 'fontSize:24px;color:red;white-space: pre-wrap'

      //     _this.interaction_color = 'fontSize:24px;color:red';

      //     _this.results['correct'] = 0
      //     _this.results['incorrect'] = 0
      //     _this.results['total'] = 0
      //     _this.interaction_msg = "Sorry, we couldn't recognise what you just said. Do you want to try it again.";
      //   }

      // }, 7500)
      
      window.countDownTimer3 = setTimeout(() => {

        if(_this.play_status)
        {
          _this.started = false
          _this.play_pause_text = 'Record answer'

          _this.interaction_color = 'fontSize:24px;color:red;white-space: pre-wrap;';

          _this.interaction_msg = 'Sorry, you did not get it right this time. Try it again.';
          _this.results['total'] = 1

          _this.results['correct'] = 0
          _this.results['correct_hint'] = 0
          _this.results['incorrect'] = 1

        }

        _this.stop_recording()

      }, 15000)
    },

    saveResults()
    {
      console.debug('saveResults')
      const _this = this

      if( _this.results['total']!=0)
      {
        console.debug('here 1')
        if(_this.results['correct']==0 && _this.results['correct_hint']==0)
        {
          _this.results['incorrect'] = 1
        }

        console.debug(_this.results)



        let all_results = {
          Correct: _this.results['correct'],
          Correct_hint: _this.results['correct_hint'],
          Incorrect: _this.results['incorrect'], 
          Total:1, 
          Show_Answer: _this.show_answer_counter, 
          Sound_Hints: _this.show_hint_counter,
          Target_Word: _this.first_answer
        }

        let plot_results = {
          Correct: _this.results['correct'],
          Incorrect:_this.results['incorrect']
        }

        let results = {
          all_results: all_results, 
          plot_results: plot_results
        }


        var scene_description_data = {
          resultsTablePre: {
            exercise_type: 'Scene Description', 
            exercise: 'Scene Description', 
            results: results, 
            audio_address: '',
            word: this.first_answer
          }
        }


        save_pre_computed_results(_this.exForm, scene_description_data)

        _this.bv_points.add(10)

        _this.$emit('logExerciseResults', scene_description_data);

        _this.has_results = true

      }

      _this.results['total'] = 0

      _this.results['correct'] = 0
      _this.results['correct_hint'] = 0

      _this.results['incorrect'] = 0
      _this.show_answer_counter = 0
      _this.show_hint_counter = 0
      




    },
    stop_recording() {
        const _this = this

        clearInterval(window.countDownTimer)
        clearInterval(window.countDownTimer3)

        _this.play_status = false;
        _this.currentSentence = ''
        _this.speech_rec.stop()
    },
    found_correct_word(curr_sentence)
    {
        const _this = this

        _this.results['total'] = 1

        _this.interaction_msg = 'Congratulations! You said the right word: ' + curr_sentence +'. \n'

        if(this.show_hint_counter>0)
        {
          _this.results['correct_hint'] = 1

        }
        else
        {
          _this.results['correct'] = 1

        }
        
        _this.results['incorrect'] = 0
        _this.interaction_color = 'fontSize:24px;color:green;white-space: pre-wrap;';

        startConfetti(this)


        _this.started = false
        _this.play_status = false
        _this.play_pause_text = 'Record answer'

        
   setTimeout(() => {
       _this.nextQuestion()
      //  _this.nextExercise()

      }, 1000)


        console.debug('_this.answer_text = ' + _this.curr_sentence)



    },
    showAnswer()
    {


        let first_answer = this.right_answer.split(';')[0]

        this.show_answer_counter = this.show_answer_counter+1 
        this.interaction_msg = "The answer is: "+first_answer;
        this.interaction_color = 'fontSize:24px;color:#0099ff;white-space: pre-wrap;';
    },
    loadLocation()
    {
        let all_loc = this.backImg_info.all_loc;
        for(let m =0; m<this.frontImg.length;m++)
        {
            let rand_loc_id = Math.floor(Math.random() * all_loc.length);
            this.frontImg_info[m].size = this.frontImg_info[m].size + ";"+all_loc[rand_loc_id];
            console.debug('front image loc= '+this.frontImg_info[m].size)
            all_loc.splice(rand_loc_id,1);
        }
    },
    randLocation()
    {
        let range_x_min = this.backImg_info.range_x[0];
        let range_x_max = this.backImg_info.range_x[1];
        let range_y_min = this.backImg_info.range_y[0];
        let range_y_max = this.backImg_info.range_y[1];
        let div_x = Math.floor(this.frontImg.length/2);
        let div_y = this.frontImg.length-div_x;

        let all_loc = [];
        for(let j = 0;j<div_x;j++)
        {
          for(let k=0;k<div_y;k++)
          {
            let x = range_x_min + Math.floor((range_x_max-range_x_min)/div_x)*(j+1);
            let y = range_y_min + Math.floor((range_y_max-range_y_min)/div_y)*(k+1);
            let loc = "top:"+y+"%;left:"+x+"%";
            all_loc.push(loc);
          }
        }

        for(let m =0; m<this.frontImg.length;m++)
        {
          let rand_loc_id = Math.floor(Math.random() * all_loc.length);
          this.frontImg_info[m].size = this.frontImg_info[m].size + ";"+all_loc[rand_loc_id];
          console.debug('front image loc= '+this.frontImg_info[m].size)
          all_loc.splice(rand_loc_id,1);
        }
    },
  }
}
</script>

<style scoped>

.parent {
  position: relative;
  top: 0;
  left: 0;
}
.backimg {
  position: relative;
  top: 0;
  left: 0;
  /* border: 1px red solid; */
}
.frontimg {
  position: absolute;
  /* top: 60px;
  left: 500px; */
  /* border: 1px green solid; */
}

</style>