<template>
  <div >
    <h1 class="display-5">{{title}}</h1>
    <div class="card card-body">
      <div class="m-4">
      <!-- <p class="lead">{{ instruct }}</p> -->
        <FormKit type="form" :key="formKey" @submit="handleSubmit"  @submit-invalid="resetValidation" class="questionnaire-form" >


            <div class="form-container">

            <p class="lead" style="text-align: left; font-size: 1.5em; font-weight: bold; color: rebeccapurple;">{{page['title']}}</p>

            <hr style="height:2px;border:none;color:#333;background-color:green;" />

            <div>

              <div v-for="question,q_index in all_pages[page_number]['questions']" v-bind:key="q_index">

                <div v-if="question['type'] === 'multi_question_multi_selection'">

                  <div class="mb-4" v-for="row,mq_index in question['questions']" :key="mq_index">

                 <h5 style="text-align: left">
                 {{mq_index+1}}. {{row['question']}}
                  </h5>
                  <FormKit
                    v-model="row['temp_answer']"
                    :options="row['options']"
                    wrapper-class= "form-check-inline ms-4"
                    input-class= "form-check-input ms-2"
                    option-class= "list-group"
                    options-class= "list-group list-group-horizontal justify-content-center mt-4"
                    validation="required"
                    :validation-messages="{ required: 'Please select an option before proceeding' }" 

                    type="radio"
                  />

           
                     
                  <hr style="height:2px;border:none;color:#333;background-color:rebeccapurple;" />

                  </div>
                </div>

                <div v-if="question['type'] === 'dip_section_e'">
                  <h5>
                    1. List 4 things, apart from speech and communication that upset or worry you.
                  </h5>
                      <div class="input-group">
                        <label>A</label>
                        <FormKit type="text"  v-model="answers[0][0]" name="A" />
                      </div>
                      <div class="input-group">
                        <label>B</label>
                        <FormKit type="text"  v-model="answers[0][1]" name="B" />
                      </div>
                      <div class="input-group">
                        <label>C</label>
                        <FormKit type="text" v-model="answers[0][2]" name="C" />
                      </div>
                      <div class="input-group">
                        <label>D</label>
                        <FormKit type="text"  v-model="answers[0][3]" name="D" />
                      </div>
                      <div class="input-group">
                        <label>E</label>
                        <FormKit type="text" name="E" value="Speech Difficulty" disabled />
                      </div>

                      <hr style="height:2px;border:none;color:#333;background-color:rebeccapurple;" />

                      <h5>
                        2. Rate these five things from 1 to 5, where 1 is what you are most concerned about and 5 is the thing you are least worried about.
                      </h5>                     
                      <div class="input-group">
                        <label>1 Most concerned/most worried about</label>
                        <FormKit type="select" v-model="answers[1][0]" :options="leftover_options" name="most_worried" />
                      </div>
                      <div class="input-group">
                        <label>2 Concerned/very worried about</label>
                        <FormKit type="select" v-model="answers[1][1]" :options="leftover_options" name="very_worried"  />
                      </div>
                      <div class="input-group">
                        <label>3 Concerned/worried about</label>
                        <FormKit type="select" v-model="answers[1][2]" :options="leftover_options" name="worried"  />
                      </div>
                      <div class="input-group">
                        <label>4 Slightly concerned/slightly worried about</label>
                        <FormKit type="select" v-model="answers[1][3]" :options="leftover_options" name="slightly_worried"  />
                      </div>
                      <div class="input-group">
                        <label>5 Least concerned/worried about</label>
                        <FormKit type="select" v-model="answers[1][4]" :options="leftover_options" name="least_worried"  />
                      </div>

                      <hr style="height:2px;border:none;color:#333;background-color:rebeccapurple;" />

                      <h5>
                        3. This questionnaire was completed by:
                      </h5>   


                      <div class="radio-wrapper" style="margin-bottom: 1%;">

                      <label class="radio-label">
                          <FormKit 
                            type="radio"
                            :value="'Me on my own'"
                            name="completion_method"
                            validation="required"
                            input-class="radio-input"
                            :validation-messages="{ required: 'Please select an option before proceeding' }"
                            @input="updateCompletionMethod('Me with the help of a relative or friend')"

                          />
                          <span class="radio-text">Me on my own</span>
                        </label>

                      </div>
                      <div class="radio-wrapper">

                        <label class="radio-label">
                          <FormKit 
                            type="radio"
                            :value="'Me with the help of a relative or friend'"
                            name="completion_method"
                            validation="required"
                            input-class="radio-input"
                            :validation-messages="{ required: 'Please select an option before proceeding' }"
                            @input="updateCompletionMethod('Me with the help of a relative or friend')"

                          />
                          <span class="radio-text">Me with the help of a relative or friend</span>
                        </label>

                      </div>

                </div>

              </div>

              <div style="margin-top: 2%;">

              </div>
              <FormKit 
                type="textsection"
                label="Additional Details"
                name="additionalDetails"
                v-model="questions['additionalDetails']"
                input-class="textsection-input"
              />
          </div>
        </div>

              
      </FormKit>
    </div>

  </div>
</div>

</template>
<script>

import {getUserDataDir, getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {VHI_computeZScore, VHI_computeStandardScore} from '@/utils/session/exercise_type/vhi/vhi'

import {getTodayString} from '@/utils/utils'
import { Modal } from 'bootstrap'

export default {
  name: 'selection_scales',
  props: {
      exForm: {
        type: Object,
      },
    },
  data(){
    return {
      formKey: 0, // Key to force re-rendering
      selectedOption: null,
      additionalDetails: '',
      all_pages:{},
      page:{},
      options:{},
      questions:[],
      page_number:0,
      total_pages:1,
      answers:[
        ['','','','','Speech Difficulty'],
        ['','','','','']

      ],
      completion_method:'',
      leftover_options:['','', '', '', 'Speech Difficulty'],

      assessment_start:'',
      assessment_end:'',
      title:'',
      questionnaire_type:'',
      instruct:'',
      questionnaire_data:{},
      questionnaire_address:'/Exercises/General/Questionnaires/VHI',
      qn_size:10,
      results_dict:null,

      radio_options: [],
      showResults: false,

    }
  },
  async created() {
    await this.loadQuestionnaire();

    this.assessment_start = getTodayString()


  },
  computed: {
    leftover_options() {
      return [...this.answers[0]];
    }
  },

  methods: {
    updateCompletionMethod(value)
    {

      this.completion_method = value


      

    },
    async loadQuestionnaire()
    {
      let _this = this;

      this.questionnaire_address = this.exForm.questionnaire_address

      let ref_questionnaires = this.questionnaire_address


      console.debug(ref_questionnaires)

      var do_once = true;      

      return new Promise(resolve => {


      getData(ref_questionnaires , function (err, result) {

          if (do_once) {

            do_once = false;

            let curr_results = result.val()

            _this.questionnaire_data = curr_results

            _this.title = curr_results['title']

            console.debug(curr_results)

            let temp_options = curr_results['all_pages']

            _this.all_pages = curr_results['all_pages']

            for(let k in temp_options)
            {
              _this.all_pages[k] = temp_options[k]
            }



            _this.page = _this.all_pages[_this.page_number]
            _this.questions = _this.page['questions']
            _this.total_pages  = Object.keys(_this.all_pages).length




          

            // _this.$forceUpdate()

          

            resolve()
          }


        });
      })
      
    },
    handleSubmit()
    {


        const _this = this

        console.debug('this.total_pages = ' + this.total_pages)

        // this.total_pages = 1


        for(let k in this.all_pages[this.page_number]['questions'])
        {

          let curr_question = this.all_pages[this.page_number]['questions'][k]

          if(curr_question['type']==='dip_section_e')
          {
            curr_question['questions'][0]['answers'] = this.answers[0]

            curr_question['questions'][1]['answers'] = this.answers[1]

            curr_question['questions'][2]['answers'] = [this.completion_method]

          }
        }

        if(this.page_number<this.total_pages-1)
        {

          

          
          _this.page_number = _this.page_number+1
          _this.page = _this.all_pages[_this.page_number]

          _this.questions = _this.page['questions']
          this.formKey++;

          window.scrollTo(0, 0);

        }
        else
        {
       




          console.debug('here 1')
          // console.debug('form option= '+this.form.options)

          let total_score = 0
          let total_questions = 0
          
          _this.results_dict = {

            questionnaire_address:_this.questionnaire_address


          }

          this.assessment_end = getTodayString()



          if(this.questionnaire_data.title === "Dysarthria Impact Profile")
          {

            let results_by_section = []


            for(let k=0;k<_this.total_pages;++k)
            {

              let curr_page = _this.all_pages[k]
              let questions = curr_page['questions']

              let section_name = curr_page['title']

              let section_total_score = 0
              let section_total_questions = 0


              for(let n in questions)
              {

                let curr_question = questions[n]

                if(curr_question['type'] === 'multi_question_multi_selection')
                {


                  for(let p in curr_question['questions'])
                  {

                    let sub_question = curr_question['questions'][p]

                    let temp_answer = sub_question['temp_answer']



                    let options = sub_question['options']

                    for(let m in options)
                    {
                      if(options[m]['value'] == temp_answer)
                      {
                        sub_question['answer'] = options[m]

                        section_total_score = section_total_score + Number(temp_answer)
                        section_total_questions = section_total_questions + 1

                      }  
                    }
                  }
                }
              }


              if(section_total_questions>0)
              {
                total_score = total_score + section_total_score
              total_questions = total_questions + section_total_questions

              results_by_section.push({section_name:section_name,total_score:section_total_score,total_questions:section_total_questions,max_score:section_total_questions*5})

              }
             
            }

            console.debug('results_by_section')

            console.debug(results_by_section)


              this.results_dict['Questionnaire_Name'] = this.questionnaire_data.title



              console.debug(this.all_pages)

              console.debug(total_score)
              console.debug(total_questions)

              this.results_dict[this.questionnaire_data.questionnaire_type] = {'full_questionnaire':_this.all_pages,
              'total_score':total_score,
              'total_questions':total_questions,
              'assessment_end':this.assessment_end,
              'assessment_start':this.assessment_start,
            'Score': {'Total':{'score':total_score,'total':total_questions*5,'total_questions':total_questions},'Domains':results_by_section}
          }

          }
        


          console.debug('here 5')

          this.saveResults()
      }



    },
    saveResults()
    {
      const _this = this


      console.debug('saveResults')
      if(_this.results_dict!=null)
      {
        var questionnaire_data = {
          resultsTablePre: {
            audio_address:'',
            exercise_type: 'Multipage Questionnaire', 
            exercise: this.questionnaire_data.questionnaire_type, 
            results: _this.results_dict
          }
        }


        console.debug(questionnaire_data)
        // _this.exForm.curr_exercise_path = 'VHI'
        
        // _this.exForm.module_name = 'VHI'
        save_pre_computed_results(_this.exForm, questionnaire_data)

        console.debug('saved')
        // _this.bv_points.add(10)

        _this.$emit('logExerciseResults', questionnaire_data);
      }

      _this.$emit('nextExercise');
    },

    
  },
}
</script>
<style scoped>
.form-container {
  max-width: 95%;
  margin: auto;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

:deep(.formkit-message) {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

.form-title {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-top: 0.1%;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #f9f9f9;
  transition: background 0.3s ease;
}

.radio-wrapper:hover {
  background: #e6f7ff;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;

  gap: 10px; /* Ensures spacing between the radio button and text */
  flex-grow: 1; /* Allows the label text to take available space */
}

:deep(.radio-input) {
  width: 22px;  /* Increase size */
  height: 22px; /* Increase size */
  accent-color: rebeccapurple; /* Change color to purple */
  cursor: pointer; /* Ensure it looks clickable */
}
.radio-text {
  font-size: 1.05em;
}

.points-badge {
  background: #18632a;
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: bold;
}

:deep(.textsection-input) {
  margin-top: 15px;
  width: 90%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.submit-button {
  margin-top: 20px;
  background: #007bff;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.submit-button:hover {
  background: #0056b3;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
  margin-left: 20%;
  
}
.input-group label {
  width: auto;
  font-weight: bold;
  margin-right: 1%;
}



</style>