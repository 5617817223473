<template>
  <div>
    <h1 class="display-3 mt-4"><strong>Select Personal Interests</strong></h1>
    <div class="row mt-4">
      <p>We'll make exercise contents based on your selected topics of interest. Please select as many as you like</p>
    </div>
    <div class="row mt-4" v-for="i in Math.ceil(topics_of_interest.length / 5)" :key="i">
      
      <div class="col text-center d-flex justify-content-center" v-for="item in topics_of_interest.slice((i - 1) * 5, i * 5)">
        <a @click="select_interest(item.name)" > 
          <div v-if="item.checked" class="interestButtonChecked" style="position:relative">
            <img class="interestThumbnail" :src="item.bg" style="opacity: 80%"/>
          </div>
          <div v-else class="interestButtonUnChecked" style="position:relative">
            <img class="interestThumbnail" :src="item.bg" style="opacity: 40%"/>
            <div class="bi bi-plus-lg" style="height:100%; margin-left: auto; margin-right: auto; left: 0; right: 0; top:18px; text-align: center; font-size: 60px; color:gray; font-weight: 1000; position:absolute;"/>
          </div>
          <p class="text-overlay">{{item.name}} <i v-if="item.checked" class="bi bi-check" style="font-size: 24px; color: rgb(7, 241, 77); font-weight: 1000;"/></p> 
        </a>

      </div>
    </div>

    <div class="row mt-4 justify-content-center">
      <div class="col-12 text-center">
        <button class="btn btn-primary rounded-pill px-4" @click="next()">Continue</button>
      </div>
    </div>
    <br/>

  </div>
</template>

<script>
import {ref, watch, onMounted} from 'vue'
import {getData} from '@/firebase/index'
import firebase from '@firebase/app';
import {updateUserData} from '@/firebase/index'

export default {
  name: 'Personal_Interests',
  setup(props, { emit }) {

    
    const topics_of_interest = ref([]);

    let user_ref = firebase.database().ref('Exercises').child('General').child('News').child('UK').child('Standard').child('Short');

    getData(user_ref , function (err, result) {

      let session_selected_topics = JSON.parse(sessionStorage.getItem('selected_topics'))
      
      result.forEach(function(childSnapshot) {
        
        let checked = false
        if (session_selected_topics && session_selected_topics.includes(childSnapshot.key)) {
          checked = true
        }
        
        let interest_temp = {name:childSnapshot.key, bg: '', checked: checked};
        let image_file = childSnapshot.key.replaceAll(' ','')
        let bg_img = new URL(`../../../../../assets/images/interests/${image_file}.png`, import.meta.url)
        
        interest_temp.bg = bg_img

        topics_of_interest.value.push(interest_temp);
      });
    });



    const select_interest = (topic_name) => {

      for (let interest of topics_of_interest.value) {
        if (interest.name == topic_name) {
          if (interest.checked) {
            interest.checked = false
          } else {
            interest.checked = true
          }
        }
      }

    }

    const next = () => {
      let selected_topics = []

      for (let interest of topics_of_interest.value) {
        if (interest.checked) {
          selected_topics.push(interest.name)
        }
      }

      updateUserData('Settings', {'interests' : selected_topics })

      sessionStorage.setItem('selected_topics', JSON.stringify(selected_topics))
      updateUserData('Settings', {'onboarding': {'current_stage_index': 4}})
      emit('next')
    }

    return {
     next,
     select_interest,
     topics_of_interest,
    }
  }
}
</script>

<style scoped>


.interestThumbnail {
  width: 120px;
  height: 120px;
  position: relative;
}

.interestButtonChecked {
  width: 120px;
  height: 120px;
  overflow: hidden;

  cursor:pointer;

  border: 5px solid rgb(7, 241, 77);

  background-color: #555555;
}

.interestButtonUnChecked {
  width: 120px;
  height: 120px;
  overflow: hidden;

  cursor:pointer;
  
  border: 5px solid rgb(128, 127, 127);
}
</style>
