<template>
  <div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='Pitch_Normal_2_High';openModal('pitch_modal');">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Voice_pitch" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px; height: 40px;">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Pitch</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="pitch_modal" tabindex="-1" aria-labelledby="pitch_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Pitch</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('pitch_modal')"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:50vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'Pitch_Normal_2_High'?'btn-primary':'btn-light']" type="button" @click="selected_module='Pitch_Normal_2_High'">
                  Pitch normal to high
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'Pitch_Normal_2_Low'?'btn-primary':'btn-light']" type="button" @click="selected_module='Pitch_Normal_2_Low'">
                  Pitch normal to low
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'Pitch_Normal_2_High_No_Assistance'?'btn-primary':'btn-light']" type="button" @click="selected_module='Pitch_Normal_2_High_No_Assistance'">
                  Pitch normal to high (no assistance)
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'Pitch_Normal_2_Low_No_Assistance'?'btn-primary':'btn-light']" type="button" @click="selected_module='Pitch_Normal_2_Low_No_Assistance'">
                  Pitch normal to low (no assistance)
                </button>
              </div>

              <div class="col-8">
                <div v-show="selected_module == 'Pitch_Normal_2_High'">
                  <p class="lead">Visualise and practise varying pitch from normal to high pitch</p>
                  <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                      <span class="badge rounded-pill" style="background-color: darkkhaki; font-size:medium; margin-left: 5%;">Frequency analysis</span>
                    </div>
                </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                        :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty" 
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'Pitch_Normal_2_Low'">
                  <p class="lead">Visualise and practise varying pitch from normal to low pitch</p>
                  <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                      <span class="badge rounded-pill" style="background-color: darkkhaki; font-size:medium; margin-left: 5%;">Frequency analysis</span>
                    </div>
                </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                        :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty" 
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'Pitch_Normal_2_High_No_Assistance'">
                  <p class="lead">Visualise and practise varying pitch from normal to high pitch (no assistance)</p>
                  <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                      <span class="badge rounded-pill" style="background-color: darkkhaki; font-size:medium; margin-left: 5%;">Frequency analysis</span>
                    </div>
                </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty" 
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'Pitch_Normal_2_Low_No_Assistance'">
                  <p class="lead">Visualise and practise varying pitch from normal to low pitch (no assistance)</p>
                  <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                      <span class="badge rounded-pill" style="background-color: darkkhaki; font-size:medium; margin-left: 5%;">Frequency analysis</span>
                    </div>
                </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                        :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty" 
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Voice_pitch from '@/assets/images/dashboard/slt/training/Voice_pitch.svg'

import { Modal } from 'bootstrap'

export default {
  name: 'pitch',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      selected_module: '',
      local_number_exercises: 5,
      Voice_pitch: Voice_pitch, 
      modal_references: {},
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "pitch_modal": new Modal(document.getElementById('pitch_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('pitch_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('pitch_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    }
  }
}
</script>