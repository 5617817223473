<template>
  <div class="row mt-3">
    <p class="text-primary" @click="$router.push({'name': 'home'})" style="cursor: pointer;">
      <strong>
        <i class="bi bi-chevron-left"></i> Back
      </strong>
    </p>
    <div clas="row mb-4">
      <h1 class="display-3 pb-4"><strong>Register</strong></h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-7">
            <div class="row mt-4">
              <p class="pb-2">Please select what best describes you</p>
            </div>
            <div>
              <div class="form-check mt-4">
                <input class="form-check-input" type="radio" v-model="category" value="patient" id="patient">
                <label class="form-check-label" for="patient">
                  Person with a speech and language condition
                </label>
              </div>
              <div class="form-check mt-4">
                <input class="form-check-input" type="radio" v-model="category" value="slp" id="slp">
                <label class="form-check-label" for="slp">
                  Speech and language therapist or pathologist
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div v-show="category=='patient'">
              <div class="border border-primary-subtle px-4 py-4">
                <strong>Beautiful Voice aims to help you:</strong>
                <p class="mt-2"> 
                  Speak louder, talk more clearly and use more words.
                  <br/>
                  <br/>
                  Research studies have shown that regular and frequent speech and language therapy is key for improvement.
                  <br/>
                  <br/>
                  Let's start practising!
                </p>
              </div>
            </div>
            <div v-show="category=='slp'">
              <div class="border border-primary-subtle px-4 py-4">
                <strong>Beautiful Voice aims to help you:</strong>
                <p class="mt-2">
                  <ul>
                    <li>save time on assessment, therapy creation and writing up case notes.</li>
                    <li>make therapy materials more interesting and personalised.</li>
                    <li>perform measurements and track patient results remotely.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <br/>
        <br/>
        <div v-if="category === 'patient' || category === 'slp'" class="row mt-4 justify-content-center">
          <div class="col-12 text-center">
            <button class="btn btn-primary rounded-pill px-4" @click="next()">Continue</button>
          </div>
        </div>
        <br/>
        <br/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  setup(props, { emit }) {
    const category = ref('');

    watch(category, (newCategory) => {
      emit('selected_category', newCategory);
    });

    const next = () => {
      emit('next')     
    }


    return {
      category,
      next,
    };
  },
};
</script>
