<template>
      <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div>
  <div>

    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module = 'Chat_scenario';openModal()">
                    
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
        
          <img v-if="section=='conversation_level'" :src="Conversation_img" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px; height: 40px;">
          <img v-else :src="Conversation_img" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px; height: 40px;">
        </div>
        
        <div class="col-9 d-flex align-items-center">
          <strong>Conversation Level</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" :id="section" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Conversation Level</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
          </div>
          <div class="modal-body">
            <div class="row" style="height:50vh">
              <div class="col-4 border-3 border-success border-end">
                <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'Chat_scenario' || selected_module ==  'Chat_scenario'?'btn-primary':'btn-light']" type="button" @click="selected_module='Chat_scenario'">
                  Practise spontaneous conversation with AI
                </button>
              </div>
              <div class="col-8">
                <div v-if="selected_module == 'Chat_scenario' || selected_module == 'Chat_scenario'">
                  <p class="lead">Practice spontaneous conversation in a pre-defined setting</p>
                  <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>

                      <span class="badge rounded-pill" style="background-color: darkcyan; font-size:medium; margin-left: 5%">Transcription</span>
                    </div>
                </div>
                  <div class="row mb-2">
                    <label class="col-6 form-label">Choose a setting</label>
                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration['topic']">
                        <option value="GP_receptionist">GP practice receptionist</option>
                        <option value="Travel_agent">Local travel agent</option>

                        <option value="Barista">Barista at a coffee shop</option>
                        <option value="Postman"> Your local postman</option>
                        <option value="Hairdresser"> Your favourite hairdresser</option>
                        <option value="Waiter">Waiter at a restaurant</option>
                        <option value="Taxi">A taxi driver </option>
                        <option value="Supermarket">The cashier at the supermarket</option>
                        <option value="Drive_thru">A drive-thru attendant at McDonald's</option>

                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Conversation_img from '@/assets/images/dashboard/slt/training/Conversation_level.svg'

import { Modal } from 'bootstrap'
import { short_paragraph, short_paragraph_phoneme} from '@/utils/llm/methods'
import constants from '@/utils/constants'

export default {
  name: 'paragraph_level',
  components: {},
  props: 
  {
    number_exercises: Number,
    section: String
  },
  data(){
    return {
      Conversation_img: Conversation_img, 
      modal_reference: null,

      selected_module: '',
      local_number_exercises: 1,

      module_configuration: {
        topic: 'GP_receptionist',


      },

      generating_word_list: false,

    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    this.phonemeArticulationDisplayDictionary = constants.phonemeDisplayListWithExamples

    this.tongue_twisters_phonemes_simple_representation = constants.extract_tongue_twisters_phonemes()
  },
  mounted() {

    this.modal_reference = new Modal(document.getElementById(this.section))

    this.local_number_exercises = this.number_exercises
  },
  methods: {
    check() {
      
      if (this.selected_module === "ShortParagraph") {
        if (this.module_configuration['short_paragraph_form'].text.length < 1) {
          alert("Please generate a paragraph first.")
          return false
        }
      }

      return true

    },
    emit_start_training() {
      if ( !this.check() ) return
      this.closeModal()
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      if ( !this.check() ) return
      this.closeModal()
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal() {
      if (this.modal_reference) {
        this.$emit('selected_module', this.selected_module)
        this.modal_reference.show()
      }
    },
    closeModal() {
      if (this.modal_reference) {
    
        this.modal_reference.hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    
    
  }
  
}
</script>