<template>
  <div>
    <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='DailyNewsTitleInstructions';openModal()">
    <div class="row">                    
      <div class="col-3" style="padding: 0px 0px 0px 10px;">
        <img v-if="section=='sentence_level'" :src="Voice_Speech_text" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline">
        <img v-else :src="Voice_Speech_text" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px; height: 40px;">
      </div>
      <div class="col-9 d-flex align-items-center">
        <strong>Sentence Level</strong>
      </div>
    </div>
  </div>
  <div class="modal fade" :id="section" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sentence Level</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">

          <div class="row" style="height:40vh">
            <div class="col-4 border-3 border-success border-end">
              <button :class="['btn','mb-2', 'w-100', selected_module == 'DailyNewsTitleInstructions'?'btn-primary':'btn-light']" type="button" @click="selected_module='DailyNewsTitleInstructions'">
                News headlines
              </button>
              <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'DailyNewsTitleAphasiaFriendly'?'btn-primary':'btn-light']" type="button" @click="selected_module='DailyNewsTitleAphasiaFriendly'">
                News headlines (aphasia friendly)
              </button>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'PubQuiz'?'btn-primary':'btn-light']" type="button" @click="selected_module='PubQuiz'">
                Pub quiz by topic of interest
              </button>
      
              <button :class="['btn','mb-2', 'w-100', selected_module == 'Speech_BackgroundNoise'?'btn-primary':'btn-light']" type="button" @click="selected_module='Speech_BackgroundNoise'">
                Speak in a noisy environment
              </button>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'FunFacts'?'btn-primary':'btn-light']" type="button" @click="selected_module='FunFacts'">
                Fun facts
              </button>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'Jokes'?'btn-primary':'btn-light']" type="button" @click="selected_module='Jokes'">
                Jokes
              </button>
            </div>
            <div class="col-8">
              <div v-show="selected_module == 'DailyNewsTitleInstructions'">
                <p class="lead">Practise phonation reading headlines </p>
                <div class="row mb-4 justify-content-center">
                  <div class="col-8 text-start">
                    <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                    <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 5%">Intelligibility</span>


                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Choose a topic</label>

                  <div class="col-6">
                    <select class="form-select form-select" v-model="module_configuration['topic']">
                      <option value="Art and Design">Art and Design</option>
                      <option value="Business">Business</option>
                      <option value="Cities">Cities</option>
                      <option value="Film">Film</option>
                      <option value="Food">Food</option>
                      <option value="Football">Football</option>
                      <option value="Latest News">Latest News</option>
                      <option value="Leisure">Leisure</option>
                      <option value="Lifestyle">Lifestyle</option>
                      <option value="Politics">Politics</option>
                      <option value="Science">Science</option>
                      <option value="Sport">Sport</option>
                      <option value="Tech">Tech</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'DailyNewsTitleAphasiaFriendly'">
                <p class="lead">Practise phonation reading headlines </p>
                <div class="row mb-4 justify-content-center">
                  <div class="col-8 text-start">
                    <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                    <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 5%">Intelligibility</span>


                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Choose a topic</label>

                  <div class="col-6">
                    <select class="form-select form-select" v-model="module_configuration['topic']">
                      <option value="Art and Design">Art and Design</option>
                      <option value="Business">Business</option>
                      <option value="Cities">Cities</option>
                      <option value="Film">Film</option>
                      <option value="Food">Food</option>
                      <option value="Football">Football</option>
                      <option value="Latest News">Latest News</option>
                      <option value="Leisure">Leisure</option>
                      <option value="Lifestyle">Lifestyle</option>
                      <option value="Politics">Politics</option>
                      <option value="Science">Science</option>
                      <option value="Sport">Sport</option>
                      <option value="Tech">Tech</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-if="selected_module == 'PubQuiz'">
                <p class="lead">Practise spontaneous phonation by speaking the question</p>
                <div class="row mb-4 justify-content-center">
                  <div class="col-8 text-start">
                    <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                    <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 5%">Intelligibility</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15"/>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Topic (Ancient Egypt, UK football players from the 90s)</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="topic" type="text" name="topic" v-model="module_configuration['pubquiz_topic']" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                        messages-class="text-danger"
                    validation="required"/>
                  </div>
                </div>
                <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Vocabulary age level</label>
                    <div class="col-6">

                      <select class="form-select form-select" v-model="module_configuration['age_vocabulary']">
                        <option v-for="(item, key) in vocabularyAgeLevel" :value="key" >{{key}} </option>
                      </select>
                    </div>
                  </div>
                <div class="row m-4">
                  <button v-if="!generating_word_list" class="btn btn-success" style="width:100%" :disabled="module_configuration['pubquiz_topic'].length==0 || local_number_exercises<=0" @click="generate_pub_quiz()">Generate pub quiz </button>
                  <button v-else class="btn btn-success" type="button" style="width:100%" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Generating...
                  </button>

                  <div v-if="module_configuration['pub_quiz_form'].data.length>0 && module_configuration['pub_quiz_form'].name === 'PubQuiz'">
                    <div class="row mt-2">
                      <div v-for="id in module_configuration['pub_quiz_form'].data.length" :key="id">
                        <div class="input-group mb-3">
                          <textarea class="form-control form-control-lg" v-model="module_configuration['pub_quiz_form'].data[id-1].question"></textarea>
                          <button class="input-group-text btn btn-outline-danger" type="button" @click="deleteSentence(id-1, module_configuration['pub_quiz_form'].data, module_configuration['pub_quiz_form'])">Delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'Speech_BackgroundNoise'">
                <p class="lead">Practise focusing attention on phonation </p>
                <div class="row mb-4 justify-content-center">
                  <div class="col-8 text-start">
                    <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                    <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 5%">Intelligibility</span>
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-if="selected_module == 'FunFacts'">
                <p class="lead">Practise phonation reading fun facts</p>
                <div class="row mb-4 justify-content-center">
                  <div class="col-8 text-start">
                    <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                    <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 5%">Intelligibility</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15"/>
                  </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Topic (Dogs, Eastenders)</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="topic" type="text" name="topic" v-model="module_configuration['funtopic']" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                        messages-class="text-danger"
                        validation="required"/>
                  </div>
                </div>
                <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Vocabulary age level</label>
                    <div class="col-6">

                      <select class="form-select form-select" v-model="module_configuration['age_vocabulary']">
                        <option v-for="(item, key) in vocabularyAgeLevel" :value="key" >{{key}} </option>
                      </select>
                    </div>
                  </div>
                <div class="row m-4">
                  <button v-if="!generating_word_list" class="btn btn-success" style="width:100%" :disabled="module_configuration['funtopic'].length==0" @click="generate_fun_facts()">Generate Fun Facts </button>
                  <button v-else class="btn btn-success" type="button" style="width:100%" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Generating...
                  </button>

                  <div v-if="module_configuration['sentences_form'].text.length>0 && module_configuration['sentences_form'].name === 'FunFacts'">
                    <div class="row mt-2">
                      <div v-for="id in module_configuration['sentences_form'].text.length" :key="id">
                        <div class="input-group mb-3">
                          <textarea class="form-control" v-model="module_configuration['sentences_form'].text[id-1]"></textarea>
                          <button class="input-group-text btn btn-outline-danger" type="button" @click="deleteSentence(id-1, module_configuration['sentences_form'].text, module_configuration['sentences_form'])">Delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
              <div v-if="selected_module == 'Jokes'">
                <p class="lead">Practise phonation reading jokes </p>
                <div class="row mb-4 justify-content-center">
                  <div class="col-8 text-start">
                    <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>
                    <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 5%">Intelligibility</span>
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
          <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

import Voice_Speech_text from '@/assets/images/dashboard/slt/training/Voice_sentences.svg'
import {getTodayString} from '@/utils/utils'

import { Modal } from 'bootstrap'
import {pub_quiz, fun_facts} from '@/utils/llm/methods'
import constants from '@/utils/constants'

export default {
  name: 'sentence_level',
  components: {},
  props: 
  {
    number_exercises: Number,
    section: String
  },
  data(){
    return {
      today: getTodayString(),
      Voice_Speech_text: Voice_Speech_text, 
      modal_reference: null,

      selected_module: '',
      local_number_exercises: 5,

      vocabularyAgeLevel:{},

      module_configuration: {
        topic: 'Latest News',
        age_vocabulary:'Adult',


        funtopic: '',
        sentences_form: 
        {
          name: 'temp',
          keywords:'',
          instruct: 'Press Start. Read the following sentence with a loud and clear voice. Press Stop.',
          text: [],
          n_item:5,
        },


        pub_quiz_form: 
        {
          name: 'temp',
          keywords:'',
          instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',
          data:[],
          n_item:5,
        },

        pubquiz_topic:'',
      },
      
      generating_word_list: false,
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    this.vocabularyAgeLevel = constants.vocabularyAgeLevel

  },
  mounted() {

    this.modal_reference = new Modal(document.getElementById(this.section))

    this.local_number_exercises = this.number_exercises
  },
  methods: {
    check() {
      
      if (this.selected_module === "FunFacts") {
        if (this.module_configuration['sentences_form'].text.length < 1) {
          alert("Please generate some fun facts first.")
          return false
        }
      }
      
      if (this.selected_module === "PubQuiz") {
        if (this.module_configuration['pub_quiz_form'].data.length < 1) {
          alert("Please generate a quiz first.")
          return false
        }
      }
      return true
    },
    emit_start_training() {
      if ( !this.check() ) return
      this.closeModal()
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      if ( !this.check() ) return
      this.closeModal()
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal() {
      this.reset()
      if (this.modal_reference) {
        this.$emit('selected_module', this.selected_module)
        this.modal_reference.show()
      }
    },
    closeModal() {
      if (this.modal_reference) {
    
        this.modal_reference.hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset(){
      this.module_configuration = {
        topic: 'Latest News',
        age_vocabulary:'Adult',

        pub_quiz_form: 
        {
          name: 'temp',
          keywords:'',
          instruct: 'Press Start. Read the following sentence loudly and clearly. Press Stop.',
          data:[],
          n_item:5,
        },

        pubquiz_topic:'',


        funtopic: '',
        sentences_form: 
        {
          name: 'temp',
          keywords:'',
          instruct: 'Press Start. Read the following sentence with a loud and clear voice. Press Stop.',
          text: [],
          n_item:5,
        },

      }

    },
    deleteSentence(id, sentences_array, sentences_form) {
      sentences_array.splice(id,1)
      this.local_number_exercises = sentences_array.length    // this.sentences_form.text.splice(id, 1)
      this.onNumberExercisesChange()
      // this.number_exercises = this.number_exercises - 1
    },
    generate_fun_facts()
    {
      const _this = this
      
      _this.generating_word_list = true
      
      let list_facts = fun_facts(_this.module_configuration['funtopic'], _this.number_exercises,_this.module_configuration['age_vocabulary'])
       
      _this.module_configuration['sentences_form'].topic = _this.module_configuration['funtopic']
      _this.module_configuration['sentences_form'].name = "FunFacts"

      list_facts.then(function(result) {
        console.debug('List_facts = ' + result)
        _this.module_configuration['sentences_form'].text = result
        _this.generating_word_list = false
      })
    },
    generate_pub_quiz()
    {
      const _this = this
      
      _this.generating_word_list = true
      
      let pub_quiz_results = pub_quiz( _this.module_configuration['pubquiz_topic'], _this.local_number_exercises,_this.module_configuration['age_vocabulary'])
       
      _this.module_configuration['pub_quiz_form'].topic = _this.module_configuration['pubquiz_topic']
      _this.module_configuration['pub_quiz_form'].name = "PubQuiz"
      pub_quiz_results.then(function(result) {
        for(let k in result) {
          console.debug(result[k])
        }
        _this.module_configuration['pub_quiz_form'].data = result

        _this.generating_word_list = false
      })
    },
    deleteWord(id) {
      this.local_number_exercises = this.number_exercises - 1
    },
  }
}
</script>