<template>
  <div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='TongueStrength';openModal('strength_modal');">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Dysphagia_strength" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="background-color: lightsalmon; width: 40px; height: 40px;">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Strength</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="strength_modal" tabindex="-1" aria-labelledby="strength_modal" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Strength</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('strength_modal')"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:20vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'TongueStrength'?'btn-primary':'btn-light']" type="button" @click="selected_module='TongueStrength'">
                  Tongue Strength
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'EffortfulSwallow'?'btn-primary':'btn-light']" type="button" @click="selected_module='EffortfulSwallow'">
                  Effortful Swallow
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'MendelsohnManoeuvre'?'btn-primary':'btn-light']" type="button" @click="selected_module='MendelsohnManoeuvre'">
                  Mendelsohn Manoeuvre
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ShakerHeadLiftShort'?'btn-primary':'btn-light']" type="button" @click="selected_module='ShakerHeadLiftShort'">
                  Shaker Head Lift (Short)
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ShakerHeadLiftLong'?'btn-primary':'btn-light']" type="button" @click="selected_module='ShakerHeadLiftLong'">
                  Shaker Head Lift (Long)
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'SupraglotticManoeuvre'?'btn-primary':'btn-light']" type="button" @click="selected_module='SupraglotticManoeuvre'">
                  Supraglottic Manoeuvre
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'EMST'?'btn-primary':'btn-light']" type="button" @click="selected_module='EMST'">
                  EMST
                </button>
              </div>
              <div class="col-8">
                <div v-show="selected_module == 'TongueStrength'">
                  <p class="lead">This exercise aims to strengthen your tongue movements, which will help you to move food and not get tired quickly when eating or drinking.</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of repetitions</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="6" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:6" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'EMST'">
                  <p class="lead">This exercise uses a device to exercise and increase the maximal pressure of the expiratory muscles. These muscles are important for breathing out forcefully, coughing, and swallowing. </p>

                  <p >This content was developed by <a href="https://www.cuh.nhs.uk/patient-information/expiratory-muscle-strength-training-emst/" target="_blank" rel="noopener noreferrer" >Cambridge University Hospitals </a>. </p>

                  <div class="row">
                    <!-- <label class="col-6 form-label" for="nexercises">Number of repetitions</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="6" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:6" />
                    </div> -->
                  </div>
                </div>

                <div v-show="selected_module == 'EffortfulSwallow'">
                  <p class="lead">This exercise aims to strengthen the throat muscles involved in swallowing.</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of repetitions</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:5" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'TongueRangeMovements'">
                  <p class="lead">This exercise aims to increase your range of tongue movements which will benefit how you move food around in your mouth.</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of repetitions</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:5" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'SupraglotticManoeuvre'">
                  <p class="lead">This exercise aims to strengthen the throat muscles involved in swallowing.</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of repetitions</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:5" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'MendelsohnManoeuvre'">
                  <p class="lead">This exercise aims to strengthen the throat muscles involved in swallowing.</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of repetitions</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:5" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'ShakerHeadLiftShort'">
                  <p class="lead">This exercise aims to strengthen the muscles in the neck and throat that will help improve swallowing movements.</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of repetitions</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:5" />
                    </div>
                  </div>
                </div>
                <div v-show="selected_module == 'ShakerHeadLiftLong'">
                  <p class="lead">This exercise aims to strengthen the muscles in the neck and throat that will help improve swallowing movements.</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of repetitions</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Dysphagia_strength from '@/assets/images/dashboard/slt/training/Dysphagia_strength.svg'

import { Modal } from 'bootstrap'

export default {
  name: 'memory',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      selected_module: '',
      local_number_exercises: 5,
      Dysphagia_strength: Dysphagia_strength, 
      modal_references: {},
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "strength_modal": new Modal(document.getElementById('strength_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('strength_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('strength_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    }
  }
}
</script>