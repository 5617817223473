<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div v-if="page_number === 0">
          <header class="header">
            <!-- <button class="back-button" @click="goBack">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" aria-hidden="true">
                <path d="M16 4L8 12l8 8" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
              </svg>
            </button> -->
            <h1 class="title">{{ title }}</h1>

          </header>
          <div class="row">
            <div class="col-6">
              <div class="card card-body">
                <video v-if="instructions_video_url" controls autoplay :key="curr_full_instructions_video_number" >
                  <source :src="full_instructions_video_urls[curr_full_instructions_video_number]" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p v-else>Loading video...</p>
                <div style="text-align: center;">
                  <button class="btn btn-secondary btn-lg mt-4" style="width: 60%;" v-if="full_instructions_video_urls.length>1" @click="curr_full_instructions_video_number=(curr_full_instructions_video_number+1<full_instructions_video_urls.length)?curr_full_instructions_video_number+1:0;instructions_video_url = full_instructions_video_urls[curr_full_instructions_video_number]"> Switch Front/Side View</button>

                </div>

              </div>
            </div>
            <div class="col-6">
              <div class="card card-body">

              <div v-if="preparation!=undefined && preparation!=null && preparation.length>0" v-bind:key="preparation">
                <h2 class="instructions-title">Preparation</h2>
                <ol class="instructions-list">
                  <li style="text-align: left;" v-for="row in preparation" :key="row">{{ row }}</li>
                </ol>

              </div>
                <h2 class="instructions-title">Instructions</h2>
                <ol class="instructions-list">
                  <li style="text-align: left;" v-for="row in instructions" :key="row">{{ row }}</li>
                </ol>

              </div>
            </div>
          </div>

          <div
          class="modal fade"
          id="emstLevelModal"
          tabindex="-1"
          aria-labelledby="emstLevelModalLabel"
          aria-hidden="true"
        >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="emstLevelModalLabel">EMST Device Setting</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p>Please select the EMST device level before proceeding:</p>
                <select
                  class="form-select"
                  v-model="EMST_Level"
                  placeholder="Select"
                  style="width: 100%;"
                >
                  <option v-for="value in EMSTOptions" :key="value" :value="value">
                    {{ value }}
                  </option>
                </select>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  @click="navigateToPage(1)"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div v-if="page_number === 1">
          <div class="row">
            <div class="col-xl-6">
              <div class="exercise-instructions">
                <h1>Step {{ step_number + 1 }}</h1>
                <p style="text-align: left;">{{ step_by_step_instructions[step_number] }}</p>
              </div>
              <div class="image-container">
                <img v-if="all_image_step_url!=undefined && all_image_step_url!=null" :src="all_image_step_url[step_number]" alt="Instructional Image" />


                <video v-if="all_video_step_url!=undefined && all_video_step_url!=null "  autoplay controls :key="all_video_step_url[step_number]">
                  <source :src="all_video_step_url[step_number]" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="navigation-buttons">
                <div>
                <button v-if="step_number>0 " class="back-button" @click="moveStep(-1)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" aria-hidden="true">
                    <path d="M16 4L8 12l8 8" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
                  </svg>
                </button>
              </div>
              <div>
                <button class="back-button" @click="moveStep(1)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" aria-hidden="true">
                    <path d="M9 5l7 7-7 7" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
                  </svg>
                </button>
              </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="purple-corners">
                <video ref="liveVideoElement" class="m-2 w-100 h-auto" muted style="box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
        <div v-if="page_number === 2">
          <div class="row mt-2 justify-content-around">

                <h4>Let's log your exercise progress</h4>
                <h5>for tongue range of motion exercise</h5>

                <div class="mt-4">
                  <label class="font-semibold">Record for</label>
                  <input type="date" v-model="date" class="border p-2 rounded w-full" />
                </div>


              <div class="row mt-4 justify-content-around">

                <div class="col-md-4" style="margin-bottom: 2%;">

                  <div class="card p-4">

                    <p >How many times did you perform the exercises?</p>
                    <div >

                      <button class="btn btn-success h-100" @click="prev_exercise_repeat_count=Math.max(0,prev_exercise_repeat_count-1)" >-</button>

                      <span class="font-bold mx-2">{{ prev_exercise_repeat_count }}</span>
                      <button class="btn btn-success h-100" @click="prev_exercise_repeat_count=prev_exercise_repeat_count+1" >+</button>
                    </div>

                  </div>

                </div>

                <div class="col-md-4" style="margin-bottom: 2%;">

                  <div class="card p-4">


                    <label class="form-label mt-2">EMST Device Setting</label>
                      <select class="form-select mb-3" v-model="EMST_Level" placeholder="Select" style="width:100%" @change="updateDropdown()">
                        <option 
                          v-for="value in EMSTOptions" 
                          :key="value" 
                          :value="value"
                        >

                        {{ value }}
                        </option>
                      </select>             

                  </div>
                  </div>
                </div>

               

              <div class="row mt-2 justify-content-around">
                <button class="btn btn-primary btn-lg h-100 w-50" @click="save" data-bs-dismiss="modal" >Save</button>

              </div>
          </div>
        </div>

        
        <div class="modal fade" id="set_finish_pop" tabindex="-1" aria-labelledby="setFinishPopLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="repeatFinishPopLabel">You are on a roll!</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="m-3">
                  <div class="row">
                    <h4>You finished a set! You can continue working on your swallowing or take a break</h4>
                  </div>
                  <div class="row mt-4 text-center">

                    <div class="col">

                       <button class="btn btn-success h-100" @click="nextRepeat" data-bs-dismiss="modal" >Continue</button>
                    </div>
                     <div class="col">
                      <button class="btn btn-primary h-100"  @click="endExercise" data-bs-dismiss="modal" >Take a break</button>
                    </div>
               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="repeat_finish_pop" tabindex="-1" aria-labelledby="repeatFinishPopLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="repeatFinishPopLabel">Good job</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="m-3">
                  <div class="row">
                    <h4>You finished {{resultsTablePre['results']['repetitions']}} repetition, take a 20 seconds break and let's do it again!</h4>
                  </div>
                  <div class="row mt-4 text-center">

                    <div class="col">

                       <button class="btn btn-success h-100" @click="nextRepeat" data-bs-dismiss="modal" >Next repetition</button>
                    </div>
                    <!-- <div class="col">
                      <button class="btn btn-primary h-100"  @click="endExercise" data-bs-dismiss="modal" >End exercise</button>
                    </div> -->
               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </template>
      <template v-slot:sidebar>
        <Sidebar
          class="h-100"
          :audioCaptureOn="page_number == 1"
          @next="navigateToPage(1)"
          :mic_on="is_recording"
          :has_next="page_number!==2 && !('name' in exForm && exForm.name === 'EMST')"
          @started="start"
          @stopped="stop"

          
        >

        <template v-if="page_number===2 " v-slot:top>
          <button class="btn btn-primary btn-lg w-100" @click="endExercise">Save</button>

        </template>
        <template v-if="page_number===0 && 'name' in exForm && exForm.name === 'EMST'" v-slot:top>
          <label class="form-label mt-2">EMST Device Setting</label>
            <select class="form-select mb-3" v-model="EMST_Level" placeholder="Select" style="width:100%" @change="updateDropdown()">
              <option 
                v-for="value in EMSTOptions" 
                :key="value" 
                :value="value"
              >

              {{ value }}
              </option>
            </select>             
            
          </template>


          <template v-if="page_number===0 && 'name' in exForm && exForm.name === 'EMST'" v-slot:middle>
              <button class="btn btn-primary btn-lg w-100" @click="page_number=2">Report what I have done</button>
                  
            
          </template>
      </Sidebar>


      </template>
     
    </Layout>
  </div>
</template>

<script>
import { ref, onMounted, defineComponent, onUnmounted } from 'vue';
import { Modal } from 'bootstrap';

import firebase from 'firebase/app'
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue';
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue';
import { BV_VideoRecorder } from '@/media_input/video_recording';
import {generate_results_path, save_pre_computed_results} from '@/utils/results/data_save_utils'


export default defineComponent({
  name: 'dysphagia',
  props: {
    exForm: {
      type: Object,
      required: true,
    },
  },
  components: { Layout, Sidebar },
  setup(props, {emit}) {
    const title = ref(props.exForm.name);
    const instructions = ref(props.exForm.instruct);
    const preparation = ref(props.exForm.Preparation);

    console.debug('prep')
    console.debug(preparation.value)


    const step_by_step_instructions = ref(props.exForm.instruct)

    if('step_by_step_instructions' in props.exForm)
    {
      step_by_step_instructions.value = props.exForm.step_by_step_instructions
    }
    const instructions_video_url = ref(null);

    const prev_exercise_repeat_count = ref(0)

    const full_instructions_video_urls = ref([])

    const exForm = ref(props.exForm)

    const curr_full_instructions_video_number = ref(0)

    for(let k in exForm.value.Video_Address)
    {
      full_instructions_video_urls.value.push(null)
 
    }

    const EMST_Level = ref(null)

    const date = ref(null)

    EMST_Level.value = ''

    date.value = new Date().toISOString().substr(0, 10)

    
    const Image_Step_Address = ref(props.exForm.Image_Step_Address)

    const Video_Step_Address = ref(props.exForm.Video_Step_Address)
    const all_video_step_url = ref(null);
    
    const all_image_step_url = ref(null);

    const EMSTOptions = ref([]);

    const generateEMSTDropdownOptions = (start, end, step) => {
      const options = [];
      for (let value = start; value <= end; value += step) {
        options.push(value);
      }
      return options;
    };

    EMSTOptions.value = generateEMSTDropdownOptions(30, 150, 7.5);



    const page_number = ref(0);
    const step_number = ref(0);
    const total_repeats = ref(props.exForm.n_repeats);
    const curr_repeat = ref(0);
    const resultsTablePre = ref({'exercise_type':'Dysphagia','exercise':props.exForm.name,results:{'repetitions':0},'video_addresses':[]});

    

    
    if(props.exForm.name==='EMST')
    {
      resultsTablePre.value['exercise_type'] = 'EMST'
      resultsTablePre.value['results']['sets'] = 0
      total_repeats.value = 25
    }

    if(props.exForm.current_exercise_index>0)
    {
      page_number.value = 1
    }


    const navigateToPage = (targetPage) => {
      if (targetPage === 1 && exForm.value.name === "EMST" && !EMST_Level.value) {
        // Show the modal if EMST_Level is not selected


        let emstModal_pop = new Modal(document.getElementById('emstLevelModal'));
        emstModal_pop.show()
      } else {
        page_number.value = targetPage;
      }
    };

    console.debug('total_repeats = ' + total_repeats.value)
    if(total_repeats.value==undefined || total_repeats.value==null || total_repeats.value<=0)
    {
      total_repeats.value = 1
      
    }

    const is_recording = ref(false);
    const liveVideoElement = ref(null);
    const BV_VideoRecorderInstance = ref(null);

    const goBack = () => {

    };

    const playVideoInstructions = () => {
      const storage = firebase.storage();
      let videos = props.exForm.Video_Address

      for(let k in videos)
      {
        let curr_video = videos[k]
        let video_index = k
        storage.ref(curr_video).getDownloadURL()
        .then((url) => {
          if(video_index==0)
          {
            instructions_video_url.value = url;
          }
          full_instructions_video_urls.value[video_index] = url

          console.debug(full_instructions_video_urls.value)

          console.log(url)
        })
        .catch((error) => {
          console.error('Error loading video:', error);
        });

        
      }
      

        if(Video_Step_Address.value!=undefined && Video_Step_Address!=null)
        {
          all_video_step_url.value = {}

          if(Video_Step_Address.value.length>3)
          {
            page_number.value = 1
            
          }
          for(let n in Video_Step_Address.value)
          {

            let k = n

            storage.ref(Video_Step_Address.value[k]).getDownloadURL()
            .then((url) => {
              all_video_step_url.value[k] = url;
              console.log(url)
            })
            .catch((error) => {
              console.error('Error loading video:', error);
            });
            
          }
        }

        if(Image_Step_Address.value!=undefined && Image_Step_Address!=null)
        {
          all_image_step_url.value = {}
          for(let n in Image_Step_Address.value)
          {

            let k = n

            storage.ref(Image_Step_Address.value[k]).getDownloadURL()
            .then((url) => {
              all_image_step_url.value[k] = url;
              console.log(url)
            })
            .catch((error) => {
              console.error('Error loading image:', error);
            });
            
          }
        }
    };

    const start = async () => {

      BV_VideoRecorderInstance.value = new BV_VideoRecorder(liveVideoElement.value);
      if (liveVideoElement.value) {
        BV_VideoRecorderInstance.value.checkPermissionsMedia();
      } else {
        console.error('ERROR: null video element');
      }

      // const storage_path = 'video_recordings/' + localStorage.getItem('client_uid') + '/dysphagia/' + 'abc';


      let results_addresses = generate_results_path(props.exForm)

      console.debug('results_addresses')
      let random_identifier = results_addresses['random_identifier']

      console.debug('random_identifier')

      console.debug(results_addresses)

      let exercises_results_path = results_addresses['exercises_results_path']

      resultsTablePre.value.video_addresses.push(exercises_results_path)

      console.debug('resultsTablePre')

      let video_folder = localStorage.getItem('uid') + '/' + props.exForm.curr_exercise_path + random_identifier;
        

      console.debug('video_folder')

      if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
        video_folder = localStorage.getItem('client_uid') + '/' + props.exForm.curr_exercise_path + random_identifier;
      }


      var video_address = 'video_recordings/' + video_folder + '.mp4';

      console.debug('video_address')

      video_address = video_address.replaceAll('//','/')

      const storage_path = video_address
      console.debug("Saving " + video_address);





      try {
        console.debug('try')
        await BV_VideoRecorderInstance.value.checkPermissionsMedia();

        console.debug('await')
        BV_VideoRecorderInstance.value.record(storage_path);
        is_recording.value = true;
      } catch (error) {
        console.error('Error start recording:', error);
      }

      
    };

    const moveStep = (increment) => {
      step_number.value = Math.max(0, step_number.value + increment);



      if(step_number.value>=step_by_step_instructions.value.length)
      {
        step_number.value = 0

        endRepetition()
        
      }

    };

    const endRepetition = () =>
    {
      curr_repeat.value = curr_repeat.value+1

      resultsTablePre.value['results']['repetitions'] = curr_repeat.value
      if(curr_repeat.value>=total_repeats.value)
      {
        endExercise()
        
      }
      else
      {


        if(props.exForm.name==='EMST' && curr_repeat.value%5==0)
        {

          resultsTablePre.value['results']['sets'] = resultsTablePre.value['results']['sets'] + 1

          let set_repetition_pop = new Modal(document.getElementById('set_finish_pop'));
          set_repetition_pop.show()
          
        }
        else
        {
          let end_repetition_pop = new Modal(document.getElementById('repeat_finish_pop'));
          end_repetition_pop.show()

        }

    
      }
    }

    const nextRepeat = () =>
    {

      step_number.value = 0
    }

    const save = () =>
    {
      if (exForm.value.name === "EMST" && !EMST_Level.value) {
        // Show the modal if EMST_Level is not selected


        let emstModal_pop = new Modal(document.getElementById('emstLevelModal'));
        emstModal_pop.show()
      }else
      {
        if(prev_exercise_repeat_count.value>0)
        {
          resultsTablePre.value['results']['sets'] = Math.floor(prev_exercise_repeat_count.value/5)
          
          resultsTablePre.value['results']['repetitions'] = prev_exercise_repeat_count.value/5

    

        }

        endExercise()

      }


    }

    const endExercise = () =>
    {

      console.debug('end exercise')

    

      if(exForm.value.name === "EMST")
      {
        resultsTablePre.value['results']['EMST_Level'] = EMST_Level.value 

      }

  
      var basicData = {resultsTablePre: resultsTablePre.value}

      save_pre_computed_results(props.exForm, basicData)

      emit('logExerciseResults', basicData);
        
      emit('nextExercise');

    }



    const stop = () => {
      BV_VideoRecorderInstance.value.stop()
      console.log('stop and saved')
      is_recording.value = false
      moveStep(step_by_step_instructions.value.length)


    };

    onMounted(() => {
      playVideoInstructions();    
    });

    onUnmounted(() => {
      BV_VideoRecorderInstance.value.destroy()
    })

    return {
      title,
      instructions,
      preparation,
      step_by_step_instructions,
      instructions_video_url,
      liveVideoElement,
      prev_exercise_repeat_count,
      step_number,
      page_number,
      is_recording,

      full_instructions_video_urls,
      curr_full_instructions_video_number,

      date,
      total_repeats,
      curr_repeat,
      resultsTablePre,

      all_video_step_url,
      all_image_step_url,

      EMSTOptions,

      EMST_Level,


      goBack,
      start,
      stop,
      save,
      moveStep,
      navigateToPage,
      endExercise,
      endRepetition,
      nextRepeat,

    };
  },
});
</script>


  
<style scoped>

.header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}
.back-button {
  width: 80px;
  height: 80px;
  background-color: #28a745; /* Green background color */
  border: none; /* No border */
  border-radius: 50%; /* Circular button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.back-button svg {
  width: 26px; /* Arrow size */
  height: 26px;
}

.back-button:hover {
  background-color: #218838; /* Darker green on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow on hover */
}


/* Title */
.title {
  font-size: 1.5em;
  font-weight: bold;
}

/* Content */
.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Side-by-Side Layout for Video and Instructions */
.side-by-side-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.video-container {
  flex: 1;
  max-width: 50%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.instruction-video {
  width: 100%;
  height: auto;
}

.instructions {
  flex: 1;
  max-width: 50%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.instructions-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 16px;
}

.instructions-list {
  list-style-type: decimal;
  padding-left: 20px;
  line-height: 1.6;
  font-size: 1.1em;
}


/* Responsive Design */
@media (max-width: 768px) {
  .side-by-side-container {
    flex-direction: column;
  }

  .video-container,
  .instructions {
    max-width: 100%;
  }
}

.exercise-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns children to the top */
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  height: 100vh;
  box-sizing: border-box;
}

/* Left Section */
.left-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: 20px;
}

.exercise-instructions {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}


.exercise-instructions h1 {
  font-size: 1.8em;
  font-weight: bold;
}

.exercise-instructions p {
  font-size: 1em;
  margin-top: 8px;
}

.image-container {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.image-container video {
  width: 80%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
}

.nav-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1em;
  font-weight: bold;
  color: #333;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.green-circle {
  background-color: #28a745; /* Green */
}

.gray-circle {
  background-color: #e2e8f0; /* Light Gray */
}

.arrow {
  width: 20px;
  height: 20px;
}

.nav-button.previous span {
  margin-left: 4px;
}

.nav-button.next span {
  margin-right: 4px;
}

/* Right Section */
.right-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
  height: 80%;
  position: relative;
  box-sizing: border-box;
  margin-top: 0;
}

.purple-corners {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purple-corners::before,
.purple-corners::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  border: 3px solid #6b46c1;
}

.purple-corners::before {
  top: 0;
  left: 0;
  border-bottom: none;
  border-right: none;
}

.purple-corners::after {
  bottom: 0;
  right: 0;
  border-top: none;
  border-left: none;
}

.look-here-text {
  font-size: 1.2em;
  color: #333;
  text-align: center;
  margin: 0;
}

</style>

