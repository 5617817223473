<template>
  <div>
    <h1 class="display-3 mt-4"><strong>Profile</strong></h1>

    <div class="row mt-4 justify-content-around">
      <div class="col-md-5">
        <label for="firstname">Firstname  <span style="color:red">*</span></label>
        <FormKit outer-class="$reset only-me-please" input-class="form-control" id="firstname" 
          name="firstname" v-model="firstname" 
          :sections-schema="{
            messages: { $el: 'div' },
            message: { $el: 'span' },
          }"
          messages-class="text-danger"
          validation="required"/>
      </div>
      <div class="col-md-5">
        <label for="surname">Surname  <span style="color:red">*</span></label>
        <FormKit outer-class="$reset only-me-please" input-class="form-control" id="surname" 
          name="surname" v-model="surname" 
          :sections-schema="{
            messages: { $el: 'div' },
            message: { $el: 'span' },
          }"
          messages-class="text-danger"
          validation="required"/>
      </div>
    </div>

    <div class="row mt-4 justify-content-around">
      <div class="col-md-5">
        <label for="email">Email <span style="color:red">*</span></label>
        <FormKit input-class="form-control" id="email" type="email" name="email" 
          v-model="email" 
          :sections-schema="{
          messages: { $el: 'div' },
          message: { $el: 'span' },
        }"
        messages-class="text-danger"
        validation="required|email"
        />
      </div>
      <div class="col-md-5">
        <label for="password">Password <span style="color:red">*</span></label>
        <FormKit input-class="form-control" id="password" type="password" name="password" 
            v-model="password" 
            :sections-schema="{
            messages: { $el: 'div' },
            message: { $el: 'span' },
          }"
          messages-class="text-danger"
          validation="required|length:6,20" />
      </div>
    </div>

    <div class="row mt-4 justify-content-around">
      <div class="col-md-5">
        <label for="email">Region</label>
        <select class="form-select" v-model="region">
          <option selected value="UK">United Kingdom</option>
          <option value="US">United States</option>
        </select>
      </div>
      <div class="col-md-5">
        <label for="language">Language</label>
        <select class="form-select" v-model="language">
          <option selected value="UK">British English</option>
          <option value="US">American English</option>
        </select>
      </div>
    </div>

    <div class="row mt-4 justify-content-around">
      <div class="col-md-5">
        <label for="reference">Reference code (if you have one)</label>
        <FormKit input-class="form-control" id="reference" type="text" name="reference" 
            v-model="reference" 
            placeholder=""
            :sections-schema="{
            messages: { $el: 'div' },
            message: { $el: 'span' },
          }"
          messages-class="text-danger" />
      </div>
      <div class="col-md-5">
      </div>
    </div>

    <div class="row mt-4 justify-content-around">
      <div class="col-8 mt-4">
        <div class="d-flex justify-content-center">
          <input class="form-check-input me-2" type="checkbox" v-model="accept" id="accept" name="consent" validation="required" :disabled="registering"/>
          <span class="me-2">I accept the</span> <a type="button" class="btn-link" @click="downloadTermsOfUse()"> Terms and Conditions</a>
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-4 justify-content-around">
      <div class="col-8 text-center">
        <button class="btn btn-primary rounded-pill px-4" 
          :disabled="!firstname || !surname || !email || !password || !accept || registering"
          @click="next()">
          <span v-if="registering">
            <div class="spinner-border fs-4 spinner-border-sm" role="status"></div>
            Loading
          </span>
          <span v-else>
            Continue
          </span>                
        </button>
      </div>
    </div>
    <br/>

  </div>
</template>

<script>

import us_icon from '@/assets/images/region/us.svg'
import uk_icon from '@/assets/images/region/uk.svg'
import { ref } from 'vue'
import { useToast } from "vue-toastification";
import firebase from '@firebase/app';
import { emailLogin } from '@/firebase/index'
import axios from 'axios'
import {updateUserData} from '@/firebase/index'

export default {
  name: 'Profile',
  
  setup(props, { emit }) {

    const api = import.meta.env.VITE_FAST_API
    
    const firstname = ref('')
    const surname = ref('')
    const email = ref('')
    const password = ref('')
    const region = ref('UK')
    const language = ref('UK')
    const reference = ref('')
    const accept = ref(false)

    const registering = ref(false)

    const register = async () => {

      registering.value = true

      let registration_details = {
        firstname: firstname.value,
        surname: surname.value,
        email: email.value,
        password: password.value,
        region: region.value,
        language: language.value,
        reference: reference.value,
        consent: accept.value
      }

      try {
        await axios.post(api + "/register_patient", registration_details).then(response => {
          if (response.status === 200) {
            if (response.data.status === "success") {

              emailLogin(email.value, password.value, function (err) {

                if (err == null) {
                  localStorage.setItem('uid', response.data.uid);
                  localStorage.setItem('userCategory', 'patient')
                      
                } else {
                  const toast = useToast()
                  toast.error(err.message)

                  registering.value = false
                  updateUserData('Settings', {'onboarding': {'current_stage_index': 1}})

                }

              })

              emit('next')
            } else if (response.data.status === "error") {
              const toast = useToast()
              toast.error(response.data.info)

              registering.value = false

            } else {
              const toast = useToast()
              toast.error("Error please contact contact@beautifulvoice.co.uk")

              registering.value = false
            }
          } else {
            const toast = useToast()
            toast.error("Error please contact contact@beautifulvoice.co.uk")

            registering.value = false
          }
        })
      } catch( error_value) {
        console.error(error_value)

        registering.value = false
      }
    }

    const next = () => {
      register()
    }


    const downloadTermsOfUse = () => {
      var storage = firebase.storage();

      let file = 'BV/terms/BV_TERMS.pdf'


      storage.ref(file).getDownloadURL()
        .then((url) => {
          var link = document.createElement("a");
          if (link.download !== undefined) {
              link.setAttribute("href", url);
              link.setAttribute("target", "_blank");
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }

        })
        .catch((error) => {

          console.debug('error = ' + error)
          // Handle any errors
      });

    }

    return {
      uk_icon,
      us_icon,
      next,
      downloadTermsOfUse,

      firstname,
      surname,
      email,
      password,
      region,
      language,
      reference,
      accept,

      registering
    }
  }
}
</script>

<style scoped>

</style>
