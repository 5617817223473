<template>
  <div>
    <Layout>
      <template v-slot:content>
        <div>
          <div v-if="interaction_message!=''" class="row" style="height: 15mm;">
            <p class="h5" :style="interaction_message_color"> {{  interaction_message }}</p> 
          </div>
          <div class="row mt-3 mb-4 justify-content-center">
            <div class="col-4">
              <h3>Select all of the: </h3>
              <div class="card card-body" style="display: flex;justify-content: center;align-items: center;" >
                <p v-if="level_properties[current_level]['target_shape_label']">{{target_img['shape']}}</p>
                <img id= "img_target" v-bind:src="target_img['url']" :style="{'height': '100px', 'width': '100px', 'filter': 'hue-rotate(' + level_properties[current_level]['target_shape_colour'] + 'deg)' }" >
              </div>  
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">

            <div v-for="i_index in Math.ceil(all_img.length / level_properties[current_level]['n_x'])" v-bind:key="i_index">
              <div class="d-flex flex-row flex-nowrap m-2 justify-content-center">
                <div v-for="(img_dict, index) in all_img.slice((i_index - 1) * level_properties[current_level]['n_x'], i_index * level_properties[current_level]['n_x'])" 
                    v-bind:key="(i_index - 1) * level_properties[current_level]['n_x'] + index">
                  <div class="m-2 border border-primary d-flex align-items-center justify-content-center" @click="selectImg((i_index - 1) * level_properties[current_level]['n_x'] + index)"
                      :style="{
                        'background': img_dict['selected'] == false ? 'white' :  
                                      img_dict['selected'] == true && img_dict['shape'] == target_img['shape'] ? 'green': 'red', 
                        'cursor': 'pointer', 'width': '150px', 'height': '150px'}">

                    <div class="m-2 d-flex align-items-center justify-content-center"
                          :style="{'width': '120px', 'height': '120px', 'background': 'white'}">
                      
                      <div style="position: relative;">
                        <img v-if="img_dict['shape'] != target_img['shape']" 
                            v-bind:src="img_dict['url']" 
                            class="img-fluid w-100"
                            :style="{transform: level_properties[current_level]['target_shape_size'] == 'bigger'? 'scale(0.5)' : ''}"
                          >
                        <img v-else 
                            v-bind:src="img_dict['url']"
                            class="img-fluid w-100"
                            :style="{ filter: 'hue-rotate(' + level_properties[current_level]['target_shape_colour'] + 'deg)'}"
                            >
                            
                        <p style="min-height: 10px; position: absolute; top: 90%; left: 50%; transform: translate(-50%, -50%); font-size: 14px;">
                          {{ img_dict['shape'] == target_img['shape'] && level_properties[current_level]['target_shape_label'] ? target_img['shape'] : '' }}
                        </p>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </template>

      <template v-slot:sidebar>
        <Sidebar class="h-100"
          :current_exercise_index="current_exercise_index"
          :total_exercises="total_exercises"
          :has_next="true"
          @next="nextExercise()"

          :has_show_results="show_results"
          @show_results="goToResults()"   
          >

        </Sidebar>
      </template>
    </Layout>
    

    <div class="modal fade" id="next_popup" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Good job!</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="m-3">
              <div class="row">
                <h4> Congratulation you have found all the shapes! </h4>
              </div>
              <div class="row mt-2">
                <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>
import Layout from '@/views/session/Normal_Session/exercise_type/_layout/layout.vue'
import Sidebar from '@/views/session/Normal_Session/exercise_type/_layout/sidebar.vue'

import { Modal } from 'bootstrap'
import {startConfetti} from '@/common_js/confetti'

import {getData, updateDataDirectory} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'

import {logActivity} from '@/utils/utils'
import { useTTS } from '@/stores/tts'

export default {
  name: "Visual_Scanning",
  props: {
    exForm: {
      type: Object,
    }
  },
  components: {Layout, Sidebar},
  data() {
    return {

      target_img: {},
      
      n_images: 0,
      current_level: 0,
      interaction_message_color:'',
      interaction_message:'',
      level_up_counter:0,
      level_up_target:2,

      base_level_address:'/Levels/Visual_Scanning',
      level_address:'',
      multi_colour:false,
      only_simple_shapes:true,
      
      level_properties:{
        1:{n_x: 5, n_y: 3, multi_colour:false, only_simple_shapes:true, target_shape_colour: '180', target_shape_size: 'bigger', target_shape_label: true},
        2:{n_x: 5, n_y: 3, multi_colour:false, only_simple_shapes:true, target_shape_colour: '180', target_shape_size: 'bigger', target_shape_label: false},
        3:{n_x: 5, n_y: 3, multi_colour:false, only_simple_shapes:true, target_shape_colour: '180', target_shape_size: 'normal', target_shape_label: false},
        4:{n_x: 5, n_y: 3, multi_colour:false, only_simple_shapes:true, target_shape_colour: '0', target_shape_size: 'normal', target_shape_label: false},
        5:{n_x: 10, n_y: 3, multi_colour:false, only_simple_shapes:true, target_shape_colour: '0', target_shape_size: 'normal', target_shape_label: false},
        6:{n_x: 10, n_y: 4, multi_colour:false, only_simple_shapes:false, target_shape_colour: '0', target_shape_size: 'normal', target_shape_label: false},
        7:{n_x: 10, n_y: 4, multi_colour:true, only_simple_shapes:false, target_shape_colour: '0', target_shape_size: 'normal', target_shape_label: false},
        8:{n_x: 10, n_y: 4, multi_colour:true, only_simple_shapes:true, target_shape_colour: '0', target_shape_size: 'normal', target_shape_label: false}
      },
      level_up_message:'You have been doing really well! Let’s try something harder.',
      
      results: { correct:0, incorrect:0, n_images:0, total_attemps: 0, total_wrong: 0, total_found: 0, total_available: 0},
      shape_folder_data: {},

      all_img:[],
      
      curr_ex_done:false,
      current_exercise_index:1,
      total_exercises: 1,
      show_results:false,
      bv_tts: useTTS().tts_engine,
    }
  },
  created() {
    logActivity( 'accessed', 'matching_shapes')
  },
  beforeUnmount()
  {

  },
  mounted() {
    this.setup()
  },
  methods: 
  {
    setup() {

      const _this = this

      _this.show_results = _this.exForm.current_exercise_index >= 1

      _this.current_exercise_index = _this.exForm.current_exercise_index + 1

      _this.total_exercises = _this.exForm.total_all_type_exercises

      _this.loadDifficulty();
    },
    loadDifficulty()
    {

      let _this = this

      let userCategory = localStorage.getItem('userCategory');

      let uid = localStorage.getItem('uid');

      this.level_address = '/Users/' + uid + this.base_level_address

      if(userCategory != undefined || userCategory === 'slp') {
        let client_uid = localStorage.getItem('client_uid');

        if(client_uid != 'undefined' && client_uid != null && client_uid != '') {
          this.level_address = '/Users/' + client_uid + this.base_level_address
        }
      }

      getData(this.level_address, function (err, result) {

        let current_level = result.child('current_level').val()
        let lvl_up_counter = result.child('level_up_counter').val()

        if(current_level == undefined || lvl_up_counter == undefined) {
          console.debug('level reload')
          current_level = 1
          lvl_up_counter = 0
          updateDataDirectory(_this.level_address, {current_level:current_level, level_up_counter:lvl_up_counter})
        }

        console.debug('current_level = ' + current_level)

        if(current_level<1 || current_level>Object.keys(_this.level_properties).length) {
          current_level = 1
        }

        _this.current_level = current_level

        _this.level_up_counter = lvl_up_counter

        let curr_level_config = _this.level_properties[_this.current_level]

        _this.n_images = curr_level_config['n_x'] * curr_level_config['n_y'] 
        _this.only_simple_shapes = curr_level_config['only_simple_shapes']
        _this.multi_colour = curr_level_config['multi_colour']
        
        _this.loadShapes()            
      })
    },
    loadShapes() {
      let _this = this;
      let shape_folder_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('Shapes');

      getData(shape_folder_ref , function (err, result) {

        _this.shape_folder_data = result.val();
        console.error(_this.shape_folder_data)
        _this.randomiseImages2Use()
      });
    },
    randomiseImages2Use() {
      const _this = this
      
      let selectedShapesForRandomisation = []

      if(this.multi_colour) {
        
        for(let shape_complexity in this.shape_folder_data) {

          if(!this.only_simple_shapes || shape_complexity==='Simple') {
            
            for(let colour in this.shape_folder_data[shape_complexity]) {
              
              for(let shape in this.shape_folder_data[shape_complexity][colour]) {
                selectedShapesForRandomisation.push(this.shape_folder_data[shape_complexity][colour][shape])
              }
            }
          }
        }
      } else {
        let all_colours = Object.keys(this.shape_folder_data['Simple'])
        let n_colours = all_colours.length

        let target_colour_index = Math.floor(Math.random() * n_colours)

        let target_colour = all_colours[target_colour_index]
        
        for(let shape_complexity in this.shape_folder_data) {

          if(!this.only_simple_shapes || shape_complexity==='Simple') {

            for(let shape in this.shape_folder_data[shape_complexity][target_colour]){
              selectedShapesForRandomisation.push(this.shape_folder_data[shape_complexity][target_colour][shape])
            }
          }
        }
        
      }

      let storageRef = _this.$firebase.storage().ref();

      _this.load_target_image(selectedShapesForRandomisation)

      let k = 0
      while(k < _this.n_images) {
        let curr_set_num = Math.floor(Math.random() * selectedShapesForRandomisation.length ) 
        
        let img_address = selectedShapesForRandomisation[curr_set_num]['image'];
        
        storageRef.child(img_address).getDownloadURL().then(function(url) {

          selectedShapesForRandomisation[curr_set_num]['url'] = url
          selectedShapesForRandomisation[curr_set_num]['selected'] = false

          _this.all_img.push({ ...selectedShapesForRandomisation[curr_set_num] })

          if (selectedShapesForRandomisation[curr_set_num]['shape'] === _this.target_img['shape']) {
            _this.results['total_available'] = _this.results['total_available'] + 1
          }

        }).catch(function(error) {

        });

        k=k+1;
      } 

      _this.results['n_images'] = _this.n_images    

    },
    load_target_image(selectedShapesForRandomisation)
    {
      const _this = this

      let storageRef = _this.$firebase.storage().ref();

      let target_img = Math.floor(Math.random() * selectedShapesForRandomisation.length) 
      
      document.getElementById('img_target').src = selectedShapesForRandomisation[target_img];

      _this.target_img = { ...selectedShapesForRandomisation[target_img] }

      let img_address = selectedShapesForRandomisation[target_img]['image'];

      storageRef.child(img_address).getDownloadURL().then(function(url) {
        _this.target_img['url'] =  url
      }).catch(function(error) {

      });

    },
    storeResults()
    {
      const _this = this;

      if(_this.curr_ex_done) {
        let all_results = {
          Correct: _this.results['correct'], 
          Incorrect: _this.results['incorrect'], 
          Level: _this.current_level,
          n_images: _this.results['n_images'],
          total_found: _this.results['total_found'],
          total_wrong: _this.results['total_wrong'],
          total_attemps: _this.results['total_attemps'], 
          total_available: _this.results['total_available']
        }

        let plot_results = {
          Correct: _this.results['correct'], 
          Incorrect: _this.results['incorrect'], 
          n_images: _this.results['n_images'],
          total_found: _this.results['total_found'],
          total_wrong: _this.results['total_wrong'],
          total_attemps: _this.results['total_attemps'], 
          total_available: _this.results['total_available']
        }

        let results = {
          all_results: all_results, 
          plot_results: plot_results
        }
        
        let visual_scanning_data = {
          resultsTablePre: {
            exercise_type: 'visual_scanning',  
            results: results, 
            audio_address: ''
          }
        }
        
        save_pre_computed_results(_this.exForm, visual_scanning_data)

        _this.$emit('logExerciseResults', visual_scanning_data);

      }
     
    },
    nextExercise()
    {
      this.storeResults()
      this.$emit('nextExercise');
    },
    goToResults()
    {
      const _this = this
      this.storeResults()
      
      _this.$emit('goToResults', false);
    },
    selectImg(img_num)
    {
      const _this = this
      
      if ( this.all_img[img_num]['selected'] == false ) {
        this.all_img[img_num]['selected'] = true
      } else {
        return
      }


      if (this.all_img[img_num]['shape'] == this.target_img['shape']) {

        _this.results['total_found'] = _this.results['total_found']  + 1
        

        let correct_text = 'Correct.'

        this.interaction_message = 'Correct.'
        this.interaction_message_color = 'color:green;white-space: pre-wrap'

        _this.bv_tts.system_say_sentences([correct_text])


      } else {
          
        _this.results['total_wrong'] = _this.results['total_wrong']  + 1     
        

        let incorrect_text = 'Not correct'

        this.interaction_message = 'Not quite right. \n Try again.'
        this.interaction_message_color = 'color:red;white-space: pre-wrap'
        _this.bv_tts.system_say_sentences([incorrect_text])

      }

      if (_this.results['total_available'] == _this.results['total_found']) {
        _this.curr_ex_done = true

        if (_this.results['total_wrong'] == 0) {
          startConfetti(this)

          _this.results['correct'] = _this.results['correct']  + 1

          _this.level_up_counter = _this.level_up_counter + 1

          if ( _this.level_up_counter >= _this.level_up_target && _this.current_level < Object.keys(_this.level_properties).length) {
            _this.current_level = _this.current_level + 1
            _this.level_up_counter = 0
          }
        } else {
          _this.results['incorrect'] = _this.results['incorrect']  + 1

          _this.level_up_counter = _this.level_up_counter - 1
          _this.level_up_counter = Math.max(_this.level_up_counter, 0)
        }

        updateDataDirectory(this.level_address, {
          current_level: _this.current_level, 
          level_up_counter: _this.level_up_counter
        })

        let next_popup = new Modal(document.getElementById('next_popup'));
        next_popup.show()
      }

      
      _this.results['total_attemps'] = _this.results['total_attemps'] + 1
      
    },

  },
}
</script>
<style scoped>
.std-pre{
  word-break: break-all

}
.page_title {
  width: 100%;
  height: 13mm;
  background-color: #7cc543;
    /* opacity: 80%; */
}
.page_main {
  width: 100%;
  height: calc(100vh - 13mm);
  background-color: #195e52;
  /* opacity: 80%; */
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 13mm;
  margin-left: 2%;
  font-size: 30px;
}
.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}

.text_item {
/* margin-bottom: 18px; */
font-size: 30px;
/* vertical-align: middle; */
/* padding: 40px 200px; */
/* height:40%; */
}

.custom-text{
  font-size: 20px;
    /* color:#FFFFFF; */
}

.menu__item {
  display: block;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 24px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}

.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

.button::-moz-focus-inner{
  border: 0;
  padding: 0;
}


</style>

<!-- Result Description
{
  "Memory Image":
  {
    "<key>": {
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>", //audio address is here because that's how I used to find where the results are
        "exercise": "Memory Image",
        "exercise_type": "Memory Games",
        "results": {
          "all_results": { 
            "Correct": "<int>",
            "Incorrect": "<int>",
            "ListenCount": "<int>",
            "Total": "<int>"
          },
          "plot_results": { //These values are the ones that feed into the end results plot
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Memory Image"
  }
} -->