<template>
  <div>
    <h1 class="display-3 mt-4"><strong>Set Goals</strong></h1>
    <p>Are there some goals you would like to achieve?</p>
    <br/>
    <div class="row">
      <div class="col-md-6">


        <div class="row">
          <label>Type your goal and click add</label>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-start">
              <input class="form-control me-2" type="text" placeholder="" v-model="typed_goal">
              <button class="btn btn-sm btn-outline-primary rounded-pill px-4" @click="add_goal(typed_goal)">Add</button>
            </div>
          </div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="mb-0">
            <p class="mb-0">Examples:</p>
          </div>
          <div v-for="index in Math.ceil(example_goals_list.length / 2)">
            <div class="d-flex mt-2">
              <div class="me-4">
                <div class="card card-body" @click="add_goal(example_goals_list[index * 2 - 2])" 
                  :style="['cursor: pointer', 
                    {'background-color': goals_list.includes(example_goals_list[index * 2 - 2]) ? 'rgba(102, 51, 153, 0.4)': ''}]">
                  {{ example_goals_list[index * 2 - 2] }}
                </div>
              </div>
              <div class="">
                <div class="card card-body" @click="add_goal(example_goals_list[index * 2 - 1])" 
                :style="['cursor: pointer', 
                    {'background-color': goals_list.includes(example_goals_list[index * 2 - 1]) ? 'rgba(102, 51, 153, 0.4)': ''}]">
                  {{ example_goals_list[index * 2 - 1] }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="row mb-4">
          <div class="col-12">
            <div class="card card-body">
              <div class="row text-center">
                <h5 class="mt-2">Your added goals</h5>
              </div>
              <div class="row mb-4">
                <div v-if="goals_list.length > 0">
                  <div v-for="goal in goals_list">
                    <div class="p-3 m-2 bg-light-subtle border border-primary-subtle rounded-3">
                      {{ goal }} <button class="btn btn-sm btn-outline-danger float-end" @click="remove_goal(goal)"><i class="bi bi-trash"></i></button>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  <br/>
                  <br/>
                  <br/>
                  <i class="bi bi-slash-square" style="font-size: 40px;opacity: 0.25;"></i>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <br/>
    <br/>
    <div class="row mt-4 mb-4 justify-content-center">
      <div class="col-12 text-center">
        <button class="btn btn-primary rounded-pill px-4" @click="next()">Continue</button>
      </div>
    </div>

  </div>
</template>

<script>
import {ref, watch} from 'vue'
import {setUserData, updateUserData} from '@/firebase/index'


export default {
  name: 'Set_Goals',
  setup(props, { emit }) {

    const typed_goal = ref('')
    const goals_list = ref([])
    const example_goals_list = ref([
      'Be able to read newspapers.',
      'Say happy birthday to my grandson.',
      'Go to a grocery store.',
      'Order a takeaway.',
    ])


    const checkIfDataIsStored = () => {
      let set_goals = JSON.parse(sessionStorage.getItem('set_goals'))
      
      if (set_goals) {
        goals_list.value = set_goals
      }
    }

    checkIfDataIsStored()


    const next = () => {

      let goals_structure = {}

      for (let index=0; index < goals_list.value.length; index++) {
        goals_structure[index] = {
          status: 'started',
          text: goals_list.value[index]
        }
      }

      sessionStorage.setItem('set_goals', JSON.stringify(goals_list.value))

      setUserData('Goals', goals_structure)
      updateUserData('Settings/onboarding', {'current_stage_index': 3})
      emit('next')
    }

    const add_goal = (goal_text) => {

      if (!goals_list.value.includes(goal_text)) {
        goals_list.value.push(goal_text)
        typed_goal.value = ''
      }

    }

    const remove_goal = (goal_text) => {
      goals_list.value = goals_list.value.filter(item => item !== goal_text);
    }

    return {
      next,
      add_goal,
      remove_goal,

      example_goals_list,
      goals_list,
      typed_goal

    }
  }
}
</script>

<style scoped>

</style>
