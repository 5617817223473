<template>
  <div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='Karaoke';openModal('singing_modal');">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Voice_singing" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px; height: 40px;">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Singing</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="singing_modal" tabindex="-1" aria-labelledby="singing_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Singing</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('singing_modal');"></button>
          </div>
          <div class="modal-body">
            <div class="row" style="height:40vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'Karaoke'?'btn-primary':'btn-light']" type="button" @click="selected_module='Karaoke'">
                  Karaoke
                </button>
              </div>
              <div class="col-8">
                <div v-show="selected_module == 'Karaoke'">
                  <p class="lead">Practise phonation with the help of a melody </p>
                  <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: grey; font-size:medium; margin-left: 0%;">No analysis</span>

                    </div>
                </div>
        
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Voice_singing from '@/assets/images/dashboard/slt/training/Voice_singing.svg'

import { Modal } from 'bootstrap'

export default {
  name: 'singing',
  components: {},
  props: 
  {
  },
  data(){
    return {
      subscription_tier: sessionStorage.getItem("tier"),
      selected_module: '',

      Voice_singing: Voice_singing, 
      modal_references: {},
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
    },
  },
  async created() {

  },
  mounted() {
    this.modal_references = {
      "singing_modal": new Modal(document.getElementById('singing_modal')),
    }
  },
  methods: {
    emit_start_training() {
      this.closeModal('singing_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('singing_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
  }
}
</script>