import { getData, setDataDirectory, getDataDirectory, updateDataDirectory, getAuthToken } from '@/firebase/index'
import { useToast } from "vue-toastification";
import axios from 'axios'

export function getTmpAssignmentList(client_uid, type) //fill table with tmp data stored in 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/'
{//switch between assessment and homework here

  let uid = localStorage.getItem('uid');

  let target_dir = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/' + type + '/general/';

  let promise_general = new Promise((resolve) => {
    getData(target_dir, function (err, result) {

      let assignment_list_tmp = []

      result.forEach(function(childSnapshot)
      {
        var key = childSnapshot.key
        let module_data = childSnapshot.val()
  
        var module_name = childSnapshot.child('mod_name').val();
        var module_descript = childSnapshot.child('keywords').val();
  
        let config = ''
  
        if('modifier' in module_data)
        {
          if(!Array.isArray(module_data['modifier']))
          {
            config = module_data['modifier']
          } else {
            for(let i in module_data['modifier'])
            {
              for(let k in module_data['modifier'][i])
              {
                config = module_data['modifier'][i][k]
              }
            }
          }
        }
  
        if(module_name!=null)
        {
          assignment_list_tmp.push({key: key, snapshot: childSnapshot, name:module_name, config:config, isPackage: false, index: assignment_list_tmp.length});
        }
      });

      assignment_list_tmp.sort((a, b) => parseFloat(a.key) - parseFloat(b.key));
      

      resolve(assignment_list_tmp)
      
    });
  })


  target_dir = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/' + type + '/packages/';

  let promise_packages = new Promise((resolve) => {

    getData(target_dir, function (err, result) {
      
      let assignment_list_tmp = []

      result.forEach(function(childSnapshot)
      {
        var key = childSnapshot.key
        let master_module_data = childSnapshot.val()

        let master_module_name = childSnapshot.child('name').val();

        let modules = master_module_data['modules']
        
        let modules_name_list = []

        for (const [key, value] of Object.entries(modules)) {
          modules_name_list.push(value['mod_name'])
        }

        let config = modules_name_list.join(', ')

        if(master_module_name!=null)
        {
          assignment_list_tmp.push({key: key, snapshot: childSnapshot, name:master_module_name, config:config, isPackage: true, index: assignment_list_tmp.length});
        }
      });

      assignment_list_tmp.sort((a, b) => parseFloat(a.key) - parseFloat(b.key));
      
      resolve(assignment_list_tmp)

    });
  })

  return [promise_general, promise_packages]
}

export async function check_has_last_sent_assignment(client_uid, type) {
  let uid = localStorage.getItem('uid');
  const last_sent_assignment_list = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/history/' + type + '/'

  return new Promise( (resolve, reject) => {
    getDataDirectory(last_sent_assignment_list).then( (value) => {

      if (value.val()){
        resolve(true)
      } else {
        resolve(false)
      }
    })


  })
}

export async function get_last_sent_assignment(client_uid, type) {
  let uid = localStorage.getItem('uid');
  const last_sent_assignment_list = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/history/' + type + '/'

  //SLT tmp assignment list
  const tmp_slt_assignment_list = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/' + type + '/'

  return new Promise( (resolve, reject) => {
    getDataDirectory(last_sent_assignment_list).then( (value) => {
      if (value.val()){
        updateDataDirectory(tmp_slt_assignment_list, value.val())
        resolve(true)
      } else {
        resolve(false)
      }
    })


  })
}

export async function copyToPatient(client_uid, type, message, is_anonymous_client)
{
  
  
  return new Promise( (resolve, reject) => {


    //copy assignments from SLT/Client to User/ of patient
    if(client_uid) {

      let uid = localStorage.getItem('uid');

      //PATIENT assignments path
      let patient_modules_dir = '/Users/' + client_uid + '/Assignments/' + type

      setDataDirectory(patient_modules_dir, {}) //delete assignments of patient

      //SLT tmp assignment list
      let tmp_slt_assignment_list = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/' + type + '/'


      const last_sent_assignment_list = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/history/' + type + '/'

      getDataDirectory(tmp_slt_assignment_list).then( (value) => {
        let data = value.val()
        //last sent assignment
        updateDataDirectory(last_sent_assignment_list, data)
        
        data['new'] = true
        updateDataDirectory(patient_modules_dir, data)
      })

      let data = {
        "slt_uid": uid,
        "client_uid": client_uid,
        "message": message
      }
      
      
      if (!is_anonymous_client) {
        let notification_api = import.meta.env.VITE_FAST_API + "/new_homework"

        getAuthToken().then((idToken) => {
  
          axios.post(notification_api, data, {
            headers: {
              'Authorization': `Bearer ${idToken}`,
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.status === 200) {
              resolve()
            }
          })
        })
      } else {
        resolve()
      }

      
    } else {
      reject()
    }
  })
}

export function deleteTmpAssignmentList(client_uid, type) {
  let uid = localStorage.getItem('uid');

  let target_modules_dir = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List/' + type
  setDataDirectory(target_modules_dir, {})
}