export default
{
    num_meme_imgs:5,  
    
    

    intelligibility_grading_strict: [
      {min:-1,max:20,grade:'Needs Practice',colour:'#767171'},
      {min:20,max:40,grade:'OK',colour:'#B87794'},
      {min:40,max:70,grade:'Good',colour:'#5B9BD5'},
      {min:70,max:1000,grade:'Great',colour:'#04813A'}
    ],

    intelligibility_grading_encouragement: [
      {min:-1,max:10,grade:'Needs Practice',colour:'#767171'},
      {min:10,max:30,grade:'OK',colour:'#B87794'},
      {min:30,max:60,grade:'Good',colour:'#5B9BD5'},
      {min:60,max:1000,grade:'Great',colour:'#04813A'}
    ],

    sentence_intelligibility_grading_strict: [
      {min:-1,max:5,grade:'Did not recognise',colour:'#C4BCBC'},
      {min:5,max:20,grade:'Needs Practice',colour:'#767171'},
      {min:20,max:40,grade:'OK',colour:'#B87794'},
      {min:40,max:70,grade:'Good',colour:'#5B9BD5'},
      {min:70,max:1000,grade:'Great',colour:'#04813A'}
    ],

    sentence_intelligibility_grading_encouragement: [
      {min:-1,max:5,grade:'Did not recognise',colour:'#C4BCBC'},
      {min:5,max:10,grade:'Needs Practice',colour:'#767171'},
      {min:10,max:30,grade:'OK',colour:'#B87794'},
      {min:30,max:60,grade:'Good',colour:'#5B9BD5'},
      {min:60,max:1000,grade:'Great',colour:'#04813A'}
    ],

    loudness_grading_strict: [
      {min:-1,max:52,grade:'Did not recognise',colour:'#C4BCBC'},
      {min:52,max:57,grade:'Needs Practice',colour:'#767171'},
      {min:57,max:62,grade:'OK',colour:'#B87794'},
      {min:62,max:67,grade:'Good',colour:'#5B9BD5'},
      {min:67,max:1000,grade:'Great',colour:'#04813A'}
    ],

    loudness_grading_encouragement: [
      {min:-1,max:50,grade:'Did not recognise',colour:'#C4BCBC'},
      {min:50,max:55,grade:'Needs Practice',colour:'#767171'},
      {min:55,max:60,grade:'OK',colour:'#B87794'},
      {min:65,max:70,grade:'Good',colour:'#5B9BD5'},
      {min:70,max:1000,grade:'Great',colour:'#04813A'}
    ],


    vocabularyAgeLevel:{
      "Adult":"Adult",
      "14":"14",
      "12":"12",
      "10":"10",
      "6":"6"


    },
    
    
    phonemeDisplayDictionaryIPA: {
        iy: 'i:',
        ih: 'ɪ',
        ey: 'eɪ',
        eh: 'e',
        ae: 'æ',
        aa: 'a:',
        aar: 'ɒ', //this sound doesnt exist in SAPI as it is a british english sound, so using an available one here
        ao: 'ɔ:',
        uh: 'ʊ',
        ow: 'əʊ',
        uw: 'u:',
        ah: 'ʌ',
        ay: 'aɪ',
        aw: 'aʊ',
        oy: 'ɔɪ',
        yuw: 'ju',
        ax: 'ə',
        ihr: 'ɪə',
        ehr: 'eə',
        uhr: 'ʊə',
        ayr: 'aɪɹ',
        awr: 'aʊɹ',
        aor: 'ɔɹ',
        err: 'ɝ',
        er: 'ɜ:',
        axr: 'ɚ',
        w: 'w',
        y: 'j',
        p: 'p',
        b: 'b',
        t: 't',
        d: 'd',
        k: 'k',
        g: 'g',
        m: 'm',
        n: 'n',
        ng: 'ŋ',
        f: 'f',
        v: 'v',
        th: 'θ',
        dh: 'ð',
        s: 's',
        z: 'z',
        sh: 'ʃ',
        zh: 'ʒ',
        h: 'h',
        ch: 'tʃ',
        jh: 'dʒ',
        l: 'l',
        r: 'r',
        // j: 'j',
    },
    phonemeDisplayListWithExamples: [
      {
        'simple_representation': 'iy',
        'IPA':'i:',
        'example_0':' in Sh',
        'example_1':'ee',
        'example_2':'p'
      },
      {
        'simple_representation': 'ih',
        'IPA':'ɪ',
        'example_0':' in D',
        'example_1':'i',
        'example_2':'sh'
      },
      {
        'simple_representation': 'uh',
        'IPA':'ʊ',
        'example_0':' in L',
        'example_1':'oo',
        'example_2':'k'
      },
      {
        'simple_representation': 'uw',
        'IPA':'u:',
        'example_0':' in Bl',
        'example_1':'ue',
        'example_2':''
      },
      {
        'simple_representation': 'eh',
        'IPA':'e',
        'example_0':' in ',
        'example_1':'E',
        'example_2':'gg'
      },
      {
        'simple_representation': 'ax',
        'IPA':'ə',
        'example_0':' in ',
        'example_1':'A',
        'example_2':'live'
      },
      {
        'simple_representation': 'er',
        'IPA':'ɜ:',
        'example_0':' in ',
        'example_1':'Ear',
        'example_2':'th'
      },
      {
        'simple_representation': 'ao',
        'IPA':'ɔ:',
        'example_0':' in F',
        'example_1':'or',
        'example_2':'k'
      },
      {
        'simple_representation': 'ae',
        'IPA':'æ',
        'example_0':' in C',
        'example_1':'a',
        'example_2':'t'
      },
      {
        'simple_representation': 'ah',
        'IPA':'ʌ',
        'example_0':' in F',
        'example_1':'u',
        'example_2':'n'
      },
      {
        'simple_representation': 'aa',
        'IPA':'ɑ:',
        'example_0':' in H',
        'example_1':'ear',
        'example_2':'t'
      },
      {
        'simple_representation': 'aar',
        'IPA':'ɒ',
        'example_0':' in S',
        'example_1':'o',
        'example_2':'ck'
      },
      {
        'simple_representation': 'ihr',
        'IPA':'ɪə',
        'example_0':' in D',
        'example_1':'ea',
        'example_2':'r'
      },
      {
        'simple_representation': 'ehr',
        'IPA':'eə',
        'example_0':' in B',
        'example_1':'ea',
        'example_2':'r'
      },
      {
        'simple_representation': 'ow',
        'IPA':'əʊ',
        'example_0':' in S',
        'example_1':'o',
        'example_2':''
      }, 
      {
        'simple_representation': 'aw',
        'IPA':'aʊ',
        'example_0':' in H',
        'example_1':'ou',
        'example_2':'se'
      }, 
      {
        'simple_representation': 'uhr',
        'IPA':'ʊə',
        'example_0':' in P',
        'example_1':'ur',
        'example_2':'e'
      }, 
      {
        'simple_representation': 'ey',
        'IPA':'eɪ',
        'example_0':' in B',
        'example_1':'a',
        'example_2':'ke'
      },
      {
        'simple_representation': 'ay',
        'IPA':'aɪ',
        'example_0':' in P',
        'example_1':'ie',
        'example_2':''
      },
      {
        'simple_representation': 'oy',
        'IPA':'ɔɪ',
        'example_0':' in ',
        'example_1':'Oy',
        'example_2':'ster'
      },
      {
        'simple_representation': 'p',
        'IPA':'p',
        'example_0':' in ',
        'example_1':'P',
        'example_2':'erch'
      }, 
      {
        'simple_representation': 'f',
        'IPA':'f',
        'example_0':' in ',
        'example_1':'F',
        'example_2':'ive'
      },
      {
        'simple_representation': 't',
        'IPA':'t',
        'example_0':' in ',
        'example_1':'T',
        'example_2':'ent'
      },
      {
        'simple_representation': 'th',
        'IPA':'θ',
        'example_0':' in ',
        'example_1':'Th',
        'example_2':'ick'
      },
      {
        'simple_representation': 'ch',
        'IPA':'tʃ',
        'example_0':' in ',
        'example_1':'Ch',
        'example_2':'eese'
      },
      {
        'simple_representation': 's',
        'IPA':'s',
        'example_0':' in ',
        'example_1':'S',
        'example_2':'ea'
      },
      {
        'simple_representation': 'sh',
        'IPA':'ʃ',
        'example_0':' in ',
        'example_1':'Sh',
        'example_2':'e'
      },
      {
        'simple_representation': 'k',
        'IPA':'k',
        'example_0':' in ',
        'example_1':'C',
        'example_2':'oin'
      },
      {
        'simple_representation': 'b',
        'IPA':'b',
        'example_0':' in ',
        'example_1':'B',
        'example_2':'oost'
      },
      {
        'simple_representation': 'v',
        'IPA':'v',
        'example_0':' in ',
        'example_1':'V',
        'example_2':'oice'
      },
      {
        'simple_representation': 'd',
        'IPA':'d',
        'example_0':' in ',
        'example_1':'D',
        'example_2':'oor'
      },
      {
        'simple_representation': 'dh',
        'IPA':'ð',
        'example_0':' in ',
        'example_1':'Th',
        'example_2':'ese'
      },
      {
        'simple_representation': 'jh',
        'IPA':'dʒ',
        'example_0':' in ',
        'example_1':'J',
        'example_2':'oy'
      },
      {
        'simple_representation': 'z',
        'IPA':'z',
        'example_0':' in ',
        'example_1':'Z',
        'example_2':'one'
      },
      {
        'simple_representation': 'zh',
        'IPA':'ʒ',
        'example_0':' in Gara',
        'example_1':'ge',
        'example_2':''
      },
      {
        'simple_representation': 'g',
        'IPA':'g',
        'example_0':' in ',
        'example_1':'G',
        'example_2':'et'
      },
      {
        'simple_representation': 'h',
        'IPA':'h',
        'example_0':' in ',
        'example_1':'H',
        'example_2':'and'
      },
      {
        'simple_representation': 'm',
        'IPA':'m',
        'example_0':' in ',
        'example_1':'M',
        'example_2':'ove'
      },
      {
        'simple_representation': 'n',
        'IPA':'n',
        'example_0':' in ',
        'example_1':'N',
        'example_2':'ice'
      },
      {
        'simple_representation': 'ng',
        'IPA':'ŋ',
        'example_0':' in Ki',
        'example_1':'ng',
        'example_2':''
      },
      {
        'simple_representation': 'r',
        'IPA':'r',
        'example_0':' in ',
        'example_1':'R',
        'example_2':'hyme'
      },
      {
        'simple_representation': 'l',
        'IPA':'l',
        'example_0':' in ',
        'example_1':'L',
        'example_2':'augh'
      },
      {
        'simple_representation': 'w',
        'IPA':'w',
        'example_0':' in ',
        'example_1':'W',
        'example_2':'here'
      },
      {
        'simple_representation': 'y',
        'IPA':'j',
        'example_0':' in ',
        'example_1':'Y',
        'example_2':'ard'
      },
    ],
    phonemeDisplayDictionary: {
        iy: 'i:',  
        ih: 'ɪ', 
        uh: 'ʊ',
        uw: 'u:',
        eh: 'e', 
        ax: 'ə',
        er: 'ɜ:',
        ao: 'ɔ:',
        ae: 'æ',
        ah: 'ʌ',
        aa: 'ɑ:', 
        aar: 'ɒ', //this sound doesnt exist in SAPI as it is a british english sound, so using an available one here
        ihr: 'ɪə',
        ehr: 'eə',
        ow: 'əʊ',
        aw: 'aʊ',
        uhr: 'ʊə',
        ey: 'eɪ',
        ay: 'aɪ',
        oy: 'ɔɪ',
        p: 'p',
        f: 'f',
        t: 't',
        th: 'θ',
        ch: 'tʃ',
        s: 's',
        sh: 'ʃ',
        k: 'k',
        b: 'b',
        v: 'v',
        d: 'd',
        dh: 'ð',
        jh: 'dʒ',
        z: 'z',
        zh: 'ʒ',
        g: 'g',
        h: 'h',
        m: 'm',
        n: 'n',
        ng: 'ŋ',
        r: 'r',
        l: 'l',
        w: 'w',
        y: 'j',
        // ayr: 'aɪɹ',
        // awr: 'aʊɹ',
        // aor: 'ɔɹ',
        // err: 'ɝ',               
        // axr: 'ɚ',         
        // j: 'j',
    },
    phonemeDisplayUKSpelling: {
      iy: 'ee',  
      ih: 'i', 
      uh: 'uh',
      uw: 'uuh',
      eh: 'eh', 
      ax: 'ar',
      er: 'er',
      ao: 'or',
      ae: 'ae',
      ah: 'ah',
      aa: 'aa', 
      aar: 'aw', //this sound doesnt exist in SAPI as it is a british english sound, so using an available one here
      ihr: 'ihr',
      ehr: 'ehr',
      ow: 'ow',
      aw: 'aow',
      uhr: 'uhr',
      ey: 'ey',
      ay: 'ay',
      oy: 'oy',
      p: 'p',
      f: 'f',
      t: 't',
      th: 'th',
      ch: 'ch',
      s: 's',
      sh: 'sh',
      k: 'k',
      b: 'b',
      v: 'v',
      d: 'd',
      dh: 'th',
      jh: 'j',
      z: 'z',
      zh: 'ge',
      g: 'g',
      h: 'h',
      m: 'm',
      n: 'n',
      ng: 'ng',
      r: 'r',
      l: 'l',
      w: 'w',
      y: 'yh',
      // ayr: 'aɪɹ',
      // awr: 'aʊɹ',
      // aor: 'ɔɹ',
      // err: 'ɝ',               
      // axr: 'ɚ',         
      // j: 'j',
  },
    phonemeVowelList:
    [
        'iy',  
        'ih', 
        'uh',
        'uw',
        'eh', 
        'ax',
        'er',
        'ao',
        'ae',
        'ah',
        'aa', 
        'aar', //this sound doesnt exist in SAPI as it is a british english sound, so using an available one here
        'ihr',
        'ehr',
        'ow',
        'aw',
        'uhr',
        'ey',
        'ay',
        'oy'
    ],
    consonants:['p','b','t','d','g','m','n','v','s','h','l','r','z','sh','ch','th','dh','f','jh','zh','y','k','ng','w'],
    conso_letters:
    {
      p:['pp','p','P'],
      b:['bb','b','B'],
      t:['tt','t','T','d'],
      d: ['dd','d','D'],
      k: ['ch','Ch','ck','k','K','cc','c','C','x','Q','q'],
      g: ['gg','g','G'],
      m: ['mm','m','M'],
      n: ['nn','n','N'],
      ng: ['ng','n'],
      f: ['ph','Ph','ff','f','F'],
      v: ['v','V'],
      th: ['th','Th'],
      dh: ['th','Th'],
      s: ['Ps','sc','Sc','cs','Cs','ss','se','s','S','c','C'],
      z: ['se','zz','z','Z','ss','s','S'],
      sh: ['sh','Sh','ti','sci','ci','s','S'],
      zh: ['g','j','J','s','z','t'],
      h: ['h','H'],
      ch: ['tch','ch','Ch'],
      jh: ['dg','j','J','g','G'],
      l: ['ll','l','L'],
      r: ['rh','Rh','r','R'],
      y: ['y','Y','u','U'],
      w: ['wh','Wh','w','W','u'],
    },

    octave_notes:
    {
      C2:{"freq":65.41,"min":63.59, "max": 69.42,"id":1,"sound":"do"},
      D2:{"freq":73.42,"min":69.43, "max": 77.92,"id":2,"sound":"re"},
      E2:{"freq":82.41,"min":77.93, "max": 84.86,"id":3,"sound":"mi"},
      F2:{"freq":87.31,"min":84.87, "max": 92.66,"id":4,"sound":"fa"},
      G2:{"freq":98,"min":92.67, "max": 104,"id":5,"sound":"sol"},
      A2:{"freq":110,"min":104.01, "max": 116.74,"id":6,"sound":"la"},
      B2:{"freq":123.47,"min":116.75, "max": 127.14,"id":7,"sound":"si"},
      C3:{"freq":130.81,"min":127.15, "max": 138.82,"id":8,"sound":"do"},
      D3:{"freq":146.83,"min":138.83, "max": 155.82,"id":9,"sound":"re"},
      E3:{"freq":164.81,"min":155.83, "max": 169.71,"id":10,"sound":"mi"},
      F3:{"freq":174.61,"min":169.72, "max": 185.31,"id":11,"sound":"fa"},
      G3:{"freq":196,"min":185.32, "max": 208,"id":12,"sound":"sol"},
      A3:{"freq":220,"min":208.01, "max": 233.47,"id":13,"sound":"la"},
      B3:{"freq":246.94,"min":233.48, "max": 254.29,"id":14,"sound":"si"},
      C4:{"freq":261.63,"min":254.30, "max": 277.65,"id":15,"sound":"do"},
      D4:{"freq":293.66,"min":277.66, "max": 311.65,"id":16,"sound":"re"},
      E4:{"freq":329.63,"min":311.66, "max": 339.43,"id":17,"sound":"mi"},
      F4:{"freq":349.23,"min":339.44, "max": 370.62,"id":18,"sound":"fa"},
      G4:{"freq":392,"min":370.63, "max": 416,"id":19,"sound":"sol"},
      A4:{"freq":440,"min":416.01, "max": 466.94,"id":20,"sound":"la"},
      B4:{"freq":493.88,"min":466.95, "max": 508.565,"id":21,"sound":"si"},
      C5:{"freq":523.25,"min":508.58, "max": 555.29,"id":22,"sound":"do"},
      D5:{"freq":587.33,"min":555.30, "max": 623.29,"id":23,"sound":"re"},
      E5:{"freq":659.25,"min":623.30, "max": 678.86,"id":24,"sound":"mi"},
      F5:{"freq":698.46,"min":678.87, "max": 741.23,"id":25,"sound":"fa"},
      G5:{"freq":783.99,"min":741.24, "max": 832,"id":26,"sound":"sol"},
      A5:{"freq":880,"min":832.01, "max": 933.89,"id":27,"sound":"la"},
      B5:{"freq":987.77,"min":933.90, "max": 1017.14,"id":28,"sound":"si"},
    },
    extract_tongue_twisters_phonemes()
    {

      let tongue_twisters_phonemes_simple_representation = ['b','ch','d','f','g','h','l','y','k','m','n','p','r','s','sh','t','th','v','w','jh','z','ax','er','ow','ah','ih']

      let tongueTwisterPhonemeDisplayListWithExamples = []

      for(let n in tongue_twisters_phonemes_simple_representation)
      {
        let found = false


        for(let k in this.phonemeDisplayListWithExamples)
        {
          if(this.phonemeDisplayListWithExamples[k]['simple_representation']===tongue_twisters_phonemes_simple_representation[n])
          {
            found = true

            tongueTwisterPhonemeDisplayListWithExamples.push(this.phonemeDisplayListWithExamples[k])
            break

          }
        }

            }


      return tongueTwisterPhonemeDisplayListWithExamples
    },



    exercise_type_to_result_types (exercise_type_id) {

      const exercise_to_results_map = {
        //vue location: antonym 
        //module: Antonym Exercise
        "Antonym Exercise": {"description": '', 'result_type_id_list': ['resultsTablePre']},
        
        //vue location: aphasia_yes_no/
        'Yes No Assessment': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: cats_image_selection/
        'cats': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: clock_game/
        //module name: clock game
        "Life Skills": {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: common_sayings/
        'common_sayings': {"description": '', 'result_type_id_list': []},

        //vue location: cookie_theft/
        'cookie_theft': {"description": '', 'result_type_id_list': ['cookie_theft', 'loudness']},

        //vue location: copy_word/
        'Copy Words': {"description": '', 'result_type_id_list': ['resultsTablePre']},
        'copy_word_speak': {"description": '', 'result_type_id_list': []},
        
        //vue location: ddk_rate/
        'ddk_rate': {"description": '', 'result_type_id_list': ['ddk_rate_dictionary', 'loudness']},

        //vue location: hangman/
        'Hangman': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: karaoke/
        'karaoke': {"description": '', 'result_type_id_list': []},

        //vue location: letter_word_match/        
        'Letter Word Match': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: listen_minimal_pairs/
        'Listen Minimal Pairs': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: matching_shapes/
        'Matching Shapes': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: minimal_pairs/
        'Minimal Pairs': {"description": '', 'result_type_id_list': []},

        //vue location: money_recognition/
        'Money Recognition': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: name_a_picture/
        //Module name: Name a picture
        'Word Naming': {"description": '', 'result_type_id_list': ['resultsTablePre']},
        'word_naming_easy': {"description": '', 'result_type_id_list': []},

        //vue location: number_sequence/
        //it is same as clock module - it is not correct should be number_sequence
        'Life Skills': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: orla/
        //module is called ORLA but is written as Read Sentences
        'Read Sentences': {"description": '', 'result_type_id_list': ['resultsTablePre']},
        
        //vue location: pace_training/
        'pace_training': {"description": '', 'result_type_id_list': ['loudness', 'sentencegibility', 'speechrate_dictionary']},

        //vue location: pca/
        'Spoken PCA': {"description": '', 'result_type_id_list': ['resultsTablePre']},

        //vue location: quiz/
        'quiz': {"description": '', 'result_type_id_list': ['loudness', 'sentencegibility']},

        //vue location: reading_comprehension/
        'reading_comprehension': {"description": '', 'result_type_id_list': []},

        //vue location: reading_images/
        'reading_images': {"description": '', 'result_type_id_list': []},

        //vue location: reading_menu/
        'read_menu': {"description": '', 'result_type_id_list': []},

        //vue location: reverse_direction/
        'reverse_direction': {"description": '', 'result_type_id_list': []},

        //vue location: reversible_passive/
        'reversible_passive': {"description": '', 'result_type_id_list': []},

        //vue location: scene_click/
        'scene_click': {"description": '', 'result_type_id_list': []},
        
        //vue location: selection_questionnaire/
        'selection_questionnaire': {"description": '', 'result_type_id_list': []},

        //vue location: spoken_pca/
        'spoken_pca': {"description": '', 'result_type_id_list': []},
        
        //vue location: token_test/
        'token_test': {"description": '', 'result_type_id_list': []},
        
        //vue location: voice_n_speech/
        'basic_module': {"description": '', 'result_type_id_list': []},
        
        //vue location: word_category/
        'word_category': {"description": '', 'result_type_id_list': []},
        
        //vue location: word_finding/
        'word_finding': {"description": '', 'result_type_id_list': []},

        //vue location: write_numbers/
        'write_numbers': {"description": '', 'result_type_id_list': []},

        //vue location: write_words/
        'write_words': {"description": '', 'result_type_id_list': []},

        //vue location: aphasia_position/
        "aphasia_position": {"description": '', 'result_type_id_list': []},
        //* THERE IS scene_position here too */
        
        //vue location: vhi/

        //vue location: word_memory/

        //vue location: story_memory/

        //vue location: scene_description/
      

        /*
        'stepping_stone',
        'simon_says',
        'word_search',
        'number_guessing',
        'word_guessing',
        'maths_table',
        'assessment_questionnaire',
        'single_word',
        
        'scene_description',

        'antonym_exercise',
        'aphasia_position',
        'aphasia_story',
        'speak_background_noise',

        'post_video_module',
        'post_video_module_spectrogram',


        'memory_image',
        'avqi',
        'speak_numbers', 
        */


      }

      return exercise_to_results_map[exercise_type_id]

    }

}


