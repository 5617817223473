<template>

    <div v-if="results['Life Skills']" id="lifeSkillsDiv">
        <correct_incorrect_chart 
        name="Life Skills"
        :correct="results['Life Skills']['Correct']" 
        :incorrect="results['Life Skills']['Incorrect']" 
        />
    </div>

    <div v-if="results['Scene Click']" id="sceneClickDiv">
        <correct_incorrect_chart 
        name="Scene Click"
        :correct="results['Scene Click']['Correct']" 
        :incorrect="results['Scene Click']['Incorrect']" 
        />
    </div>

    <div v-if="results['Scene Description']" id="sceneDescriptionDiv">

        <bar_result 
        name="Answer basic questions"
        :results="results['Scene Description']" 
        :selected_features="{'Correct':{'name':'Correct','color':'#4caf50'},'Correct_hint':{'name':'Correct with hints','color':'#FFA500'},'Incorrect':{'name':'Incorrect','color':'#FF7F7F'}}" 
        />
    </div>

    <div v-if="results['Word Category']" id="wordCategoryDiv">
        <correct_incorrect_chart 
        name="Word Category"
        :correct="results['Word Category']['Correct']" 
        :incorrect="results['Word Category']['Incorrect']" 
        />
    </div>

    <div v-if="results['Money Recognition']" id="moneyRecognitionDiv">
        <correct_incorrect_chart 
        name="Money Recognition"
        :correct="results['Money Recognition']['Correct']" 
        :incorrect="results['Money Recognition']['Incorrect']" 
        />
    </div>

    <div v-if="results['Listen Minimal Pairs']" id="listen_minimal_pairs">
        <correct_incorrect_chart 
        name="Listen Minimal Pairs"
        :correct="results['Listen Minimal Pairs']['Correct']" 
        :incorrect="results['Listen Minimal Pairs']['Incorrect']" 
        />
    </div>

    <div v-if="results['Reversible Passive']" id="reversiblePassiveDiv">
        <correct_incorrect_chart 
        name="Reversible Passive"
        :correct="results['Reversible Passive']['Correct']" 
        :incorrect="results['Reversible Passive']['Incorrect']" 
        />
    </div>
  
    <div v-if="results['Copy Words']" id="copyWordsDiv">
        <correct_incorrect_chart 
        name="Copy Words"
        :correct="results['Copy Words']['Correct']" 
        :incorrect="results['Copy Words']['Incorrect']" 
        />
    </div>
  
    <div v-if="results['Write Words']" id="writeWordsDiv">
        <correct_incorrect_chart 
        name="Write Words"
        :correct="results['Write Words']['Correct']" 
        :incorrect="results['Write Words']['Incorrect']" 
        />
    </div>

    <div v-if="results['Hangman']" id="hangmanDiv">
        <correct_incorrect_chart 
        name="Hangman"
        :correct="results['Hangman']['Correct']" 
        :incorrect="results['Hangman']['Incorrect']" 
        />
    </div>

    
    <div v-if="results['Count in images']" id="countImgDiv">
        <correct_incorrect_chart 
        name="Count in images"
        :correct="results['Count in images']['Correct']" 
        :incorrect="results['Count in images']['Incorrect']" 
        />
    </div>
  
    <div v-if="results['Letter Word Match']" id="letterWordMatchDiv">
        <correct_incorrect_chart 
        name="Letter Word Match"
        :correct="results['Letter Word Match']['Correct']" 
        :incorrect="results['Letter Word Match']['Incorrect']" 
        />
    </div>
    
    <div v-if="results['Read Sentences']" id="orlaDiv">
        <correct_incorrect_chart 
        name="Read Sentences"
        :correct="results['Read Sentences']['Correct']" 
        :incorrect="results['Read Sentences']['Incorrect']" 
        />
    </div>

    <div v-if="results['Common Sayings']" id="commonSayingsDiv">
        <correct_incorrect_chart 
        name="Common Sayings"
        :correct="results['Common Sayings']['Correct']" 
        :incorrect="results['Common Sayings']['Incorrect']" 
        />
    </div>

    <div v-if="results['Read Menu']" id="readMenuDiv">
        <correct_incorrect_chart 
        name="Read Menu"
        :correct="results['Read Menu']['Correct']" 
        :incorrect="results['Read Menu']['Incorrect']" 
        />
    </div>

    <div v-if="results['Reading Comprehension']" id="readMenuDiv">
        <correct_incorrect_chart 
        name="Reading Comprehension"
        :correct="results['Reading Comprehension']['Correct']" 
        :incorrect="results['Reading Comprehension']['Incorrect']" 
        />
    </div>

    <div v-if="results['Memory Games']" id="memoryGamesDiv">
        <correct_incorrect_chart 
        name="Memory Games"
        :correct="results['Memory Games']['Correct']" 
        :incorrect="results['Memory Games']['Incorrect']" 
        />
    </div>

    <div v-if="results['Matching Shapes']" id="matchingShapesDiv">
        <correct_incorrect_chart 
        name="Matching Shapes"
        :correct="results['Matching Shapes']['Correct']" 
        :incorrect="results['Matching Shapes']['Incorrect']" 
        />
    </div>

    <div v-if="results['visual_scanning']" id="visual_scanning">
        <correct_incorrect_chart 
        name="Visual Scanning"
        :correct="results['visual_scanning']['Correct']" 
        :incorrect="results['visual_scanning']['Incorrect']" 
        />
    </div>

    <div v-if="results['Write Numbers']" id="writeNumbersDiv">
        <correct_incorrect_chart 
        name="Write Numbers"
        :correct="results['Write Numbers']['Correct']" 
        :incorrect="results['Write Numbers']['Incorrect']" 
        />
    </div>

    <div v-if="results['Yes No Assessment']" id="yesNoDiv">
        <correct_incorrect_chart 
        name="Yes No Assessment"
        :correct="results['Yes No Assessment']['Correct']" 
        :incorrect="results['Yes No Assessment']['Incorrect']" 
        />
    </div>

    <div v-if="results['Word Naming']" id="wordNamingDiv">
        <correct_incorrect_chart 
        name="Word Naming"
        :correct="results['Word Naming']['Correct']" 
        :incorrect="results['Word Naming']['Incorrect']" 
        />
    </div>

    <div v-if="results['Give Directions']" id="giveDirectionsDiv">
        <correct_incorrect_chart 
        name="Give Directions"
        :correct="results['Give Directions']['Correct']" 
        :incorrect="results['Give Directions']['Incorrect']" 
        />
    </div>

    <div v-if="results['PCA']" id="pcaDiv">
        <correct_incorrect_chart 
        name="PCA"
        :correct="results['PCA']['Correct']" 
        :incorrect="results['PCA']['Incorrect']" 
        />
    </div>

    <div v-if="results['Spoken PCA']" id="spokenPcaDiv">
        <correct_incorrect_chart 
        name="Spoken PCA"
        :correct="results['Spoken PCA']['Correct']" 
        :incorrect="results['Spoken PCA']['Incorrect']" 
        />
    </div>








    <div v-if="results['Token Assessment']" style="display:flex;position:relative;align-items:center;justify-content:center;margin-top:20px">
        <div class="card card-body mt-2">
          <span style="display:flex;justify-content:center;font-size:22px"> Token assessment score:</span>
          <div class="row mt-2 text-center">
            <span class="custom-text" style="color:green;"> Correct answers: {{results['Token Assessment']['Correct']}}</span>
          </div>
          <div class="row mb-2 text-center">
            <span class="custom-text" style="color:red;"> Incorrect answers: {{results['Token Assessment']['Incorrect']}}</span>
          </div>
          <div class="row text-center" v-show="userCategory!=='slp'">
            <span class="mb-2" style="display:flex;justify-content:center;font-size:22px;" > Your best personal score is: </span>
            <span style="justify-content:center;font-size:18px;white-space: pre-wrap"> {{results['Token Assessment']['top']}} </span>
          </div>
          <div class="row text-center">
            <span style="color:#0000FF;"> {{results['Token Assessment']['best_message']}} </span>
          </div>
        </div>
      </div>

      <div v-if="results['Pub Quiz']" style="display:flex;position:relative;align-items:center;justify-content:center;margin-top:20px">
        <div class="card card-body text-center mt-2">
            <span style="display:flex;justify-content:center;font-size:22px"> Quiz score:</span>
            <div class="row">
                <span class="custom-text" style="color:green;"> Correct answers: {{results['Pub Quiz']['Correct']}}</span>
            </div>
            <div class="row">
                <span class="custom-text" style="color:red;"> Incorrect answers: {{results['Pub Quiz']['Incorrect']}}</span>
            </div>
            <div v-show="userCategory!=='slp'">
                <div class="row">
                    <span style="display:flex;justify-content:center;font-size:22px;"> Your best personal score is: </span>
                    <span style="justify-content:center;font-size:22px;white-space: pre-wrap"> {{results['Pub Quiz']['top']}} </span>
                </div>
                <div class="row">
                    <span style="color:#0000FF;"> {{results['Pub Quiz']['best_message']}} </span>
                </div>
            </div>
        </div>
      </div>

      <div v-if="results['Brain Games']" class="row mt-4 justify-content-center">
        <div class="col-6">
          <div class="card card-body text-center">
            <span style="font-size:24px"> Brain game score:</span>
            <span style="color:green;font-size:28px"> {{results['Brain Games']['Correct']}} correct answers</span>
            <span style="color:red;font-size:18px"> {{results['Brain Games']['Incorrect']}} incorrect answers</span>
            <br>
            <div v-show="userCategory!=='slp'">
                <span style="font-size:22px"> Your best personal score is: </span>
                <span style="font-size:24px;white-space: pre-wrap"> {{results['Brain Games']['top']}} <b style="color:#0000FF"> {{results['Brain Games']['best_message']}} </b>  </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="results['Position Games']" class="row mt-4 justify-content-center">
        <div class="col-6">
          <div class="card card-body text-center">
            <span style="font-size:24px"> Position game score:</span>
            <span style="color:green;font-size:28px"> {{results['Position Games']['Correct']}} correct answers</span>
            <span style="color:red;font-size:18px;white-space:pre-wrap"> {{results['Position Games']['Incorrect']}} incorrect answers</span>
            <br>
            <div v-show="userCategory!=='slp'">
                <span style="font-size:22px" > Your best personal score is: </span>
                <span style="font-size:24px;white-space:pre-wrap"> {{results['Position Games']['top']}} <b style="color:#0000FF"> {{results['Position Games']['best_message']}} </b> </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="results['Reading Images']" class="row mt-4 justify-content-center">
        <div class="col-6">
          <div class="card card-body text-center">
            <span style="font-size:24px"> Match a sentence to an image score:</span>

            <span style="color:green;font-size:28px"> {{results['Reading Images']['Correct']}} correct answers</span>
            <span style="color:red;font-size:18px"> {{results['Reading Images']['Incorrect']}} incorrect answers</span>

            <br>
            <div v-show="userCategory!=='slp'">
                <span style="font-size:22px"> Your best personal score is: </span>
                <span style="font-size:24px;white-space:pre-wrap"> {{results['Reading Images']['top']}} <b style="color:#0000FF"> {{results['Reading Images']['best_message']}} </b> </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="results['Word Search']" class="row mt-4 justify-content-center">
        <div class="col-6">
          <div class="card card-body text-center">
            <span style="font-size:24px"> Word search score:</span>

            <span style="color:green;font-size:28px"> {{results['Word Search']['Correct']}} correct answers</span>
            <span style="color:red;font-size:18px"> {{results['Word Search']['Incorrect']}} incorrect answers</span>

            <br>
            <div v-show="userCategory!=='slp'">
                <span style="font-size:22px"> Your best personal score is: </span>
                <span style="font-size:24px;white-space:pre-wrap"> {{results['Word Search']['top']}} <b style="color:#0000FF"> {{results['Word Search']['best_message']}} </b> </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="results['Antonym Exercise']" class="row mt-4 justify-content-center">
        <div class="col-6">
          <div class="card card-body text-center">
            <span style="font-size:24px"> Antonym game score:</span>
            <span style="color:green;font-size:28px"> {{results['Antonym Exercise']['Correct']}} correct answers</span>
            <span style="color:red;font-size:18px"> {{results['Antonym Exercise']['Incorrect']}} incorrect answers</span>

            <br>
            <div v-show="userCategory!=='slp'">
                <span style="font-size:22px"> Your best personal score is: </span>
                <span style="font-size:24px;white-space:pre-wrap"> {{results['Antonym Exercise']['top']}} <b style="color:#0000FF"> {{results['Antonym Exercise']['best_message']}} </b> </span>
            </div>
          </div>
        </div>
      </div>

      
</template>
<script>

import correct_incorrect_chart from '@/views//session/Result/type/correct_incorrect_chart.vue'
import bar_result from '@/views//session/Result/type/bar_result.vue'

export default {
  name: 'correct_incorrect_section',
  components: {
    correct_incorrect_chart,
    bar_result
  },
  props: ['results'],
  setup(props) {
    return {

    }
  }
}
</script>
<style>
  canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  #chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #chartjs-radar{
    width : 60%;
    height: 60%;
  }
  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
  </style>