<template>
  <div>
    <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='AutoSpeech';openModal('language_semantic_modal')">
    <div class="row">
      <div class="col-3" style="padding: 0px 0px 0px 10px;">
        <img :src="Language_semantic" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="background-color: orange;width: 40px; height: 40px;">
      </div>
      <div class="col-9 d-flex align-items-center">
        <strong>Expressive Semantics</strong>
      </div>
    </div>
  </div>
  <div class="modal fade" id="language_semantic_modal" tabindex="-1" aria-labelledby="language_semantic_modal" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Expressive Semantics</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('language_semantic_modal')"></button>
        </div>
        <div class="modal-body">
          <div class="row" style="height:50vh">
            <div class="col-4 border-3 border-success border-end">
              <button :class="['btn','mb-2', 'w-100', selected_module == 'AutoSpeech'?'btn-primary':'btn-light']" type="button" @click="selected_module='AutoSpeech'">
                Spontaneous naming
              </button> 
              <button :class="['btn','mb-2', 'w-100', selected_module == 'WordFinding'?'btn-primary':'btn-light']" type="button" @click="selected_module='WordFinding'">
                Semantic fluency
              </button> 
              <!-- <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div> -->
              <button :class="['btn','mb-2', 'w-100', selected_module == 'CommonSayings'?'btn-primary':'btn-light']" type="button" @click="selected_module='CommonSayings'">
                Common sayings
              </button> 
     
              <button :class="['btn','mb-2', 'w-100', selected_module == 'WordCategory'?'btn-primary':'btn-light']" type="button" @click="selected_module='WordCategory'">
                Word category
              </button> 

              <button :class="['btn','mb-2', 'w-100', selected_module == 'PictureNaming'?'btn-primary':'btn-light']" type="button" @click="selected_module='PictureNaming'">
                Confrontational picture naming
              </button>
              <div class="position-relative">
                <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
              </div>
              <button :class="['btn','mb-2', 'w-100', selected_module == 'Count_in_images'?'btn-primary':'btn-light']" type="button" @click="selected_module='Count_in_images'">
                Count in images
              </button> 
              <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'PictureDescriptionLanguage' || selected_module == 'PictureDescriptionLanguageSimple' ?'btn-primary':'btn-light']" type="button" @click="selected_module='PictureDescriptionLanguage'">
                  Picture description (non-scripted talking) - with transcription results 
                </button>
            </div>
            <div class="col-8">
              <div v-show="selected_module == 'AutoSpeech'">
                <p class="lead">Practise basic functional divergent naming</p>
                <p> Days of the week &emsp;  Months of the year &emsp; Counting to 10</p>
              </div>
              <div v-show="selected_module == 'WordFinding'">
                <p class="lead">Practise divergent naming</p>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Choose difficulty</label>
                  <div class="col-6">
                    <select class="form-select form-select" v-model="module_configuration['difficulty']">
                      <option value="Easy">Easy</option>
                      <!-- <option value="Medium">Medium</option> -->
                    </select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                      options: { $el: 'div' },
                      option: { $el: 'div' },
                      }"
                      messages-class="text-danger"
                      validation="required|min:1|max:15"/>
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'CommonSayings'">
                <p class="lead">Practise spontaneous recall with common sayings</p>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                      options: { $el: 'div' },
                      option: { $el: 'div' },
                      }"
                      messages-class="text-danger"
                      validation="required|min:1|max:15"/>
                  </div>
                </div>
                <div v-if="current_difficulty['current_level']" class="row mt-4">
                  <div class="col-6">
                    <label>Patient current level:</label>
                  </div>
                  <div class="col-6 text-start">
                    <p>Level {{ current_difficulty['current_level'] }} </p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-6">
                    <label>Select difficulty level:</label>
                  </div>
                  <div class="col-6">
                    <select class="form-select" v-model="difficulty">
                      <option v-for="level in 4" :key="level" :value="level">Level {{ level }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-4">
                    <p class="mt-3">{{ difficulty_description['CommonSayings'][difficulty-1] }}</p>
                  </div>
                <div class="row">
                  <img :src="getImageSrc(difficulty)" />
                </div>
              </div>
              <div v-show="selected_module == 'WordCategory'">
                <p class="lead">Practise identification of semantic features</p>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="topic">Choose type</label>
                  <div class="col-6">
                    <select class="form-select form-select" v-model="module_configuration['word_category_type']">
                      <option value="all">A mix of all types</option>
                      <option value="find">Find images that fit a category</option>
                      <option value="add">Add images to a category</option>
                      <option value="exclude">Exclude images that don't fit</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'PictureNaming'">
                <p class="lead">Practise confrontation naming</p>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-show="selected_module == 'Count_in_images'">
                <p class="lead">Practise counting items on an image</p>
                <div class="row">
                  <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                  <div class="col-6">
                    <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                    :sections-schema="{
                        messages: { $el: 'div' },
                        message: { $el: 'span' },
                        options: { $el: 'div' },
                        option: { $el: 'div' },
                        }"
                      messages-class="text-danger"
                      validation-visibility="dirty"
                      validation="required|min:1|max:15" />
                  </div>
                </div>
              </div>
              <div v-if="selected_module == 'PictureDescriptionLanguage' || selected_module == 'PictureDescriptionLanguageSimple'">
                  <p class="lead">Picture description (non-scripted talking) - with transcription</p>
                  <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 0%;">Loudness</span>

                      <span class="badge rounded-pill" style="background-color: darkcyan; font-size:medium; margin-left: 5%">Transcription</span>
                    </div>
                </div>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="nexercises">Difficulty level</label>

                <div class="col-6">
                      <select class="form-select form-select" v-model="selected_module">
                        <option value="PictureDescriptionLanguageSimple">Simple pictures</option>

                        <option value="PictureDescriptionLanguage">Complex pictures</option>
                       
                      </select>
                    </div>
                </div>
                  <div class="row" >
                    
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="3" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
          <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
        </div>
      </div>
    </div>
    </div> 
  </div>
</template>
<script>

import Language_semantic from '@/assets/images/dashboard/slt/training/Language_semantic.svg'
import { updateDataDirectory, getDataDirectory } from '@/firebase/index'
import { Modal } from 'bootstrap'


export default {
  name: 'semantic',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      selected_module: '',
      difficulty: 1,
      current_difficulty: 1,
      local_number_exercises: 5,
      Language_semantic: Language_semantic, 
      modal_references: {},
      module_configuration: {
        difficulty: "Easy",
        word_category_type: 'all',
      },

      difficulty_description: {
        'CommonSayings': [
          "Level 1 - Read common phrases together, then fill in missing word",
          "Level 2 - Fill in the missing word of common phrases",
          "Level 3 - Read longer phrases together, then fill in missing word",
          "Level 4 - Fill in the missing word of longer common phrases"

        ]
      }
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.get_current_difficulty()
      this.difficulty = 1
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "language_semantic_modal": new Modal(document.getElementById('language_semantic_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    getImageSrc(num) {
      let url = null
      if (this.selected_module === 'CommonSayings') {
        url = new URL(`../../../../../../../../../assets/images/dashboard/slt/training/difficulty/common_sayings/Common Sayings - ${num}.png`, import.meta.url)
      }

      return url
    },
    get_level_address() {

      let userCategory = localStorage.getItem('userCategory');

      let uid = localStorage.getItem('uid');
      let uuid = uid

      if(userCategory === 'slp') {
        uuid = localStorage.getItem('client_uid');
      }

      let base_level_address = ''
      if (this.selected_module === 'CommonSayings') {
        base_level_address = 'Common_sayings'
      }

      let level_address = '/Users/' + uuid + '/Levels/' + base_level_address

      return level_address
    },
    get_current_difficulty() {
      let _this = this
      if (this.selected_module) {
        let level_address = this.get_level_address()
        
        getDataDirectory(level_address).then(value => {

          if (value.val() && 'current_level' in value.val()) {
            _this.current_difficulty = value.val()
          }
          
        })
      }

    },
    set_difficulty() {
      let level_address = this.get_level_address()

      updateDataDirectory(level_address, {
          current_level: this.difficulty,  
          level_up_counter: 0
        }
      )
    },
    emit_start_training() {
      this.set_difficulty()
      this.closeModal('language_semantic_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.set_difficulty()
      this.closeModal('language_semantic_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      this.reset()
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset() {
      this.module_configuration = {
        difficulty: "Easy",
        word_category_type: 'all',
      }
    },

  }
}
</script>