<template>
  <div v-if="mic_permission_allowed === 'false'">
    <MicPermission @response="(rtn) => mic_permission_allowed = rtn" />
    </div>
  <div v-else>

    <Navbar :title="title" :enableBack=true></Navbar>
    <div class="d-flex flex-column min-vh-100">

      <div class="container-fluid text-center">

        <div v-if="!showResults" style="width:100%;height:100%;margin-top:0%">      
          <component :is="dynamicComponent" v-if="dynamicComponent && current_exercise_type" 
            :exForm="exForm"
              
            @logExerciseResults="logExerciseResults($event)"
            @goToResults="goToResults($event)"
            @nextExercise="next()"
          />
        </div>
        <div v-else style="width:100%;height:100%;margin-top:0%">
          <Results
            :more_exercises_available="more_exercises_available" 
            :resultsTable="results_table"
            @discardResults="discardResults()"
            @nextExercise="next()" />
        </div>
      </div>
      <Footerbar class="mt-auto"></Footerbar>
    </div>
  </div>
</template>
<script>

import {get_microphone_permission} from '@/media_input/mic/permission.js'
import MicPermission from '@/views/components/mic_permission/MicPermission.vue'
import {deleteSoundMeter } from '@/media_input/microphone_recording'
import {generate_results_path} from '@/utils/results/data_save_utils'

import Results from './End_results.vue'
import {Session} from '@/utils/session/session.js'

import Footerbar from '@/views/Footerbar.vue'
import Navbar from '@/views/Navbar.vue'


import word_guessing from '../../dashboard/patient/daily_puzzles/word_guessing/word_guessing.vue';
import stepping_stone from '../../dashboard/patient/daily_puzzles/stepping_stone/stepping_stone.vue';
import maths_table from '../../dashboard/patient/daily_puzzles/maths_table/maths_table.vue';
import number_guessing from '../../dashboard/patient/daily_puzzles/number_guessing/number_guessing.vue';
import simon_says from '../../dashboard/patient/daily_puzzles/simon_says/simon_says.vue';
import word_search from '../../dashboard/patient/daily_puzzles/word_search/word_search.vue';


import dysphagia from './exercise_type/dysphagia/dysphagia.vue';

import count_in_images from './exercise_type/count_in_images/count_in_images.vue';

import scene_description from './exercise_type/scene_description/scene_description.vue';
import chat_scenario from './exercise_type/chat_scenario/chat_scenario.vue';
import swal_qol from './exercise_type/swal_qol/swal_qol.vue';
import fda from './exercise_type/fda/fda.vue';


import selection_scales from './exercise_type/selection_questionnaire/selection_scales.vue';
import multipage_questionnaire from './exercise_type/selection_questionnaire/multipage_questionnaire.vue';


import selection_questionnaire from './exercise_type/selection_questionnaire/selection_questionnaire.vue';
import reverse_direction from './exercise_type/reverse_direction/reverseDirection.vue';
import reversible_passive from './exercise_type/reversible_passive/reversible_passive.vue';
import orla from './exercise_type/orla/orla.vue';
import common_sayings from './exercise_type/common_sayings/common_sayings.vue';
import read_menu from './exercise_type/reading_menu/reading_menu.vue';
import word_category from './exercise_type/word_category/word_category.vue';

import quiz from './exercise_type/quiz/quizGame.vue';
import assessment_questionnaire from './exercise_type/vhi/assessment_questionnaire.vue';
import scene_click from './exercise_type/scene_click/scene_click.vue';
import pace_training from './exercise_type/pace_training/pace_training.vue';
import number_sequence from './exercise_type/number_sequence/number_sequence.vue';
import copy_word from './exercise_type/copy_word/copy_word.vue';
import copy_word_speak from './exercise_type/copy_word/copy_word_speak.vue';
import antonym_exercise from './exercise_type/antonym/antonym.vue';
import letter_word_match from './exercise_type/letter_word_match/letter_word_match.vue';
import minimal_pairs from './exercise_type/minimal_pairs/minimal_pairs.vue';
import listen_minimal_pairs from './exercise_type/listen_minimal_pairs/listen_minimal_pairs.vue';
import aphasia_position from './exercise_type/aphasia_position/scene_position.vue';
import aphasia_story from './exercise_type/story_memory/story_memory.vue';
import reading_comprehension from './exercise_type/reading_comprehension/reading_comprehension.vue';
import hangman from './exercise_type/hangman/hangman.vue';
import write_words from './exercise_type/write_words/write_words.vue';
import spoken_pca from './exercise_type/spoken_pca/spoken_pca.vue';
import pca from './exercise_type/pca/pca.vue';
import name_a_picture_easy from './exercise_type/name_a_picture/name_a_picture_easy.vue';
import give_directions from './exercise_type/give_directions/give_directions.vue';

import picture_description from './exercise_type/picture_description/picture_description.vue';
import yes_no from './exercise_type/aphasia_yes_no/aphasia_yes_no.vue';
import reading_images from './exercise_type/reading_images/reading_images.vue';

import cats_image_selection from './exercise_type/cats_image_selection/cats_image_selection.vue';
import memory_image from './exercise_type/word_memory/word_memory.vue';
import matching_shapes from './exercise_type/matching_shapes/matching_shapes.vue';
import word_finding from './exercise_type/word_finding/word_finding_js.vue';
import clock_game from './exercise_type/clock_game/clock_game.vue';
import money_recognition from './exercise_type/money_recognition/money_recognition.vue';
import write_numbers from './exercise_type/write_numbers/write_numbers.vue';
import karaoke from './exercise_type/karaoke/karaoke.vue';
import token_test from './exercise_type/token_test/token_test.vue';
import ddk_rate from './exercise_type/ddk_rate/ddk_rate.vue';
import basic_module from './exercise_type/voice_n_speech/multi_word.vue';
import speak_numbers from './exercise_type/voice_n_speech/multi_word.vue';
import single_word from './exercise_type/voice_n_speech/multi_word_auto.vue';
import speak_background_noise from './exercise_type/voice_n_speech/speak_background_noise.vue';
import cookie_theft from './exercise_type/cookie_theft/cookie_theft.vue';
import avqi from './exercise_type/voice_n_speech/avqi.vue';
import visual_scanning from './exercise_type/visual_scanning/visual_scanning.vue';

export default {
  name: 'Session',
  props: {
    requested: String,
    blueprint_id: String,
    blueprint_path: String
  },

  components: {
    Footerbar,
    Navbar,
    MicPermission, 
    Results,
    dysphagia,
    word_guessing,
    stepping_stone,
    maths_table,
    number_guessing,
    simon_says,
    word_search,
    scene_description,
    chat_scenario,
    count_in_images,
    swal_qol,
    fda,
    selection_questionnaire,
    selection_scales,
    multipage_questionnaire,

    reverse_direction,
    reversible_passive,
    orla,
    common_sayings,
    read_menu,
    word_category,
    quiz,
    assessment_questionnaire,
    scene_click,
    pace_training,
    number_sequence,
    copy_word,
    copy_word_speak,
    antonym_exercise,
    letter_word_match,
    minimal_pairs,
    listen_minimal_pairs,
    aphasia_position,
    aphasia_story,
    reading_comprehension,
    hangman,
    write_words,
    spoken_pca,
    pca,
    name_a_picture_easy,
    give_directions,
    picture_description,
    yes_no,
    reading_images,
    cats_image_selection,
    memory_image,
    matching_shapes,
    word_finding,
    clock_game,
    money_recognition,
    write_numbers,
    karaoke,
    token_test,
    ddk_rate,
    basic_module,
    speak_numbers,
    single_word,
    speak_background_noise,
    cookie_theft,
    avqi,
    visual_scanning
  },
  data() {
    return { 
      mic_permission_allowed: false,
      userCategory: localStorage.getItem('userCategory'),

      session: null,

      exForm: {},

      more_exercises_available: false,
      results_table: {back:[], pre:[]},

      current_exercise_type: null,

      title: '',
      showResults: false,
      dynamicComponent: null,
    }
  },
  async created() {
    window.scrollTo(0, 0);

    await get_microphone_permission(this)
    
    this.session = new Session(this.requested, this.blueprint_id, this.blueprint_path, this)
    await this.session.initialise()
    this.title = this.session.title
  
    this.exForm = this.session.getExForm()
    this.current_exercise_type = this.session.getCurrentExerciseType()

    this.loadDynamicComponent();
  },
  mounted() {

  },
  beforeUnmount()
  {
    deleteSoundMeter()
  },
  methods: {

    async loadDynamicComponent() {
      if (this.current_exercise_type) {

        this.dynamicComponent = this.current_exercise_type
      } else {
        this.dynamicComponent = null;
      }
    },
    
    watch: {
      current_exercise_type(newVal) {
        this.loadDynamicComponent();
      }
    },
    
    async next() {
      let _this = this
      
      _this.showResults = false
      _this.current_exercise_type = null

      await this.session.next()
      _this.exForm = _this.session.getExForm()
      _this.current_exercise_type = _this.session.getCurrentExerciseType()
      _this.loadDynamicComponent();
      _this.title = _this.session.title

      if (_this.session.getShowResults()) {
        //forced to show the results
        let rt = _this.session.getResults()
        
        _this.more_exercises_available = rt['more_exercises_available']
        _this.results_table = rt['results_table']

        _this.showResults = true;
      }

    },

    async goToResults(goToResultsData)
    {
      let _this = this
      
      _this.showResults = false

      let rt = await _this.session.goToResults(goToResultsData)

      _this.more_exercises_available = rt['more_exercises_available']
      _this.results_table = rt['results_table']

      _this.showResults = true;
    },

    discardResults()
    {
      let _this = this
      let paths = generate_results_path(this.exForm)

      let module_path = paths['modules_results_path']

      let core_directory_handle =  this.$firebase.database().ref(module_path) 

      console.error(core_directory_handle)
      core_directory_handle.remove().then(function() {
        console.debug("Removal succeeded!")
        
        let clientUID = localStorage.getItem("client_uid")
        _this.$router.push({ name: 'client_interface', query: {uid: clientUID} });

        alert("Results have been discarded.")
      })
      .catch(function(error) {
        console.error("Remove failed: " + error.message)
      });


    },
    
    logExerciseResults(data)
    {
      this.session.logExerciseResults(data)
      this.exForm = this.session.getExForm()
    },
  },
  
}
</script>
<style scoped>

</style>