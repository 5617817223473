<template>
<div>
  <div class="card card-body mb-4">
    <div class="mb-4">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <button 
            :class="['nav-link', active_tab === 'assessment_package' ? 'active': '']"
            @click="active_tab = 'assessment_package'">
            <h6>Packages</h6>
          </button>
        </li>
        <li class="nav-item">
          <button :class="['nav-link', active_tab ===  'voice' ? 'active': '']"
            @click="active_tab = 'voice'">
            <h6 class="text-start"> <img :src="Voice_assessment" style="height: 30px;width: 30px;filter: invert(1);"/> Voice</h6>
          </button>
        </li>
        <li class="nav-item">
          <button :class="['nav-link', active_tab ===  'speech' ? 'active': '']"
            @click="active_tab = 'speech'">
            <h6 class="text-start"><img :src="Speech_assessment" style="height: 30px;width: 30px;filter: invert(1);"/> Speech</h6>
          </button>
        </li>
        <li class="nav-item">
          <button :class="['nav-link', active_tab ===  'language' ? 'active': '']"
            @click="active_tab = 'language'">
            <h6 class="text-start"><img :src="Language_assessment" style="height: 30px;width: 30px;filter: invert(1);"/> Language</h6>
          </button>
        </li>
        <li class="nav-item">
          <button :class="['nav-link', active_tab ===  'dysphagia' ? 'active': '']"
            @click="active_tab = 'dysphagia'">
            <h6 class="text-start"><img :src="Dysphagia_assessment" style="height: 30px;width: 30px;filter: invert(1);"/> Dysphagia</h6>
          </button>
        </li>
        <li class="nav-item">
          <button :class="['nav-link', active_tab ===  'interactive_session' ? 'active': '']"
            @click="active_tab = 'interactive_session'">
            <h6 class="text-start"><img :src="Interactive_assessment" style="height: 30px;width: 30px;filter: invert(1);"/> Interactive</h6>
          </button>
        </li>
      </ul>
    </div>
    <div class="mb-4">
      <div v-if="active_tab == 'assessment_package'">
        <div class="row mb-2">
          <div class="col-md-4">
            <VTooltip>
              <!--<div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#general_questionnaire_modal">-->
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:not-allowed">
                <img :src="package_general" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: #0dcaf0;"><strong class="fw-light"><s>Communication Questionnaire</s></strong>
              </div>
              <template #popper>
                Please contact Beautiful Voice on contact@beautifulvoice.co.uk to access this assessment... 
              </template>
            </VTooltip>
            <div class="modal fade" id="general_questionnaire_modal" tabindex="-1" aria-labelledby="general_questionnaire_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">General  questionnaire</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body text-start">
                    <div class="row">
                      <p>This is an assessment package containing a brief questionnaire. You will receive an email once the assessment is concluded. You can exclude an question by clicking the check box.</p>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(true, 'general_questionnaire')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_assessment('general_questionnaire')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <VTooltip>
              <!--<div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#aphasia_modal">-->
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:not-allowed">
                <img :src="package_aphasia" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: #0dcaf0;"><strong class="fw-light"><s>Aphasia</s></strong>
              </div>
              <template #popper>
                Please contact Beautiful Voice on contact@beautifulvoice.co.uk to access this assessment... 
              </template>
            </VTooltip>
            <div class="modal fade" id="aphasia_modal" tabindex="-1" aria-labelledby="aphasia_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Aphasia</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body text-start">
                    <div class="row">
                      <p>This is an assessment package for aphasia. You will receive an email once the assessment is concluded.
                      This package contains the following exercises. You can exclude an exercise by clicking the check box.
                      </p>
                    </div>
                    <div class="row text-center" style="height:20vh">
                      <h1>TBD</h1>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>-->
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(true, 'aphasia')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_assessment('aphasia')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <VTooltip>
              <!--<div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#dysarthria_modal">-->
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:not-allowed">
                <img :src="package_dysarthria" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: #0dcaf0;"><strong class="fw-light"><s>Dysarthria</s></strong>
              </div>
            <template #popper>
              Please contact Beautiful Voice on contact@beautifulvoice.co.uk to access this assessment... 
              </template>
            </VTooltip>
            <div class="modal fade" id="dysarthria_modal" tabindex="-1" aria-labelledby="dysarthria_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Dysarthria</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body text-start">
                    <div class="row">
                      <p> This is an assessment package for dysarthria. You will receive an email once the assessment is concluded.
                          This package contains the following exercises. You can exclude an exercise by clicking the check box.
                      </p>
                    </div>
                    <div class="row" style="height:45vh">
                      <FormKit
                        v-model="dysarthria_selected_options"
                        
                        :options="[{
                          'value': 'vhi',
                          'label': 'Voice Handicap Index (VHI)',
                          'label-class': 'd-inline',
                          'input-class': 'form-check-input',
                          'element-class': 'd-inline mb-4',
                          'wrapper-class': 'm-3',
                          'decorator-class': 'd-inline'
                        },
                        {
                          'value': 'phonation',
                          'label': 'Maximum Phonation',
                          'label-class': 'd-inline',
                          'input-class': 'form-check-input',
                          'element-class': 'd-inline mb-4',
                          'wrapper-class': 'm-3',
                          'decorator-class': 'd-inline'
                        },
                        {
                          'value': 'pitch',
                          'label': 'Pitch Variation',
                          'label-class': 'd-inline',
                          'input-class': 'form-check-input',
                          'element-class': 'd-inline mb-4',
                          'wrapper-class': 'm-3',
                          'decorator-class': 'd-inline'
                        },
                        {
                          'value': 'grandfather',
                          'label': 'Grandfather Passage',
                          'label-class': 'd-inline',
                          'input-class': 'form-check-input',
                          'element-class': 'd-inline mb-4',
                          'wrapper-class': 'm-3',
                          'decorator-class': 'd-inline'
                        },
                        {
                          'value': 'hobbies',
                          'label': 'Hobbies',
                          'label-class': 'd-inline',
                          'input-class': 'form-check-input',
                          'element-class': 'd-inline mb-4',
                          'wrapper-class': 'm-3',
                          'decorator-class': 'd-inline'
                        }]"
                        type="checkbox"
                        label="Modules:"
                        validation-name="Module"

                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                      <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>-->
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(true, 'dysathria')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_assessment('dysathria')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2 mb-4">
          <div class="col-md-4">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#parkinson_modal">
              <img :src="package_parkinson" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: #0dcaf0;"><strong>Parkinson's Assessment</strong>
            </div>
            <div class="modal fade" id="parkinson_modal" tabindex="-1" aria-labelledby="parkinson_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Parkinson's Assessment</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body text-start">
                    <div class="row">
                      <p>
                        This is an assessment package for Parkinson's. You will receive an email notification once the assessment is concluded by the client. This package contains the following exercises. You can exclude an exercise by unchecking the box.
                      </p>

                    </div>
                    
                    <div class="row" style="height:50vh">
                      
                      <FormKit
                        v-model="parkinson_selected_options"
                        label-class= "form-check-label ms-1"
                        element-class= "d-inline"
                        wrapper-class= "m-2"
                        input-class= "form-check-input"
                        decorator-class= "d-inline"
                        option-class= "list-group"
                        options-class= "list-group"
                        :options=parkinson_options

                        type="checkbox"
                        label="Modules:"
                        validation-name="Module"
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                      <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>-->
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(true, 'parkinson')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_assessment('parkinson')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-if="has_active_assessment_package">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer; background-color: greenyellow;" data-bs-toggle="modal" data-bs-target="#resume_package_assessment_modal">
              <img :src="package_parkinson" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: #0dcaf0;"><strong>Resume Assessment</strong>
            </div>
            <div class="modal fade" id="resume_package_assessment_modal" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Resume Assessment</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body text-start">                        
                    <div class="row" style="height:50vh">
                      <div class="">
                        <div 
                          class="fs-5" 
                          v-for="(option, index) in parkinson_options" 
                          :key="index"
                          style="cursor: default;opacity: 0.6;"
                        >
                          <i v-if="active_package_assessment_modules.some(module => module.value === option.value && module.has_finished)" class="bi bi-check-square-fill fs-4" style="color:green"></i>
                          <i v-else-if="active_package_assessment_modules.some(module => module.value === option.value && module.in_progress)" class="bi bi-pencil-square fs-4" style="color:goldenrod"></i>
                          <i v-else-if="active_package_assessment_modules.some(module => module.value === option.value && !module.has_finished)" class="bi bi-caret-right-square-fill fs-4" style="color: red"></i>
                        
                          <label v-if="active_package_assessment_modules.some(module => module.value === option.value)" :for="`option${index}`" class="form-check-label ms-2" style="font-size: inherit;">
                            {{ option.label }}
                          </label>
                        </div>
                      </div>
                      <div>
                        <hr/>
                        <!--<i class="bi bi-square"> Not part of the assessement</i>-->
                        <br/>
                        <i class="bi bi-check-square-fill" style="color:green"> Completed</i>
                        <br/>
                        <i class="bi bi-pencil-square" style="color: darkgoldenrod"> In-progress</i>
                        <br/>
                        <i class="bi bi-caret-right-square-fill" style="color: red"> Not yet assessed</i>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="$router.push({'name': 'resume_clinic_assessment_package'})">Resume assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="active_tab == 'voice'">
        <div class="row mb-4">
          <div class="row mt-2 justify-content-around">
            <div class="col-md-4">
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#phoneme_modal" @click="selected_module='phonation'">
                <img :src="voice_phonation" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong class="d-inline">Phonation</strong>
              </div>
              <div class="modal fade" id="phoneme_modal" tabindex="-1" aria-labelledby="phoneme_modal" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Phonation Module</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">


                      <div class="row" style="height:50vh">
                        <div class="col-4 border-3 border-success border-end">
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'phonation'?'btn-primary':'btn-light']" type="button" @click="selected_module='phonation'">
                            Phonation
                          </button>
                        
                        </div>

                      <div class="col-8">

                  <div v-show="selected_module=='phonation'">
                    <p class="lead">Measure phonation</p>

                    <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: darkred; font-size:medium; margin-left: 0%;">Phonation duration</span>
                      <span class="badge rounded-pill" style="background-color: darkkhaki; font-size:medium; margin-left: 5%;">Frequency analysis</span>
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 5%;">Loudness</span>
                    </div>
                </div>

                    <div class="row">
                              <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                              <div class="col-6">
                                <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="number_exercises" 
                                  :sections-schema="{
                                    messages: { $el: 'div' },
                                    message: { $el: 'span' },
                                    options: { $el: 'div' },
                                    option: { $el: 'div' },
                                    }"
                                  messages-class="text-danger"
                                  validation-visibility="dirty" 
                                  validation="required|min:1|max:15" />
                              </div>
                            </div>
                            


                    </div>
                    </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'phonation')" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment('phonation')" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#pitch_modal" @click="selected_module='Pitch_Normal_2_High'">
                <img :src="voice_pitch" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Pitch Variation</strong>
              </div>
              <div class="modal fade" id="pitch_modal" tabindex="-1" aria-labelledby="pitch_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Pitch Variation Module</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                      <div class="row" style="height:50vh">
                        <div class="col-4 border-3 border-success border-end">
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'Pitch_Normal_2_High'?'btn-primary':'btn-light']" type="button" @click="selected_module='Pitch_Normal_2_High'">
                            Pitch normal to high
                          </button>
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'Pitch_Normal_2_Low'?'btn-primary':'btn-light']" type="button" @click="selected_module='Pitch_Normal_2_Low'">
                            Pitch normal to low
                          </button>
                        </div>

                        <div class="col-8">
                          <div v-show="selected_module == 'Pitch_Normal_2_High'">
                            <p class="lead">Visualise and practise varying pitch from normal to high pitch</p>

                            <div class="row mb-4 justify-content-center">
                              <div class="col-8 text-center">
                                <span class="badge rounded-pill" style="background-color: darkkhaki; font-size:medium; margin-left: 0%;">Frequency analysis</span>
                                <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 5%;">Loudness</span>
                              </div>
                          </div>


                            <div class="row">
                              <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                              <div class="col-6">
                                <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="number_exercises" 
                                  :sections-schema="{
                                    messages: { $el: 'div' },
                                    message: { $el: 'span' },
                                    options: { $el: 'div' },
                                    option: { $el: 'div' },
                                    }"
                                  messages-class="text-danger"
                                  validation-visibility="dirty" 
                                  validation="required|min:1|max:15" />
                              </div>
                            </div>
                          </div>
                          <div v-show="selected_module == 'Pitch_Normal_2_Low'">
                            <p class="lead">Visualise and practise varying pitch from normal to low pitch</p>
                            <div class="row mb-4 justify-content-center">
                              <div class="col-8 text-center">
                                <span class="badge rounded-pill" style="background-color: darkkhaki; font-size:medium; margin-left: 0%;">Frequency analysis</span>
                                <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 5%;">Loudness</span>
                              </div>
                          </div>
                            <div class="row">
                              <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                              <div class="col-6">
                                <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="number_exercises" 
                                  :sections-schema="{
                                    messages: { $el: 'div' },
                                    message: { $el: 'span' },
                                    options: { $el: 'div' },
                                    option: { $el: 'div' },
                                    }"
                                  messages-class="text-danger"
                                  validation-visibility="dirty" 
                                  validation="required|min:1|max:15" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, selected_module)" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment(selected_module)" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#vhi_modal" @click="selected_module='short_vhi'">
                <img :src="voice_VHI" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Voice Handicap Index </strong>
              </div>
              <div class="modal fade" id="vhi_modal" tabindex="-1" aria-labelledby="vhi_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Voice Handicap Index (VHI) Module</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">

                        <div class="col-4 border-3 border-success border-end">
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'short_vhi'?'btn-primary':'btn-light']" type="button" @click="selected_module='short_vhi'">
                            Short VHI questionnaire
                          </button>
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'long_vhi'?'btn-primary':'btn-light']" type="button" @click="selected_module='long_vhi'">
                            Long VHI questionnaire 
                          </button>
                        </div>

                        <div class="col-8">
                          <div v-show="selected_module == 'short_vhi'">
                            <p class="lead">10 questions</p>
                          </div>
                          <div v-show="selected_module == 'long_vhi'">
                            <p class="lead">30 questions</p>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, selected_module)" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment(selected_module)" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row mt-2 justify-content-start">
            <div class="col-md-4">
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#avqi_modal" @click="selected_module='avqi'">
                <img :src="voice_AVQI" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>AVQI</strong>
              </div>
              <div class="modal fade" id="avqi_modal" tabindex="-1" aria-labelledby="avqi_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Acoustic Voice Quality Index (AVQI) Module</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <p>Acoustic Voice Quality Index (AVQI) measurement containing:</p>
                          <ul style="list-style-type:disc;  list-style-position: inside;">
                            <li>Rainbow passage x1</li>
                            <li>Sustainable /Ah/ x1</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'avqi')" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment('avqi')" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">

              
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#count_10_modal" @click="selected_module='count_10'">
              <img :src="Voice_count10" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Count to 10</strong>
            </div>
              <div class="modal fade" id="count_10_modal" tabindex="-1" aria-labelledby="count_10_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Count to 10</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <p>Count to 10:</p>
                          <ul style="list-style-type:disc;  list-style-position: inside;">
                            <li>Comfortable voice x2</li>
                            <li>Projecting voice x2</li>
                          </ul>
                          <div class="row mb-4 justify-content-center">
                            <div class="col-8 text-center">
                              <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 0%">Intelligibility</span>
                              <span class="badge rounded-pill" style="background-color: darkmagenta; font-size:medium; margin-left: 5%;">Speech rate</span>
                              <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 5%;">Loudness</span>
                            </div>
                        </div>

                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'count_10')" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment('count_10')" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
            <div class="col-md-4">

                            
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#fda_modal" @click="selected_module='fda'">
              <img :src="FDA_Icon" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>FDA score</strong>
              </div>
              <div class="modal fade" id="fda_modal" tabindex="-1" aria-labelledby="fda_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">FDA score</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <p>FDA score</p>
                          <!-- <ul style="list-style-type:disc;  list-style-position: inside;">
                            <li>Comfortable voice x2</li>
                            <li>Projecting voice x2</li>
                          </ul>
                          <p>Metrics: intelligibility, volume and speech rate </p> -->

                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'fda')" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment('fda')" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
          <div class="row mt-2 justify-content-around">
            
            <div class="col-md-4">
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#lcq_modal" @click="selected_module='lcq_self'">
                <img :src="LCQ_Icon" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>La Trobe (LCQ) </strong>
              </div>
              <div class="modal fade" id="lcq_modal" tabindex="-1" aria-labelledby="lcq_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">La Trobe Communication Questionnaire (LCQ)</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">

                        <div class="col-4 border-3 border-success border-end">
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'lcq_self'?'btn-primary':'btn-light']" type="button" @click="selected_module='lcq_self'">
                            LCQ (self)
                          </button>
                          <!-- <button :class="['btn','mb-2', 'w-100', selected_module == 'long_vhi'?'btn-primary':'btn-light']" type="button" @click="selected_module='long_vhi'">
                            Long VHI questionnaire 
                          </button> -->
                        </div>

                        <div class="col-8">
                          <div v-show="selected_module == 'lcq_self'">
                            <p class="lead">La Trobe Communication Questionnaire (self)</p>
                          </div>
                          <!-- <div v-show="selected_module == 'long_vhi'">
                            <p class="lead">30 questions</p>
                          </div> -->
                        </div>

                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, selected_module)" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment(selected_module)" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      
      <div v-if="active_tab == 'speech'">
        <div class="row mb-2 justify-content-around">
          <div class="col-md-6">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#ddk_modal" @click="selected_module='ddk'">
              <img :src="speech_DDK_rate" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Diadochokinetic Rate</strong>
            </div>
            <div class="modal fade" id="ddk_modal" tabindex="-1" aria-labelledby="ddk_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Diadochokinetic Rate Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <p>Diadochokinetic (DDK) rate measurement containing:</p>
                      <ul style="list-style-type:disc;  list-style-position: inside;">
                        <li>/Pa/ x1</li>
                        <li>/Ta/ x1</li>
                        <li>/Ka/ x1</li>
                        <li>/Pa Ta Ka/ x1</li>
                      </ul>
                    </div>

                    <div class="row mb-4 justify-content-center">
                      <div class="col-8 text-center">
                        <span class="badge rounded-pill" style="background-color: darkorange; font-size:medium; margin-left: 0%;">DDK rate</span>
                        <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 5%;">Loudness</span>
                      </div>
                  </div>
                  
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="number_exercises=4;add_to_assignment_list(false, 'ddk_rate')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="number_exercises=4;start_clinic_assessment('ddk_rate')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#word_intelligibility_modal" @click="selected_module='word_intelligibility'">
              <img :src="speech_word_intell" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Word Intelligibility</strong>
            </div>
            <div class="modal fade" id="word_intelligibility_modal" tabindex="-1" aria-labelledby="word_intelligibility_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Word Intelligibility Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row justify-content-between">
                      <div class="col-6 border-end">
                        <p>Tikofsky word intelligibility test (50 words)</p>
                      </div>


                      <div class="col-6">
                        <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-start">
                      <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 0%">Intelligibility</span>
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 5%;">Loudness</span>
                    </div>
                </div>
                        <div class="row justify-content-center">
                          <div class="col-7">
                            <label for="nexercises">Number of exercises</label>
                            <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="number_exercises" 
                              :sections-schema="{
                              messages: { $el: 'div' },
                              message: { $el: 'span' },
                              options: { $el: 'div' },
                              option: { $el: 'div' },
                              }"
                              messages-class="text-danger"
                              validation-visibility="dirty"
                            :validation="module_exercise_number_validation_string" value="5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'word_intelligibility')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('word_intelligibility')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2 justify-content-around">
          <div class="col-md-6">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#dip_modal" @click="selected_module='dip'">
              <img :src="DIP_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong >Dysarthria Impact Profile</strong>
            </div>
          </div>
          <div class="modal fade" id="dip_modal" tabindex="-1" aria-labelledby="dip_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Dysarthria Impact Profile</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <p>Dysarthria Impact Profile</p>
                       

                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'dip')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('dip')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          <div class="col-md-6">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#sentence_intelligibility_modal" @click="selected_module='RainbowPassage'">
              <img :src="speech_sentence_intell" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Sentence Intelligibility and Speech Rate</strong>
            </div>
            <div class="modal fade" id="sentence_intelligibility_modal" tabindex="-1" aria-labelledby="sentence_intelligibility_modal" aria-hidden="true">
              <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Sentence Intelligibility and Speech Rate Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">

                    <div class="row" style="height:50vh">
                      <div class="col-4 border-3 border-success border-end">
                        <button :class="['btn','mb-2', 'w-100', selected_module == 'RainbowPassage'?'btn-primary':'btn-light']" type="button" @click="selected_module='RainbowPassage'">
                          Rainbow passage
                        </button>
                        <button :class="['btn','mb-2', 'w-100', selected_module == 'GrandfatherPassage'?'btn-primary':'btn-light']" type="button" @click="selected_module='GrandfatherPassage'">
                          Grandfather passage
                        </button>
                        <button :class="['btn','mb-2', 'w-100', selected_module == 'CaterpillarPassage'?'btn-primary':'btn-light']" type="button" @click="selected_module='CaterpillarPassage'">
                          Caterpillar passage
                        </button>
                      </div>

                      <div class="col-8">
                        <div v-show="selected_module == 'RainbowPassage'">
                          <p class="lead">Read aloud the rainbow passage.</p>
                        </div>
                        <div v-show="selected_module == 'GrandfatherPassage'">
                          <p class="lead">Read aloud the grandfather passage.</p>
                        </div>
                        <div v-show="selected_module == 'CaterpillarPassage'">
                          <p class="lead">Read aloud the caterpillar passage.</p>
                        </div>
                        <div class="row mb-4 justify-content-center">
                    <div class="col-8 text-center">
                      <span class="badge rounded-pill" style="background-color: darkgoldenrod; font-size:medium; margin-left: 0%">Intelligibility</span>
                      <span class="badge rounded-pill" style="background-color: darkmagenta; font-size:medium; margin-left: 5%;">Speech rate</span>
                      <span class="badge rounded-pill" style="background-color: darkgreen; font-size:medium; margin-left: 5%;">Loudness</span>
                    </div>
                </div>
                      </div>
                    </div>
                    </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, selected_module)" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment(selected_module)" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        </div>  
        <div class="row mb-4 justify-content-around">
          <div class="col-md-6">

                            
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#fda_modal" @click="selected_module='fda'">
            <img :src="FDA_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>FDA score</strong>
            </div>
            <div class="modal fade" id="fda_modal" tabindex="-1" aria-labelledby="fda_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">FDA score</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-12 text-center">
                        <p>FDA score</p>
                       

                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'fda')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('fda')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div class="col-md-6">
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#lcq_modal" @click="selected_module='lcq_self'">
                <img :src="LCQ_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>La Trobe (LCQ) </strong>
              </div>
              <div class="modal fade" id="lcq_modal" tabindex="-1" aria-labelledby="lcq_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">La Trobe Communication Questionnaire (LCQ)</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">

                        <div class="col-4 border-3 border-success border-end">
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'lcq_self'?'btn-primary':'btn-light']" type="button" @click="selected_module='lcq_self'">
                            LCQ (self)
                          </button>
                          <!-- <button :class="['btn','mb-2', 'w-100', selected_module == 'long_vhi'?'btn-primary':'btn-light']" type="button" @click="selected_module='long_vhi'">
                            Long VHI questionnaire 
                          </button> -->
                        </div>

                        <div class="col-8">
                          <div v-show="selected_module == 'lcq_self'">
                            <p class="lead">La Trobe Communication Questionnaire (self)</p>
                          </div>
                          <!-- <div v-show="selected_module == 'long_vhi'">
                            <p class="lead">30 questions</p>
                          </div> -->
                        </div>

                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, selected_module)" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment(selected_module)" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
        </div>      
      </div>

      <div v-if="active_tab == 'language'">
        <div class="row mt-2 justify-content-around">
          <div class="col-md-6">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#semantic_recognition_memory_modal" @click="selected_module='semantic_memory'">
              <img :src="language_semantic_memory" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>Semantic/Recognition Memory</strong>
            </div>
            <div class="modal fade" id="semantic_recognition_memory_modal" tabindex="-1" aria-labelledby="semantic_recognition_memory_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Semantic/Recognition Memory</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="modal-body">

                      <div class="row" style="height:50vh">
                        <div class="col-4 border-3 border-success border-end">
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'semantic_memory'?'btn-primary':'btn-light']" type="button" @click="selected_module='semantic_memory'">
                            Semantic Memory
                          </button>
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'recognition_memory'?'btn-primary':'btn-light']" type="button" @click="selected_module='recognition_memory'">
                            Recognition Memory
                          </button>
                        </div>

                        <div class="col-8">
                          <div v-show="selected_module == 'semantic_memory'">
                            <p class="lead">Semantic memory.</p>
                          </div>
                          <div v-show="selected_module == 'recognition_memory'">
                            <p class="lead">Recognition memory  (only administer it after Semantic Memory).</p>
                            <p class>Assess if the patient can recognise the images appearing on the Semantic Memory assessment.</p>
                          </div>
            
                        </div>
                      </div>
                      </div>
                    </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, selected_module)" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment(selected_module)" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#word_comprehension_modal" @click="selected_module='written_words_comprehension'">
              <img :src="language_word_comprehension" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>Word Comprehension (Written/Spoken)</strong>
            </div>
            <div class="modal fade" id="word_comprehension_modal" tabindex="-1" aria-labelledby="word_comprehension_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Word Comprehension (Written/Spoken)</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="modal-body">

                      <div class="row" style="height:50vh">
                        <div class="col-4 border-3 border-success border-end">
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'written_words_comprehension'?'btn-primary':'btn-light']" type="button" @click="selected_module='written_words_comprehension'">
                            Written Word Comprehension

                          </button>
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'spoken_words_comprehension'?'btn-primary':'btn-light']" type="button" @click="selected_module='spoken_words_comprehension'">
                            Spoken Word Comprehension

                          </button>
                        
                        </div>

                        <div class="col-8">
                          <div v-show="selected_module == 'spoken_words_comprehension'">
                            <p class="lead">Spoken Word Comprehension.</p>
                          </div>
                          <div v-show="selected_module == 'written_words_comprehension'">
                            <p class="lead">Written Word Comprehension.</p>
                          </div>
            
                        </div>
                      </div>
                      </div>
                    </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, selected_module)" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment(selected_module)" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 justify-content-around">
          <div class="col-md-6">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#token_assessment_modal" @click="selected_module='token_assessment'">
              <img :src="language_token_test" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>Token Assessment</strong>
            </div>
            <div class="modal fade" id="token_assessment_modal" tabindex="-1" aria-labelledby="token_assessment_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Token assessment Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row justify-content-between">
                      <div class="col-6 border-end">
                        <p>Token test to assess comprehension of spoken instructions. It measures the capability to carry out commands of varying complexity (e.g., 'touch the blue square and the white square'). The full assessment set has 25 exercises.</p>
                      </div>
                      <div class="col-6">
                        <div class="row justify-content-center">
                          <div class="col-7">
                            <label for="nexercises">Number of exercises</label>
                            <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="number_exercises" 
                              :sections-schema="{
                              messages: { $el: 'div' },
                              message: { $el: 'span' },
                              options: { $el: 'div' },
                              option: { $el: 'div' },
                              }"
                              messages-class="text-danger"
                              validation-visibility="dirty"
                            :validation="module_exercise_number_validation_string" value="5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'token_assessment')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('token_assessment')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#yes_no_modal" @click="selected_module='yes_no_assessment'">
              <img :src="language_AIQ" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>Yes/No Assessment</strong>

              <!-- <img :src="language_AIQ" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong class="text-decoration-line-through">Aphasia Impact questionnaire (AIQ)</strong> -->
            </div>
            <div class="modal fade" id="yes_no_modal" tabindex="-1" aria-labelledby="yes_no_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Yes/No Assessment</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">

                    <div class="row justify-content-between">
                      <div class="col-12">
                        <p>Test the client's ability to answer yes/no questions (10 questions)</p>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'yes_no_assessment')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('yes_no_assessment')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 justify-content-around">
          <div class="col-md-4">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#word_memory_modal" @click="selected_module='word_memory'">
              <img :src="language_word_memory" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>Word Memory</strong>
            </div>
            <div class="modal fade" id="word_memory_modal" tabindex="-1" aria-labelledby="word_memory_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Word Memory Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">

                    <div class="row justify-content-between">
                      <div class="col-6 border-end">
                        <p>Test the client's ability to remember words and identify the corresponding images</p>
                      </div>
                      <div class="col-6">
                        <div class="row justify-content-center">
                          <div class="col-7">
                            <label for="nexercises">Number of exercises</label>
                            <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="number_exercises" 
                              :sections-schema="{
                              messages: { $el: 'div' },
                              message: { $el: 'span' },
                              options: { $el: 'div' },
                              option: { $el: 'div' },
                              }"
                              messages-class="text-danger"
                              validation-visibility="dirty"
                            :validation="module_exercise_number_validation_string" value="5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'word_memory')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('word_memory')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#picture_naming_modal" @click="selected_module='picture_naming'">
              <img :src="language_picture_naming" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>Picture Naming</strong>
            </div>
            <div class="modal fade" id="picture_naming_modal" tabindex="-1" aria-labelledby="picture_naming_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Picture Naming Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row justify-content-between">
                      <div class="col-6 border-end">
                        <p>Test the client's ability to name simple pictures</p>
                      </div>
                      <div class="col-6">
                        <div class="row justify-content-center">
                          <div class="col-7">
                            <label for="nexercises">Number of exercises</label>
                            <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="number_exercises" 
                              :sections-schema="{
                              messages: { $el: 'div' },
                              message: { $el: 'span' },
                              options: { $el: 'div' },
                              option: { $el: 'div' },
                              }"
                              messages-class="text-danger"
                              validation-visibility="dirty"
                            :validation="module_exercise_number_validation_string" value="5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'picture_naming')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('picture_naming')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#word_finding_modal" @click="selected_module='word_finding'">
              <img :src="language_word_finding" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>Word Finding</strong>
            </div>
            <div class="modal fade" id="word_finding_modal" tabindex="-1" aria-labelledby="word_finding_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Word Finding Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row justify-content-between">
                      <div class="col-6 border-end">
                        <p>Semantic word finding</p>
                      </div>
                      <div class="col-6">
                        <div class="row justify-content-center">
                          <div class="col-7">
                            <label for="nexercises">Number of exercises</label>
                            <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" v-model="number_exercises" 
                              :sections-schema="{
                              messages: { $el: 'div' },
                              message: { $el: 'span' },
                              options: { $el: 'div' },
                              option: { $el: 'div' },
                              }"
                              messages-class="text-danger"
                              validation-visibility="dirty"
                            :validation="module_exercise_number_validation_string" value="5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'word_finding')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('word_finding')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 justify-content-around">
          <div class="col-md-4">

            <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#cookie_theft_modal" @click="selected_module='cookie_theft'">
              <img :src="language_cookie_theft" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>Cookie Theft</strong>
            </div>

        
            <div class="modal fade" id="cookie_theft_modal" tabindex="-1" aria-labelledby="cookie_theft_modal" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Cookie Theft Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">

                    <div class="row justify-content-between">
                      <div class="col-6 border-end">
                        <p>Test the client's ability to describe a complex image</p>
                      </div>
                      
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'cookie_theft')" data-bs-dismiss="modal">Send to patient for assessment</button>
                    <button type="button" class="btn btn-primary" @click="start_clinic_assessment('cookie_theft')" data-bs-dismiss="modal">Start assessment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#lcq_modal" @click="selected_module='lcq_self'">
                <img :src="voice_VHI" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="width: 40px; background-color: orange"><strong>La Trobe (LCQ) </strong>
              </div>
              <div class="modal fade" id="lcq_modal" tabindex="-1" aria-labelledby="lcq_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">La Trobe Communication Questionnaire (LCQ)</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">

                        <div class="col-4 border-3 border-success border-end">
                          <button :class="['btn','mb-2', 'w-100', selected_module == 'lcq_self'?'btn-primary':'btn-light']" type="button" @click="selected_module='lcq_self'">
                            LCQ (self)
                          </button>
                          <!-- <button :class="['btn','mb-2', 'w-100', selected_module == 'long_vhi'?'btn-primary':'btn-light']" type="button" @click="selected_module='long_vhi'">
                            Long VHI questionnaire 
                          </button> -->
                        </div>

                        <div class="col-8">
                          <div v-show="selected_module == 'lcq_self'">
                            <p class="lead">La Trobe Communication Questionnaire (self)</p>
                          </div>
                          <!-- <div v-show="selected_module == 'long_vhi'">
                            <p class="lead">30 questions</p>
                          </div> -->
                        </div>

                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, selected_module)" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment(selected_module)" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">

              </div>
        </div>
      </div>

      <div v-if="active_tab == 'dysphagia'">
        <div class="row mb-4">
         
          
          <div class="row mt-2 justify-content-start">
           

            <div class="col-md-4">

              
              <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#swal_qol_modal" @click="selected_module='swal_qol'">
              <img :src="SWAL_Qol_Icon" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Swal-Qol</strong>
            </div>

            
             
          </div>
          <div class="col-md-4">

                        
          <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#fois_modal" @click="selected_module='fois'">
          <img :src="FOIS_Icon" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong> Functional Oral Intake Scale</strong>
          </div>



          </div>

          <div class="col-md-4">

                                  
          <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#dsfs_modal" @click="selected_module='dsfs'">
          <img :src="DSFS_Icon" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Drooling Severity and Frequency Scale</strong>
          </div>



          </div>
          <div class="modal fade" id="swal_qol_modal" tabindex="-1" aria-labelledby="swal_qol_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Swal-Qol</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <p>Swal-Qol Questionnaire</p>
                         
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'swal_qol')" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment('swal_qol')" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal fade" id="fois_modal" tabindex="-1" aria-labelledby="fois_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title"> Functional Oral Intake Scale</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <p>FOIS Questionnaire</p>
                         
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'fois')" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment('fois')" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal fade" id="dsfs_modal" tabindex="-1" aria-labelledby="dsfs_modal" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Drooling Severity and Frequency Scale</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12 text-center">
                          <p>DSFS Questionnaire</p>
                         
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-success me-2" @click="add_to_assignment_list(false, 'dsfs')" data-bs-dismiss="modal">Send to patient for assessment</button>
                      <button type="button" class="btn btn-primary" @click="start_clinic_assessment('dsfs')" data-bs-dismiss="modal">Start assessment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

        </div>

        
      </div>
      
      <div v-if="active_tab == 'interactive_session'">            
        <div class="row mb-4 justify-content-around">
          <div class="col-md-4">
            <div v-if="subscription_tier.includes('F')" class="card-body divH text-start border border-dark border-opacity-25" style="cursor:not-allowed">
              <img :src="language_picture_naming" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Picture Naming</strong>
            </div>
            <div v-else class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="$router.push({'name': 'training_room', 'params': {session_name: 'Interactive Session Picture Naming'}})">
              <img :src="language_picture_naming" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Picture Naming</strong>
            </div>
          </div>
          <div class="col-md-4">
            <div v-if="subscription_tier.includes('F')" class="card-body divH text-start border border-dark border-opacity-25" style="cursor:not-allowed">
              <img :src="Interactive_Questionnaire_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Questionnaire Assessment</strong>
            </div>
            <div v-else class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="$router.push({'name': 'training_room', 'params': {session_name: 'Interactive Session Questionnaire'}})">
              <img :src="Interactive_Questionnaire_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Questionnaire Assessment</strong>
            </div>
          </div>
          <div class="col-md-4">
            <div v-if="subscription_tier.includes('F')" class="card-body divH text-start border border-dark border-opacity-25" style="cursor:not-allowed" >
              <img :src="Interactive_Word_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Word Assessment</strong>
            </div>
            <div v-else class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="$router.push({'name': 'training_room', 'params': {session_name: 'Interactive Session Words'}})">
              <img :src="Interactive_Word_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Word Assessment</strong>
            </div>
          </div>
        </div>
        <div class="row mb-4 justify-content-around">
          <div class="col-md-6">
            <div v-if="subscription_tier.includes('F')" class="card-body divH text-start border border-dark border-opacity-25" style="cursor:not-allowed">
              <img :src="Interactive_Sentence_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Sentences Assessment</strong>
            </div>
            <div v-else class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="$router.push({'name': 'training_room', 'params': {session_name: 'Interactive Session Sentences'}})">
              <img :src="Interactive_Sentence_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Sentences Assessment</strong>
            </div>
          </div>
          <div class="col-md-6">
            <div v-if="subscription_tier.includes('F')" class="card-body divH text-start border border-dark border-opacity-25" style="cursor:not-allowed">
              <img :src="Interactive_Phonation_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Ahhh phonation</strong>
            </div>
            <div v-else class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="$router.push({'name': 'training_room', 'params': {session_name: 'Interactive Session Phonation'}})">
              <img :src="Interactive_Phonation_Icon" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline" style="width: 40px"><strong>Ahhh phonation</strong>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="modal fade" id="subscribe_modal" tabindex="-1" aria-labelledby="subscribe_modal" aria-hidden="true">

    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Subscribe</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-start">
          <div class="row">
            <p>
              A subscription plan is required for this function. You can upgrade your plan by clicking below.
              <br/>
              <br/>
              Contact us at <a href='mailto:contact@beautifulvoice.co.uk'>contact@beautifulvoice.co.uk</a> if you need help.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success me-2" data-bs-dismiss="modal" @click="$router.push({name: 'subscribe'})">Subscribe</button>
        </div>
      </div>
    </div>

  </div>
  
  <Assignment_list :table_assignment_list="table_assessment_list" list_type="assessment"></Assignment_list>
</div>
</template>


<script>

import package_general from '@/assets/images/dashboard/slt/assessment/Package_general.svg'
import package_aphasia from '@/assets/images/dashboard/slt/assessment/Package_aphasia.png'
import package_dysarthria from '@/assets/images/dashboard/slt/assessment/Package_dysarthria.svg'
import package_parkinson from '@/assets/images/dashboard/slt/assessment/Package_parkinsons.svg'

import voice_phonation from '@/assets/images/dashboard/slt/assessment/Voice_phonation.svg'
import voice_pitch from '@/assets/images/dashboard/slt/assessment/Voice_pitch.svg'
import voice_VHI from '@/assets/images/dashboard/slt/assessment/Voice_VHI.svg'
import voice_AVQI from '@/assets/images/dashboard/slt/assessment/voice_AVQI.svg'
import FDA_Icon from '@/assets/images/dashboard/slt/assessment/FDA_Icon.svg'
import DIP_Icon from '@/assets/images/dashboard/slt/assessment/DIP_Icon.svg'
import DSFS_Icon from '@/assets/images/dashboard/slt/assessment/DSFS_Icon.svg'
import FOIS_Icon from '@/assets/images/dashboard/slt/assessment/FOIS_Icon.svg'
import SWAL_Qol_Icon from '@/assets/images/dashboard/slt/assessment/SWAL_Qol_Icon.svg'


import Interactive_Phonation_Icon from '@/assets/images/dashboard/slt/assessment/Interactive_Phonation_Icon.svg'
import Interactive_Sentence_Icon from '@/assets/images/dashboard/slt/assessment/Interactive_Sentence_Icon.svg'
import Interactive_Questionnaire_Icon from '@/assets/images/dashboard/slt/assessment/Interactive_Questionnaire_Icon.svg'
import Interactive_Word_Icon from '@/assets/images/dashboard/slt/assessment/Interactive_Word_Icon.svg'


import LCQ_Icon from '@/assets/images/dashboard/slt/assessment/LCQ_Icon.svg'

import speech_sentence_intell from '@/assets/images/dashboard/slt/assessment/Speech_sentence_intell.svg'
import speech_word_intell from '@/assets/images/dashboard/slt/assessment/Speech_word_intell.svg'
import speech_DDK_rate from '@/assets/images/dashboard/slt/assessment/Speech_DDK_rate.svg'

import Voice_count10 from '@/assets/images/dashboard/slt/assessment/Voice_count10.svg'

import language_picture_naming from '@/assets/images/dashboard/slt/assessment/Language_picture_naming.svg'
import language_token_test from '@/assets/images/dashboard/slt/assessment/Language_token_test.svg'
import language_AIQ from '@/assets/images/dashboard/slt/assessment/Language_AIQ.svg'
import language_cookie_theft from '@/assets/images/dashboard/slt/assessment/Language_cookie_theft.svg'
import language_semantic_memory from '@/assets/images/dashboard/slt/assessment/Language_semantic_memory.svg'
import language_word_comprehension from '@/assets/images/dashboard/slt/assessment/Language_word_comprehension.svg'


import language_word_finding from '@/assets/images/dashboard/slt/assessment/Language_word_finding.svg'
import language_word_memory from '@/assets/images/dashboard/slt/assessment/Language_word_memory.svg'


import Voice_assessment from '@/assets/images/dashboard/slt/assessment/collapse/voice_training.svg'
import Speech_assessment from '@/assets/images/dashboard/slt/assessment/collapse/speech_training.svg'
import Language_assessment from '@/assets/images/dashboard/slt/assessment/collapse/language_training.svg'
import Dysphagia_assessment from '@/assets/images/dashboard/slt/assessment/collapse/dysphagia_training.svg'

import Interactive_assessment from '@/assets/images/dashboard/slt/assessment/collapse/interactive.svg'

import homework from '@/assets/images/dashboard/slt/assessment/collapse/homework.png'


import { getTmpAssignmentList} from '@/utils/assignment/common.js'
import { getData, updateDataDirectory, setDataDirectory } from '@/firebase/index'
import { useToast } from "vue-toastification";
import Assignment_list from "../common/assignment_list.vue"
import { Modal } from 'bootstrap'
import { getDataDirectory} from '@/firebase/index'

export default {
  name: 'Assessment',
  props: 
  {
  },
  components: {Assignment_list},
  data(){
    return {
      slt_email:localStorage.getItem('slt_email'),
      uid: localStorage.getItem('uid'),
      client_uid: localStorage.getItem('client_uid'),
      subscription_tier: sessionStorage.getItem("tier"),
      selected_module : '',
      module_min_max_exercises: {
        'phonation': {"default": 5, "max": 15},
        'short_vhi': {"default": 1, "max": 1},
        'lcq_self': {"default": 1, "max": 1},

        'long_vhi': {"default": 1, "max": 1},
        'avqi': {"default": 1, "max": 1},
        'count_10': {"default": 1, "max": 1},
        'fda': {"default": 1, "max": 1},

        'swal_qol': {"default": 1, "max": 1},
        'fois': {"default": 1, "max": 1},
        'dsfs': {"default": 1, "max": 1},
        'dip': {"default": 1, "max": 1},



        'word_intelligibility': {"default": 5, "max": 50},
        'sentence_intelligibility': {"default": 5, "max": 50},
        'GrandfatherPassage': {"default": 1, "max": 1},
        'RainbowPassage': {"default": 1, "max": 1},
        'CaterpillarPassage': {"default": 1, "max": 1},
        'recognition_memory': {"default": 1, "max": 1},
        'semantic_memory': {"default": 1, "max": 1},
        'written_words_comprehension': {"default": 1, "max": 1},
        'spoken_words_comprehension': {"default": 1, "max": 1},

        
        'token_assessment': {"default": 25, "max": 25},
        'word_memory': {"default": 5, "max": 50},
        'yes_no_assessment': {"default": 10, "max": 10},

        'cookie_theft': {"default": 1, "max": 1},


        'picture_naming': {"default": 5, "max": 50},
        'word_finding': {"default": 3, "max": 3},
        'Pitch_Normal_2_High': {"default": 5, "max": 15},
        'Pitch_Normal_2_Low': {"default": 5, "max": 15},
      },

      package_aphasia: package_aphasia,
      package_general: package_general,
      package_dysarthria: package_dysarthria,
      package_parkinson: package_parkinson,

      voice_phonation: voice_phonation,
      voice_pitch: voice_pitch,
      voice_VHI: voice_VHI,
      voice_AVQI:voice_AVQI,
      FDA_Icon:FDA_Icon,
      LCQ_Icon:LCQ_Icon,
      Voice_count10:Voice_count10,

      Interactive_Phonation_Icon:Interactive_Phonation_Icon,
      Interactive_Sentence_Icon:Interactive_Sentence_Icon,
      Interactive_Questionnaire_Icon:Interactive_Questionnaire_Icon,
      Interactive_Word_Icon:Interactive_Word_Icon,

      SWAL_Qol_Icon:SWAL_Qol_Icon,
      DSFS_Icon:DSFS_Icon,
      DIP_Icon:DIP_Icon,
      FOIS_Icon:FOIS_Icon,

      Dysphagia_assessment:Dysphagia_assessment,
      Interactive_assessment:Interactive_assessment,

      speech_sentence_intell: speech_sentence_intell,
      speech_DDK_rate: speech_DDK_rate,
      speech_word_intell: speech_word_intell,

      language_picture_naming: language_picture_naming,
      language_token_test: language_token_test,
      language_word_finding: language_word_finding,
      language_word_memory: language_word_memory,
      language_AIQ: language_AIQ,
      language_cookie_theft:language_cookie_theft,
      language_word_comprehension:language_word_comprehension,
      language_semantic_memory:language_semantic_memory,

      Voice_assessment: Voice_assessment,
      Speech_assessment: Speech_assessment,
      Language_assessment: Language_assessment,
      homework: homework,

      dysarthria_selected_options: ['short_vhi', 'phonation', 'Pitch normal to high', 'Pitch normal to low', 'grandfather', 'hobbies'],
      parkinson_selected_options: ['caterpillar', 'hobbies', 'phonation', 'Pitch normal to high', 'Pitch normal to low', 'Short VHI Assessment'],
      parkinson_module_order: ['grandfather', 'caterpillar', 'rainbow', 'hobbies', 'phonation', 'Pitch normal to high', 'Pitch normal to low', 'Short VHI Assessment', 'Long VHI Assessment'],

      parkinson_options: [{  
                            'value': 'grandfather',
                            'label': 'Grandfather passage x1',
                          },
                          {
                            'value': 'caterpillar',
                            'label': 'Caterpillar Passage x1',
                          },
                          {
                            'value': 'rainbow',
                            'label': 'Rainbow Passage x1',
                          },
                          {
                            'value': 'hobbies',
                            'label': 'Hobbies (freestyle talking) x1',
                          },
                          {
                            'value': 'phonation',
                            'label': 'Phonation x6',
                          },
                          {
                            'value': 'Pitch normal to high',
                            'label': 'Pitch (normal to high) x4',
                          },
                          {
                            'value': 'Pitch normal to low',
                            'label': 'Pitch (normal to low) x4',
                          },
                          {
                            'value': 'Short VHI Assessment',
                            'label': 'Short Voice Handicap Index (VHI) - 10 Questions',
                          },
                          {
                            'value': 'Long VHI Assessment',
                            'label': 'Full Voice Handicap Index (VHI) - 30 Questions',
                          }],

      assessment_module_map: {
        "cookie_theft":{
          target_address: "BaseUserPlan/Assessment_modules/CookieTheft/",
          module_name: "Cookie Theft",
        },
        "phonation":{
          target_address: "BaseUserPlan/Assessment_modules/Maximum Phonation/",
          module_name: "Phonation Assessment",
        },
        "Pitch_Normal_2_High": {
          target_address: "BaseUserPlan/Assessment_modules/Pitch_Normal_2_High/",
          module_name: "Pitch Normal to High",
        },
        "Pitch_Normal_2_Low": {
          target_address: "BaseUserPlan/Assessment_modules/Pitch_Normal_2_Low/",
          module_name: "Pitch Normal to Low",
        },
        "pitch":{
          target_address: "BaseUserPlan/Assessment_modules/Pitch Variation/",
          module_name: "Pitch Variation Assessment",
        },
        "lcq_self":{
          target_address: "BaseUserPlan/Assessment_modules/LCQ_Self/",
          module_name: "La Trobe Communication",
        },
        "short_vhi":{
          target_address: "BaseUserPlan/Assessment_modules/VHI10/",
          module_name: "Short VHI Assessment",
        },
        "long_vhi":{
          target_address: "BaseUserPlan/Assessment_modules/VHI/",
          module_name: "VHI Assessment",
        },
        "avqi":{
          target_address: "BaseUserPlan/Assessment_modules/AVQI/",
          module_name: "AVQI Assessment",
        },
        "count_10":{
          target_address: "BaseUserPlan/Assessment_modules/CountTo10",
          module_name: "Count to 10",
        },
        "fda":{
          target_address: "BaseUserPlan/Assessment_modules/FDA",
          module_name: "FDA score",
        },
        "swal_qol":{
          target_address: "BaseUserPlan/Assessment_modules/Swal_qol",
          module_name: "Swal-Qol",
        },
        "fois":{
          target_address: "BaseUserPlan/Assessment_modules/FOIS",
          module_name: "FOIS",
        },
        "dip":{
          target_address: "BaseUserPlan/Assessment_modules/DIP",
          module_name: "DIP",
        },
        "dsfs":{
          target_address: "BaseUserPlan/Assessment_modules/DSFS",
          module_name: "DSFS",
        },
        "ddk_rate":{
          target_address: "BaseUserPlan/Assessment_modules/DDK_Rate/",
          module_name: "Diadochokinetic Rate Assessment",
        },
        "word_intelligibility":{
          target_address: "BaseUserPlan/Assessment_modules/Tikofsky10-wordIntelligibilityTest/",
          module_name: "Word intelligibility",
        },
        "sentence_intelligibility":{
          target_address: "BaseUserPlan/Assessment_modules/Rainbow Passage/",
          module_name: "Sentence intelligibility and speech rate assessment",
        },
        "GrandfatherPassage":{
          target_address: "BaseUserPlan/Assessment_modules/SentenceIntelligibilityGrandfather/",
          module_name: "Sentence intelligibility and speech rate assessment",
        },
        "CaterpillarPassage":{
          target_address: "BaseUserPlan/Assessment_modules/SentenceIntelligibilityCaterpillar/",
          module_name: "Sentence intelligibility and speech rate assessment",
        },
        "RainbowPassage":{
          target_address: "BaseUserPlan/Assessment_modules/SentenceIntelligibilityRainbow/",
          module_name: "Sentence intelligibility and speech rate assessment",
        },
        "word_finding":{
          target_address: "BaseUserPlan/Assessment_modules/WordFinding", //this should be a module in BaseUserPlan
          module_name: "Word Finding Assessment",
        },
        "token_assessment":{
          target_address: "BaseUserPlan/Assessment_modules/TokenAssessment/",
          module_name: "Token Assessment",
        },
        "semantic_memory":{
          target_address: "BaseUserPlan/Assessment_modules/SemanticMemory/",
          module_name: "Semantic Memory",
        },
        "recognition_memory":{
          target_address: "BaseUserPlan/Assessment_modules/RecognitionMemory/",
          module_name: "Recognition Memory",
        },
        "written_words_comprehension":{
          target_address: "BaseUserPlan/Assessment_modules/WrittenWordsComprehension/",
          module_name: "Written Word Comprehension",
        },
        "spoken_words_comprehension":{
          target_address: "BaseUserPlan/Assessment_modules/SpokenWordsComprehension/",
          module_name: "Spoken Word Comprehension  ",
        },
        "word_memory":{
          target_address: "BaseUserPlan/Assessment_modules/MemoryImage/",
          module_name: "Word Memory Assessment",
        }, 
        "yes_no_assessment":{
          target_address: "BaseUserPlan/Assessment_modules/Yes_No/",
          module_name: "Yes No Assessment",
        }, 
        "picture_naming":{
          target_address: "BaseUserPlan/Assessment_modules/PictureNaming/",
          module_name: "Picture Naming Assessment",
        },
      },
      number_exercises: 5,
      table_assessment_list: [],
      has_active_assessment_package: false,
      active_package_assessment_modules: [],
      active_tab: localStorage.getItem('assessment_tab')
    }
  },
  async created() {
    localStorage.setItem("view_mode", "assessment")

    let general_package_lists = await Promise.all(getTmpAssignmentList(this.client_uid, 'assessment'))
    this.table_assessment_list = general_package_lists[0].concat(general_package_lists[1])

    if (this.active_tab == null) {
      this.active_tab = 'assessment_package'
      localStorage.setItem('assessment_tab', 'assessment_package')
    }
  },
  computed: {
    module_exercise_number_validation_string() {
      if (this.selected_module in this.module_min_max_exercises) {
        return "required|min:1|max:" + this.module_min_max_exercises[this.selected_module]['max']
      }
      return "required|min:1"
    },
  },
  watch: {

    selected_module(newModule, oldModule) {
      if (newModule in this.module_min_max_exercises) {
        this.number_exercises = this.module_min_max_exercises[newModule]['default']
      } else {
        this.number_exercises = 1
      }
    },
    active_tab(new_active_tab) {
      localStorage.setItem('assessment_tab', new_active_tab )
    }
  },
  mounted() {
    this.check_has_active_package()
  },
  methods: {
    check_has_active_package() {
      let _this = this

      let packages_dir = 'Users/' + localStorage.getItem('client_uid') + '/Active_Assessment/packages/'
      
      getDataDirectory(packages_dir).then( (package_data) => {
        if (package_data.val() && Object.keys(package_data.val()).length > 0) { 
          
          if ('finished' in package_data.val()[ Object.keys(package_data.val())[0]]) {
            _this.has_active_assessment_package = false
            _this.active_package_assessment_modules = []
            return
          }

          _this.has_active_assessment_package = true

          let modules = package_data.val()[ Object.keys(package_data.val())[0]]['modules']

          let filteredValues = [];

          let found_in_progress = false

          for (let i = 0; i < _this.parkinson_options.length; i++) {
            let option = _this.parkinson_options[i]

            let cleaned = option.value.toLowerCase().replace(/ x\d+$/, '').trim()

            
            for (let module of modules) {
              
              if (module['mod_name'].toLowerCase().includes(cleaned)) {
                console.log(module['mod_name'].toLowerCase())
                console.log(cleaned)
                let value = option.value
                let has_finished = module.hasOwnProperty('completedOn')

                let in_progress = false

                if (has_finished) {

                } else {
                  if (found_in_progress == false) {
                    found_in_progress = true
                    in_progress = true
                  }

                }
                
                
                filteredValues.push({value: value, has_finished: has_finished, in_progress: in_progress});
              }
            } 
          }
          _this.active_package_assessment_modules = filteredValues
          
        } else {
          _this.has_active_assessment_package = false
          _this.active_package_assessment_modules = []
        }

      })
    },
    async onNumberExercisesChange() {
      let _this = this
      if (this.selected_module != '' && this.number_exercises > this.module_min_max_exercises[this.selected_module]['max']) {
        //alert(this.selected_module)
        //setTimeout(function() {
          
          const DEF_DELAY = 200;

          function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms || DEF_DELAY));
          }

          await sleep(DEF_DELAY);

          _this.number_exercises = _this.module_min_max_exercises[_this.selected_module]['max']
        //}, 1000);

        //alert("Exceeded number of available exercises. Please select a lower number of exercises")

      } else if (this.selected_module != '' && this.number_exercises <= 0 && this.module_min_max_exercises[this.selected_module]['default'] != 0) {
        //alert("Please select a higher number of exercises")
      } else {
        //this.number_exercises = this.number_exercises
      }

    },
    add_to_assignment_list(isPackage, master_module_name) {

      if (sessionStorage.getItem('tier').includes('F')) {
        let subscribe_modal = new Modal(document.getElementById('subscribe_modal'));
        subscribe_modal.show()
        return
      }



      let _this = this

      if (isPackage) {

        if (master_module_name === 'general_questionnaire') {

        } else if (master_module_name === 'aphasia') {

        } else if (master_module_name === 'dysathria') {


          /*create tmp package programmatically*/
          /*
          let module_directory = '/BaseUserPlan/Training_modules/PictureNaming';

          let timestamp = new Date().getTime()

          let master_module_uid = master_module_name + '_' + timestamp
          
          this.add_to_tmp_list(module_directory, master_module_uid)
          module_directory = '/BaseUserPlan/Training_modules/TokenAssessment';

          this.add_to_tmp_list(module_directory, master_module_uid)
          //this.add_package_meta_data(master_module_uid, meta)

          module_directory = '/BaseUserPlan/Training_modules/DailyNewsShort';

          this.add_to_tmp_list(module_directory, master_module_uid)
          //this.add_to_tmp_list(module_directory) 
          //regular assessment module
          */

          let dysathria_package_directory = "/BaseUserPlan/Assessment_packages/dysathria/"
          getData(dysathria_package_directory, function(err, dysathria_package_handle) {
            
            let dysathria_package_data = dysathria_package_handle.val()

            let filtered_modules = {}
            for (const [key, module_data] of Object.entries(dysathria_package_data['modules'])) {
              for (let dysathria_selected_module of _this.dysarthria_selected_options) {
                if (module_data['mod_name'].toLowerCase().includes(dysathria_selected_module.toLowerCase())) {
                  filtered_modules[key] = module_data['mod_name']
                }
              }
            }
            dysathria_package_data['modules'] = filtered_modules

            _this.add_package_data_to_tmp_list(dysathria_package_data)
          })


        } else if (master_module_name === 'parkinson') {

          /*create tmp package programmatically*/
          /*
          let module_directory = '/BaseUserPlan/Training_modules/PictureNaming';

          let timestamp = new Date().getTime()

          let master_module_uid = master_module_name + '_' + timestamp
          
          this.add_to_tmp_list(module_directory, master_module_uid)
          module_directory = '/BaseUserPlan/Training_modules/TokenAssessment';

          this.add_to_tmp_list(module_directory, master_module_uid)
          //this.add_package_meta_data(master_module_uid, meta)

          module_directory = '/BaseUserPlan/Training_modules/DailyNewsShort';

          this.add_to_tmp_list(module_directory, master_module_uid)
          //this.add_to_tmp_list(module_directory) 
          //regular assessment module
          */
          
          //sort the selection
          _this.parkinson_selected_options = _this.parkinson_module_order.filter(item => _this.parkinson_selected_options.includes(item)).map(item => _this.parkinson_selected_options[_this.parkinson_selected_options.indexOf(item)])
          
          let parkinson_package_directory = "/BaseUserPlan/Assessment_packages/parkinson/"
          getData(parkinson_package_directory, function(err, parkinson_package_handle) {
            
            let parkinson_package_data = parkinson_package_handle.val()

            let filtered_modules = []
            for (let parkinson_selected_module of _this.parkinson_selected_options) {
              for (const [key, module_data] of Object.entries(parkinson_package_data['modules'])) {

                //alert(module_data['mod_name'] + " " + parkinson_selected_module)
                if (module_data['mod_name'].toLowerCase().includes(parkinson_selected_module.toLowerCase())) {
                  
                  filtered_modules.push(module_data)
                  continue
                }
              }
            }
            parkinson_package_data['modules'] = filtered_modules
            
            _this.add_package_data_to_tmp_list(parkinson_package_data)
          })

        }

      } else {
        _this.add_to_tmp_list(_this.assessment_module_map[master_module_name].target_address) 
      }

      const toast = useToast()
      toast.success("Assessment added to the home assesment list\nScroll down to send the assesment list.", {timeout: 5000})
    },
    start_assessment(master_module_name) {

      if (sessionStorage.getItem('tier').includes('F')) {
        let subscribe_modal = new Modal(document.getElementById('subscribe_modal'));
        subscribe_modal.show()
        return
      }
      
      let _this = this
      if (master_module_name === 'general_questionnaire') {

        let directory = '/BaseUserPlan/Assessment_packages/general_questionnaire'
        updateDataDirectory(directory, {name: "General Questionnaire"})

      } else if (master_module_name === 'aphasia') {

        let directory = '/BaseUserPlan/Assessment_packages/aphasia'
        updateDataDirectory(directory, {name: "Aphasia"})
      } else if (master_module_name === 'dysathria') {

        /* 
        ////////////////This is for creating the package///////////
        let training_modules_directory = '/BaseUserPlan/Training_modules/';

        getData(training_modules_directory, function(err, training_modules) {
          console.error(err)
          console.error(training_modules.val())

          let assessment_modules_directory = '/BaseUserPlan/Training_modules/';
          getData(assessment_modules_directory, function(err, assessment_modules) {
            console.error(err)
            let assessment_modules_data = assessment_modules.val()
            console.error(assessment_modules_data)
            console.error(assessment_modules_data['PictureNaming'])
            let directory = '/BaseUserPlan/Assessment_packages/dysathria/'
            let tmp_ref = _this.$firebase.database().ref(directory)
            updateData(tmp_ref, {modules: 
              {PictureNaming: assessment_modules_data['PictureNaming'], 
              "Loudness&Phonation": assessment_modules_data['Loudness&Phonation']}})
          })
        })*/

        let dysathria_package_directory = "/BaseUserPlan/Assessment_packages/dysathria/"
        getData(dysathria_package_directory, function(err, dysathria_package_handle) {
          
          let dysathria_package_data = dysathria_package_handle.val()

          let filtered_modules = []
          for (const [key, module_data] of Object.entries(dysathria_package_data['modules'])) {
            for (let dysathria_selected_module of _this.dysarthria_selected_options) {
              if (module_data['mod_name'].toLowerCase().includes(dysathria_selected_module.toLowerCase())) {
                filtered_modules.push(module_data['mod_name'])
              }
            }
          }
          dysathria_package_data['modules'] = filtered_modules
          
          //_this.$router.push({ name: 'clinic_assessment', query: {isPackage: true, package: JSON.stringify(dysathria_package_data), package_dir: dysathria_package_directory} })
        })

      } else if (master_module_name === 'parkinson') {

        _this.parkinson_selected_options = _this.parkinson_module_order.filter(item => _this.parkinson_selected_options.includes(item)).map(item => _this.parkinson_selected_options[_this.parkinson_selected_options.indexOf(item)])

        let parkinson_package_directory = "/BaseUserPlan/Assessment_packages/parkinson/"
        
        getData(parkinson_package_directory, function(err, parkinson_package_handle) {
          
          let parkinson_package_data = parkinson_package_handle.val()

          let filtered_modules = []
          let filtered_modules_names = []
          for (let parkinson_selected_module of _this.parkinson_selected_options) {
            for (const [key, module_data] of Object.entries(parkinson_package_data['modules'])) {
              if (module_data['mod_name'].toLowerCase().includes(parkinson_selected_module.toLowerCase())) {
                filtered_modules.push(module_data)
                filtered_modules_names.push(module_data['mod_name'])
              }
            }
          }
          
          parkinson_package_data['modules'] = filtered_modules

          _this.add_package_data_to_tmp_list(parkinson_package_data, false)
          
          let master_module_name = parkinson_package_data['name']

          let target_dir = 'Users/' + _this.client_uid + '/Active_Assessment/packages/' + master_module_name

          _this.$router.push({ name: 'clinic_assessment_package', query: {isPackage: true, package_name: master_module_name, package_dir: target_dir} })
        })
      }
    },
    async add_package_data_to_tmp_list(predefined_package_data, assignment=true) {

      if (sessionStorage.getItem('tier').includes('F')) {
        let subscribe_modal = new Modal(document.getElementById('subscribe_modal'));
        subscribe_modal.show()
        return
      }

      const _this = this

      let timestamp = new Date().getTime()

      let master_module_name = predefined_package_data['name']
      let master_module_uid = master_module_name + '_' + timestamp

      predefined_package_data['package_uid'] = master_module_uid

      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List' + '/assessment/packages/' + master_module_uid
      
      if (!assignment) {
        target_dir = 'Users/' + _this.client_uid + '/Active_Assessment/packages/' + master_module_name
      }
      
      setDataDirectory(target_dir, predefined_package_data) //this is set to overwrite previous writes

      if (!assignment) {
        let general_package_lists = await Promise.all(getTmpAssignmentList(_this.client_uid, 'assessment'))
        _this.table_assessment_list = general_package_lists[0].concat(general_package_lists[1])
      }
    },
    async add_to_tmp_list(predefined_module_path, master_module_uid='') 
    {
      const _this = this

      let do_once = true
      
      getData(predefined_module_path, async function (err, module_handle) {

        if(do_once)
        {
          do_once = false
          let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/assessment/general/'

          if (master_module_uid) {
            target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/assessment/packages/' + master_module_uid + '/modules/'
          }

          let tmp_ref = _this.$firebase.database().ref(target_dir)
          let record_key = _this.table_assessment_list.length + "__" + tmp_ref.push().key // // alphanumerical order is mandatory, so this is required

          let curr_module_data = module_handle.val()
          curr_module_data['modifier'] =  ''
          curr_module_data['number_exercises'] = _this.number_exercises

          updateDataDirectory(target_dir + record_key, curr_module_data)

        }  
      });

      let general_package_lists = await Promise.all(getTmpAssignmentList(this.client_uid, 'assessment'))
      this.table_assessment_list = general_package_lists[0].concat(general_package_lists[1])
    },
    async add_package_meta_data(master_module_uid, meta) {
      const _this = this

      let target_dir = 'Users/' + _this.uid + '/Clients/' + _this.client_uid + '/Draft_Assignment_List/assessment/packages/' + master_module_uid 
      
      updateDataDirectory(target_dir, meta)
      let general_package_lists = await Promise.all(getTmpAssignmentList(this.client_uid, 'assessment'))
      this.table_assessment_list = general_package_lists[0].concat(general_package_lists[1])
    },
    start_clinic_assessment(name) {
      
      if (sessionStorage.getItem('tier').includes('F')) {
        let subscribe_modal = new Modal(document.getElementById('subscribe_modal'));
        subscribe_modal.show()
        return
      }
      
      if (name in this.assessment_module_map) {

        let meta = this.assessment_module_map[name]
        
        let start_object = {
          module_path: meta.target_address,
          module_name: meta.module_name,
          assessment_type: "SLP_ASSESSMENT",
          total_requested_input_task_exercises: this.number_exercises
        }

        this.$router.push({ 
          name: 'clinic_assessment', 
          query: { 
            module_name: meta.module_name, 
            blueprint_path: meta.target_address,
            start_object: encodeURIComponent(JSON.stringify(start_object))
          }
        });

      } 
    }
  }
}
</script>
<style scoped>
li {
    list-style-type: none;
}
</style>