import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import {roundNumber} from '@/utils/utils'

export function get_real_life_exercise_data(exercise_data, name) {

  let task = exercise_data['real_life_exercise']['task']
  let audio_address = exercise_data['audio_address']
  let loudness = exercise_data['loudness']['loudness']

  let real_life_exercise_results = {Name: name, loudness:loudness,  AudioAddress: audio_address, task:task};

  return real_life_exercise_results




}

export function getSpeechrate_data(exercise_data, name) {


  let asd = exercise_data['speechrate_dictionary']['ASD(speakingtime by nsyll)']
  let art_rate = exercise_data['speechrate_dictionary']['articulation rate(nsyll by phonationtime)']
  let dur = exercise_data['speechrate_dictionary']['dur(s)']
  let npause = exercise_data['speechrate_dictionary']['npause']
  let nsyll = exercise_data['speechrate_dictionary']['nsyll']
  let phonation_time = exercise_data['speechrate_dictionary']['phonationtime(s)']
  let speech_rate = exercise_data['speechrate_dictionary']['speechrate(nsyll by dur)']
  let audio_address = exercise_data['audio_address']
  let loudness = exercise_data['loudness']['loudness']

  loudness=roundNumber(loudness,1)
  speech_rate = roundNumber(speech_rate, 2)

  let words_min = speech_rate/2*60
  words_min = roundNumber(words_min, 0)

  phonation_time = roundNumber(phonation_time, 2);

  let speech = {Name: name, loudness:loudness, WordsMin: words_min, ASD: asd, ArticulationRate: art_rate, Duration: dur, NumberPauses:npause, NumberSyllables:nsyll, PhonationTime:phonation_time, AudioAddress: audio_address, SpeechRate:speech_rate};

  return speech
}

export function getMax_phonation_data(exercise_data, name) {
  let max_phonation = exercise_data['max_phonation_dictionary']['max_phonation']
  let audio_address = exercise_data['audio_address']
  let loudness = exercise_data['loudness']['loudness']
  loudness=roundNumber(loudness,1)

  max_phonation = roundNumber(max_phonation,2)
  let phonation = {Name: name, max_phonation, AudioAddress: audio_address, loudness:loudness};
  return phonation
}

export function get_avqi_data(exercise_data, name) {
  let full_avqi = exercise_data['avqi']
  let audio_address = exercise_data['audio_address']
  

  let avqi_dict = {'success':false,'Name':name,'AudioAddress':audio_address}

  if(full_avqi['success'])
  {
    let audio_address_cs = full_avqi['audio_address_cs']
    let audio_address_sv = full_avqi['audio_address_sv']
    let avqi = full_avqi['avqi']


    avqi = roundNumber(avqi,2)

    let cpps = full_avqi['cpps']
    cpps = roundNumber(cpps,2)
    let hnr = full_avqi['hnr']
    hnr = roundNumber(hnr,2)
    let shim = full_avqi['shim']
    shim = roundNumber(shim,2)
    let shdb = full_avqi['shdb']
    shdb = roundNumber(shdb,2)
    let slope = full_avqi['slope']
    slope = roundNumber(slope,2)
    let tilt = full_avqi['tilt']
    tilt = roundNumber(tilt,2)

    avqi_dict['success'] = true
    
    avqi_dict['avqi'] = avqi
    avqi_dict['cpps'] = cpps
    avqi_dict['hnr'] = hnr
    avqi_dict['shim'] = shim
    avqi_dict['shdb'] = shdb
    avqi_dict['slope'] = slope
    avqi_dict['tilt'] = tilt

    avqi_dict['audio_address_cs'] = audio_address_cs
    avqi_dict['audio_address_sv'] = audio_address_sv




  }
  return avqi_dict
}

export function jitter_shimmer_data(exercise_data, name) {



  let mF0 = exercise_data['jitter_shimmer_dictionary']['meanF0']
  let sF0 = exercise_data['jitter_shimmer_dictionary']['stdevF0']
  let lJitter = exercise_data['jitter_shimmer_dictionary']['localJitter']
  let lShimmer = exercise_data['jitter_shimmer_dictionary']['localShimmer']
  let loudness = exercise_data['loudness']['loudness']
  loudness=roundNumber(loudness,1)



  mF0 = roundNumber(mF0,0);
  sF0 = roundNumber(sF0,1);
  lJitter = roundNumber(lJitter,3);
  lShimmer = roundNumber(lShimmer,3);

  let audio_address = exercise_data['audio_address']

  let jitter_shimmer = {Name: name, loudness:loudness, meanF0: mF0, stdevF0: sF0, localJitter: lJitter, localShimmer:lShimmer, AudioAddress:audio_address}              // var jitter_shimmer = {Name: name, meanF0: mF0}
  console.debug(jitter_shimmer)

  return jitter_shimmer

}

export function ddk_data(exercise_data, name) {

  var curr_ddk_rate = exercise_data['ddk_rate_dictionary']['ddk_rate']
  var n_syllables = exercise_data['ddk_rate_dictionary']['syllable_counter']
  var test_duration = exercise_data['ddk_rate_dictionary']['test_duration']
  var test_syllable_count = exercise_data['ddk_rate_dictionary']['test_syllable_count']

  if(test_syllable_count==undefined || test_syllable_count==null || test_syllable_count<1)
  {
    test_syllable_count = 1
  }

  let loudness = exercise_data['loudness']['loudness']
  loudness=roundNumber(loudness,1)

  let repetition_count = n_syllables/test_syllable_count
  let repetition_count_fixed = repetition_count.toFixed(0)
  if(repetition_count>repetition_count_fixed)
  {
    repetition_count = parseInt(repetition_count_fixed)+1
  }
  else
  {
    repetition_count = repetition_count_fixed
  }
  let curr_ddk_rate_sec = curr_ddk_rate/test_duration


  curr_ddk_rate_sec = roundNumber(curr_ddk_rate_sec, 2)

  
  var audio_address = exercise_data['audio_address']
  let scores = ''
  if(name == 'Pa' || name == 'Ta' || name=='Ka')
  {
    if(n_syllables>19)
    {
      scores = 'Normal';
    }
    else if(n_syllables>14 && n_syllables<20)
    {
      scores = 'Good';
    }
    else if(n_syllables>7 && n_syllables<15)
    {
      scores = 'Fair'
    }
    else
    {
      scores = 'Poor'
    }
  }
  else if(name == 'Pa Ta Ka')
  {
    if(n_syllables>11)
    {
      scores = 'Normal';
    }
    else if(n_syllables>7 && n_syllables<12)
    {
      scores = 'Good';
    }
    else if(n_syllables>3 && n_syllables<8)
    {
      scores = 'Fair'
    }
    else
    {
      scores = 'Poor'
    }
  }
  else if(name == 'Ka La')
  {
    if(n_syllables>14)
    {
      scores = 'Normal';
    }
    else if(n_syllables>9 && n_syllables<15)
    {
      scores = 'Good';
    }
    else if(n_syllables>4 && n_syllables<10)
    {
      scores = 'Fair'
    }
    else
    {
      scores = 'Poor'
    }
  }
  var ddk_rate = {Name: name, AudioAddress: audio_address, loudness:loudness, ddk_rate:curr_ddk_rate_sec, n_syllables:n_syllables, test_duration:test_duration, score:scores, repetition_count:repetition_count};
  return ddk_rate
}

export function loudnessScore(avg_loudness_array) {
  let avg_loudness = 0
  if(avg_loudness_array!=undefined && avg_loudness_array!=null)
  {
    let N = avg_loudness_array.length
    if(N>0) {
      for(let n = 0;n<N;++n)
      {
        avg_loudness = avg_loudness + avg_loudness_array[n]/N
        console.debug('avg_loudness_array[n] = ' + avg_loudness_array[n])
      }
      avg_loudness = roundNumber(avg_loudness, 0)
    }
  }
  
  let vol_stage = [ null, null, null, null, null]
  if (avg_loudness < 40) {
    vol_stage[0] = 'You are here';
  } else if (avg_loudness >=40 && avg_loudness < 50) 
  {
    vol_stage[1] = 'You are here';
  } else if (avg_loudness >=50 && avg_loudness < 60) 
  {
    vol_stage[2] = 'You are here';
  } else if (avg_loudness >=60 && avg_loudness < 70) 
  {
    vol_stage[3] = 'You are here';
  } else {
    vol_stage[4] = 'You are here';
  }
  
  return {
    "avg_loudness": avg_loudness,
    "vol_stage": vol_stage
  }
}

export function createPieChart(canvas_id, results_sum_by_result_tag, result_tags)
{
  let final_data = {}

  final_data['data'] = results_sum_by_result_tag

  final_data['backgroundColor'] = ['rgba(99, 255, 64, 0.6)',
                                   'rgba(255, 99, 132, 0.6)']

  final_data['borderColor'] = final_data['backgroundColor']
  final_data['borderWidth'] = 1
  final_data['pointBackgroundColor'] = "rgba(10,181,198,1)"
  final_data['pointBorderColor'] =  "#fff"
  final_data['pointHoverBorderColor'] = "rgba(10,181,198,1)"

  let pieChartData = {
    labels: result_tags,
    datasets: [final_data],
  }

  let canvas = document.getElementById(canvas_id)
  let ctx = canvas.getContext('2d')

  let piechart = new Chart(ctx, {
      type: 'pie',
      data: pieChartData
  });
}

export function add_pitch_range_result(exercise_name, exercises, audio_address, results)
{

  if (!results.hasOwnProperty(exercise_name)) {
    results[exercise_name] = []
  }

  for(let i in exercises) {
    let x_exercise = {}

    for (const [result_type, result] of Object.entries(exercises[i]['results'])) {

      x_exercise[result_type] = roundNumber(result, 0)
    }

    x_exercise['audio_address'] = audio_address

  
    x_exercise['Name'] = exercises[i]['exercise']
    results[exercise_name].push(x_exercise)
  }

  return results
}