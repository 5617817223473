<template>
  <div>
    <div v-if="step < 3">
      <h1>Self Check</h1>

      <div class="mt-4">
        <div v-for="qIndex in Array.from({length: Math.floor(3)}, (x, i) => i)" :key="qIndex" class="row my-4">
          <div class="col">
            <div class="d-flex">
              <p class="fs-5 me-2">{{ step * 3  + (qIndex + 1) }}. &emsp;{{ questions[step * 3 + qIndex].question }} </p>
              <VTooltip>
                <p style="cursor: help"><u style="font-size: 13px;">what does this mean?</u></p>
                <template #popper>
                  {{ questions[step * 3 + qIndex].tooltip }}
                </template>
              </VTooltip>
            </div>
            <br/>
            <div class="d-flex justify-content-between">
              <div v-for="available_answer, index in questions[step * 3 + qIndex].answers" class="text-center flex-item">
                <div @click="select_answer(step*3+qIndex, available_answer)" 
                      style="cursor: pointer;">
                  <i v-if="answers[step * 3 + qIndex] != available_answer.text" class="bi bi-circle fs-4" style="color: rebeccapurple;"></i>
                  <i v-else class="bi bi-check-circle-fill fs-4" style="color: rebeccapurple;"></i>
                  <p class="mx-4">{{ available_answer.text }}</p>
                </div>
              </div>
            </div>
            <br/>
          </div>
        </div>
      </div>
      <div class="row my-4 justify-content-center" >
        <div class="col-12 text-center">
          <button class="btn btn-primary rounded-pill px-4" 
            :disabled="!(Object.values(answers_per_step[step]).filter(value => value !== null).length == 3)" 
            @click="next()">Continue</button>
        </div>
      </div>

    </div>
    <div v-else>
      <h1>What do you want to work on?</h1>
      <div class="row mt-4 justify-content-between">
        <div v-for="category, step_index in work_on_categories" class="col-md-4">
          <div  :class="['card','card-body', 'h-100', {'shadow-lg': selected_work_on_category.includes(category) }]" 
                :style="[{'height:250px': selected_work_on_category.includes(category)}, 
                         {'height:250px': !selected_work_on_category.includes(category)}, 
                          'cursor:pointer']" 
          @click="select_work_on_category(category)">
            <div class="row justify-content-between mt-2">
              <div class="col-3">
                <i v-if="selected_work_on_category.includes(category)" class="bi bi-check-circle-fill text-primary" style="font-size: 20px;"></i>
                <i v-else class="bi bi-circle text-primary" style="font-size: 20px;"></i>
              </div>
              <div class="col text-center fs-5">
                <div class="row">
                  <strong>{{category}}</strong>
                </div>
                <div class="row mt-4 text-center">
                 <p>Score {{ score_step(step_index)? score_step(step_index) : ''}} / 12</p>
                </div>
              </div>
              <div class="col-3"></div>
            </div>
            <div class="row justify-content-between mt-4">
              <div class="row text-center" v-if="score_step(step_index) == Math.max(score_step(0), score_step(1), score_step(2))">
                <p class="ms-0">We recommend you start with this one</p>
              </div>
              <div v-else class="row text-center">

              </div>
            </div>
          </div>
        </div>

      </div>
      <br/>
      <br/>

      <div class="row my-4 justify-content-center">
        <div class="col-8 text-center">
          <button class="btn btn-primary rounded-pill px-4" @click="next()">Continue</button>          
          <p class="mt-4">OR</p>
          <p class="text-primary mt-2" @click="restart()" style="cursor: pointer;"><small>Do you want to try again?</small></p>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
  import {ref, watch, onMounted} from 'vue'
  import {updateUserData, getUserDataDir} from '@/firebase/index'


  export default {
    name: 'Self_Check',
    props: {
      current_substage_index: {
        type: Number,
        required: true
      },
    },
    setup(props, { emit }) {

      onMounted(() => {
        window.scrollTo(0, 0);
      });
      
      const show_next = ref(false)
      const step = ref(props.current_substage_index)

      emit('update_current_progress', (step.value/4 * 100) )

      const questions = [
        //category 1
        { 
          'question': 'My voice is quiet.',
          'tooltip': 'People struggle to hear me.',
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Language'
        },
        { 
          'question': 'My voice is hoarse.',
          'tooltip': 'My voice sounds rough, raspy, strained or breathy.',
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Language'
        },
        { 
          'question': 'I have difficulty speaking clearly.',
          'tooltip': 'My speech sounds slurred or unclear.',
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Language'
        },
        //category 2
        { 
          'question': 'I struggle to articulate sounds.',
          'tooltip': 'I cannot say certain words properly.',
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Speech'
        },
        { 
          'question': 'I have difficulty finding the words to say.',
          'tooltip': 'I get stuck when trying to say something.',
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Speech'
        },
        { 
          'question': 'Wrong words or nonsense words come out when I speak.',
          'tooltip': "People say they don't understand what I mean.", 
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Speech'
        },
        //category 3
        { 
          'question': 'I have difficulty understanding what other people are saying.',
          'tooltip': 'I take long to process what people say.',
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Voice'
        },
        { 
          'question': 'I find it difficult to write letters or words.',
          'tooltip': 'I struggle to find the right letter when writing a word.',
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Voice'
        },
        { 
          'question': 'I find it difficult to read.',
          'tooltip': 'I cannot read at all or I struggle to read long sentences.',
          'answers': [{'text': 'Never', 'weight': 0}, 
                      {'text': 'Almost Never', 'weight': 1}, 
                      {'text': 'Sometimes', 'weight': 2}, 
                      {'text': 'Almost Always', 'weight': 3}, 
                      {'text': 'Always', 'weight': 4}],
          'category': 'Voice'
        },
      ]
      const answers = ref({})
      const answers_per_step = ref({0: {}, 1: {}, 2: {}})
      
      const selected_work_on_category = ref([])
      const category_score = ref({})

      const work_on_categories = ['Voice', 'Speech', 'Language']
  

      watch(() => props.current_substage_index, (newVal) => {
        step.value = newVal;
        emit('update_current_progress', (step.value/4 * 100) )
      });

      const score_step = (step_index) => {
        return (answers_per_step.value[step_index][0].weight + 
                answers_per_step.value[step_index][1].weight + 
                answers_per_step.value[step_index][2].weight)
      }

      //this means the user used the back button and re-visits this stage
      const checkIfDataIsStored = () => {

        let self_check = JSON.parse(sessionStorage.getItem('self_check'))
        
        if (self_check) {
          selected_work_on_category.value = self_check['selected_work_on_category']
          category_score.value = self_check['category_score']
          answers_per_step.value = self_check['answers_per_step']
          answers.value = self_check['answers']
        }
      }

      checkIfDataIsStored()
      
      const restart = () => {
        
        step.value = 0
        
        selected_work_on_category.value = []
        category_score.value = {}
        answers_per_step.value = {0: {}, 1: {}, 2: {}}
        answers.value = {}

        emit('next_substage', 0)
        emit('update_current_progress', 0 )
      }

      const next = () => {
        
        if (step.value < 3) {
          
          if (step.value == 2) {
            let max = Math.max(score_step(0), score_step(1), score_step(2))
            
            for(let i = 0; i < work_on_categories.length; i++) {
              
              if (score_step(i) == max) {
                selected_work_on_category.value.push(work_on_categories[i])
              }
             
            }
          }

          step.value++
          emit('next_substage', step.value)
          emit('update_current_progress', (step.value/4 * 100) )
        } else {

          let difficulties = []
          for (let difficulty of selected_work_on_category.value) {
            if (difficulty == 'Language') {
              difficulties.push("Slurred/choppy speech")
            } 

            if (difficulty == 'Voice') {
              difficulties.push("Hoarse/breathy voice")
            }
            
            if (difficulty == 'Speech') {
              difficulties.push("Dysphasia/aphasia")
              difficulties.push("Difficulty in moving lips, jaw or tongue")
            }
          }
          updateUserData('Settings', {'Qs_diseaseType': difficulties })
          
          //this is to support the back button in case use comes back
          let self_check = {
            'selected_work_on_category': selected_work_on_category.value,
            'category_score': category_score.value,
            'answers_per_step': answers_per_step.value,
            'answers': answers.value
          }

          sessionStorage.setItem('self_check', JSON.stringify(self_check))
          updateUserData('Settings', {'onboarding': {'current_stage_index': 2}})
          emit('next')
        }

        window.scrollTo(0, 0);

      }

      const select_answer = (index, available_answer) => {
        if (answers.value[index] != available_answer.text) {
          
          answers_per_step.value[step.value][(index) % 3] = available_answer

          answers.value[index] = available_answer.text

        } else {
          answers.value[index] = null

          answers_per_step.value[step.value][(index) % 3] = null
        }
      }

      const select_work_on_category = (category) => {
        if (selected_work_on_category.value.includes(category)) {
          selected_work_on_category.value = selected_work_on_category.value.filter(item => item !== category);
          
        } else {
          selected_work_on_category.value.push(category)
        }
      }

      return {
        show_next,
        step,
        questions,
        answers,

        work_on_categories,
        category_score,
        selected_work_on_category,

        next,
        select_work_on_category,
        select_answer,
        answers_per_step,
        score_step,
        restart

      }
    }
  }
</script>

<style scoped>
.d-flex {
  position: relative;
}

.flex-item {
  position: relative;
  flex: 1;
}

.flex-item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 22%;
  right: 0;
  width: 80%; /* Adjust this value to control the line length */
  height: 5px; /* Adjust the thickness of the line */
  background-color: rebeccapurple; /* Adjust the color of the line */
  opacity: 30%;
  transform: translateX(50%);
}
</style>
  